<template>
  <v-container
    class="shift-report py-0"
    fill-height
    fluid
  >
    <v-card
      id="report"
      flat
    >
      <v-card-title class="body-2 d-block px-0">
        <span class="body-2 font-weight-medium">
          {{ title }}
        </span>
        <v-btn
          class="float-right mt-n1"
          icon
          small
          @click="$emit('close')"
        >
          <v-icon small>
            fal fa-times
          </v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text
        class="pa-0 mb-2"
        style="height: 88vh;"
      >
        <v-row
          v-if="newTemplate"
          align="center"
          :class="['pt-0', $vuetify.breakpoint.smAndDown ? 'text-center' : '']"
        >
          <v-col class="pt-0">
            <v-text-field
              v-model.trim="newTemplateName"
              autofocus
              class="d-inline-block mr-4"
              dense
              hide-details
              :label="$t('labels.templateName')"
              outlined
              :style="{ width: $vuetify.breakpoint.smAndDown ? '190px' : '250px' }"
            />
            <div class="d-inline-block">
              <v-tooltip
                max-width="300px"
                top
              >
                <template #activator="{ on, attrs }">
                  <v-btn
                    class="mr-4"
                    elevation="0"
                    fab
                    x-small
                    v-bind="attrs"
                    v-on="on"
                    @click="cancelCreateTemplate"
                  >
                    <v-icon size="16">
                      fal fa-times
                    </v-icon>
                  </v-btn>
                </template>
                <span class="body-2">
                  {{ $t('labels.cancel') }}
                </span>
              </v-tooltip>
              <v-tooltip
                max-width="300px"
                top
              >
                <template #activator="{ on, attrs }">
                  <v-btn
                    :disabled="creatingTemplateInProgress || !newTemplateName"
                    fab
                    class="mr-4"
                    elevation="0"
                    x-small
                    v-bind="attrs"
                    v-on="on"
                    @click="createTemplate"
                  >
                    <v-progress-circular
                      v-if="creatingTemplateInProgress"
                      color="primary"
                      indeterminate
                      size="16"
                      width="2"
                    />
                    <v-icon
                      v-else
                      size="16"
                    >
                      fal fa-save
                    </v-icon>
                  </v-btn>
                </template>
                <span class="body-2">
                  {{ $t('labels.save') }}
                </span>
              </v-tooltip>
              <v-tooltip
                max-width="300px"
                top
              >
                <template #activator="{ on, attrs }">
                  <v-btn
                    fab
                    class="mr-4"
                    :disabled="!gridHasChanges"
                    elevation="0"
                    x-small
                    v-bind="attrs"
                    v-on="on"
                    @click="reset"
                  >
                    <v-icon size="16">
                      fal fa-undo
                    </v-icon>
                  </v-btn>
                </template>
                <span class="body-2">
                  {{ $t('labels.reset') }}
                </span>
              </v-tooltip>
            </div>
          </v-col>
        </v-row>
        <v-row
          v-else-if="editTemplate"
          align="center"
        >
          <v-col
            :class="['pt-0', $vuetify.breakpoint.smAndDown ? 'text-center' : '']"
          >
            <v-text-field
              v-model.trim="editTemplateName"
              autofocus
              class="d-inline-block mr-4"
              dense
              hide-details
              :label="$t('labels.templateName')"
              outlined
              style="width: 250px"
            />
            <div class="d-inline-block">
              <v-tooltip
                max-width="300px"
                top
              >
                <template #activator="{ on, attrs }">
                  <v-btn
                    fab
                    class="mr-4"
                    elevation="0"
                    x-small
                    v-bind="attrs"
                    v-on="on"
                    @click="cancelRenameTemplate"
                  >
                    <v-icon size="16">
                      fal fa-times
                    </v-icon>
                  </v-btn>
                </template>
                <span class="body-2">
                  {{ $t('labels.cancel') }}
                </span>
              </v-tooltip>
              <v-tooltip
                max-width="300px"
                top
              >
                <template #activator="{ on, attrs }">
                  <v-btn
                    fab
                    class="mr-4"
                    :disabled="updatingTemplateInProgress || !editTemplateName || !selectedTemplateHasChanges"
                    elevation="0"
                    x-small
                    v-bind="attrs"
                    v-on="on"
                    @click="updateTemplate"
                  >
                    <v-progress-circular
                      v-if="updatingTemplateInProgress"
                      color="primary"
                      indeterminate
                      size="16"
                      width="2"
                    />
                    <v-icon
                      v-else
                      size="16"
                    >
                      fal fa-save
                    </v-icon>
                  </v-btn>
                </template>
                <span class="body-2">
                  {{ $t('labels.save') }}
                </span>
              </v-tooltip>
              <v-tooltip
                max-width="300px"
                top
              >
                <template #activator="{ on, attrs }">
                  <v-btn
                    fab
                    class="mr-4"
                    :disabled="!gridHasChanges"
                    elevation="0"
                    x-small
                    v-bind="attrs"
                    v-on="on"
                    @click="reset"
                  >
                    <v-icon size="16">
                      fal fa-undo
                    </v-icon>
                  </v-btn>
                </template>
                <span class="body-2">
                  {{ $t('labels.reset') }}
                </span>
              </v-tooltip>
              <v-tooltip
                max-width="300px"
                top
              >
                <template #activator="{ on, attrs }">
                  <v-btn
                    fab
                    class="mr-4"
                    elevation="0"
                    disabled
                    x-small
                    v-bind="attrs"
                    v-on="on"
                  >
                    <v-icon size="16">
                      fal fa-pen
                    </v-icon>
                  </v-btn>
                </template>
                <span class="body-2">
                  {{ $t('labels.rename') }}
                </span>
              </v-tooltip>
              <v-tooltip
                max-width="300px"
                top
              >
                <template #activator="{ on, attrs }">
                  <v-btn
                    fab
                    class="mr-4"
                    elevation="0"
                    x-small
                    v-bind="attrs"
                    v-on="on"
                    @click="initDeleteTemplate"
                  >
                    <v-icon
                      color="error"
                      size="16"
                    >
                      fal fa-trash-alt
                    </v-icon>
                  </v-btn>
                </template>
                <span class="body-2">
                  {{ $t('labels.delete') }}
                </span>
              </v-tooltip>
            </div>
          </v-col>
        </v-row>
        <v-row
          v-else
          align="center"
        >
          <v-col
            :class="['pt-0', $vuetify.breakpoint.smAndDown ? 'text-center' : '']"
          >
            <v-menu
              v-model="templateSelectorOpened"
              :close-on-content-click="false"
              max-width="250"
              min-width="250"
              :offset-y="true"
              v-on="$listeners"
            >
              <template v-slot:activator="{ on, attrs, value }">
                <v-btn
                  class="title font-weight-regular mr-4"
                  color="grey lighten-2"
                  :disabled="loadingData"
                  outlined
                  height="40px"
                  width="250px"
                  v-bind="attrs"
                  v-on="on"
                >
                  <span
                    class="body-2 font-weight-regular grey--text text--darken-3 text-truncate text-left pr-1"
                    style="width: 188px; text-transform: none;"
                  >
                    {{ selectedTemplateName }}
                  </span>
                  <v-spacer />
                  <v-icon
                    v-if="selectedTemplate"
                    class="grey--text text--darken-3 mr-3"
                    size="16"
                    @click.stop="clearSelectedTemplate"
                  >
                    fas fa-times-circle
                  </v-icon>
                  <v-icon
                    class="grey--text text--darken-3"
                    size="16"
                  >
                    {{ value ? 'fas fa-caret-up' : 'fas fa-caret-down' }}
                  </v-icon>
                </v-btn>
              </template>
              <v-card>
                <v-card-title
                  v-if="templates.length > 0"
                  class="pa-2"
                >
                  <v-text-field
                    v-model.trim="templateSearchText"
                    :append-icon="templateSearchText ? '' : 'fal fa-search'"
                    class="template-search"
                    :clearable="!!templateSearchText"
                    dense
                    :elevation="0"
                    hide-details
                    :placeholder="$t('labels.searchByName')"
                    outlined
                  />
                </v-card-title>
                <v-list
                  v-if="filteredTemplates.length > 0"
                  class="pa-0 template-list v-select-list"
                  dense
                >
                  <v-list-item
                    v-for="template in filteredTemplates"
                    :key="template.id"
                    :class="template.id === selectedTemplate ? 'v-list-item--active' : ''"
                    @click="setSelectedTemplate(template.id)"
                  >
                    <v-list-item-title class="body-2 font-weight-regular">
                      {{ template.name }}
                    </v-list-item-title>
                  </v-list-item>
                </v-list>
                <v-list
                  v-else
                  class="pa-0"
                  dense
                >
                  <v-list-item>
                    <v-list-item-title class="body-2 font-weight-regular">
                      {{ templateSearchText ? $t('labels.noMatchingTemplates') : $tc('labels.noTemplate', 2) }}
                    </v-list-item-title>
                  </v-list-item>
                  <v-list-item @click="initCreateTemplate">
                    <v-list-item-icon class="mr-0">
                      <v-icon size="16">
                        fal fa-plus
                      </v-icon>
                    </v-list-item-icon>
                    <v-list-item-title>
                      {{ $t('labels.createTemplate') }}
                    </v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-card>
            </v-menu>
            <div
              class="d-inline-block"
            >
              <v-tooltip
                max-width="300px"
                top
              >
                <template #activator="{ on, attrs }">
                  <v-btn
                    fab
                    class="mr-4 create-template"
                    elevation="0"
                    x-small
                    v-bind="attrs"
                    v-on="on"
                    @click="initCreateTemplate"
                  >
                    <v-icon size="16">
                      fal fa-plus
                    </v-icon>
                  </v-btn>
                </template>
                <span class="body-2">
                  {{ $t('labels.new') }}
                </span>
              </v-tooltip>
              <v-tooltip
                max-width="300px"
                top
              >
                <template #activator="{ on, attrs }">
                  <v-btn
                    fab
                    class="mr-4"
                    :disabled="updatingTemplateInProgress || !selectedTemplate || !selectedGridHasChanges"
                    elevation="0"
                    x-small
                    v-bind="attrs"
                    v-on="on"
                    @click="updateTemplate"
                  >
                    <v-progress-circular
                      v-if="updatingTemplateInProgress"
                      color="primary"
                      indeterminate
                      size="16"
                      width="2"
                    />
                    <v-icon
                      v-else
                      size="16"
                    >
                      fal fa-save
                    </v-icon>
                  </v-btn>
                </template>
                <span class="body-2">
                  {{ $t('labels.save') }}
                </span>
              </v-tooltip>
              <v-tooltip
                max-width="300px"
                top
              >
                <template #activator="{ on, attrs }">
                  <v-btn
                    fab
                    class="mr-4"
                    :disabled="!gridHasChanges"
                    elevation="0"
                    x-small
                    v-bind="attrs"
                    v-on="on"
                    @click="reset"
                  >
                    <v-icon size="16">
                      fal fa-undo
                    </v-icon>
                  </v-btn>
                </template>
                <span class="body-2">
                  {{ $t('labels.reset') }}
                </span>
              </v-tooltip>
              <v-tooltip
                max-width="300px"
                top
              >
                <template #activator="{ on, attrs }">
                  <v-btn
                    fab
                    class="mr-4 rename-template"
                    elevation="0"
                    :disabled="!selectedTemplate"
                    x-small
                    v-bind="attrs"
                    v-on="on"
                    @click="initUpdateTemplate"
                  >
                    <v-icon size="16">
                      fal fa-pen
                    </v-icon>
                  </v-btn>
                </template>
                <span class="body-2">
                  {{ $t('labels.rename') }}
                </span>
              </v-tooltip>
              <v-tooltip
                max-width="300px"
                top
              >
                <template #activator="{ on, attrs }">
                  <v-btn
                    fab
                    class="mr-4"
                    :disabled="!selectedTemplate"
                    elevation="0"
                    x-small
                    v-bind="attrs"
                    v-on="on"
                    @click="initDeleteTemplate"
                  >
                    <v-icon
                      color="error"
                      size="16"
                    >
                      fal fa-trash-alt
                    </v-icon>
                  </v-btn>
                </template>
                <span class="body-2">
                  {{ $t('labels.delete') }}
                </span>
              </v-tooltip>
            </div>
          </v-col>
        </v-row>
        <ag-grid-vue
          v-if="showGrid"
          style="width: 100%; height: 94%"
          class="ag-theme-quartz ag-theme-nb"
          :auto-height="true"
          :cache-block-size="cacheBlockSize"
          :column-defs="columnDefs"
          :get-context-menu-items="getContextMenuItems"
          :initial-state="initialState"
          :max-blocks-in-cache="maxBlocksInCache"
          :modules="modules"
          :pagination="true"
          :pagination-auto-page-size="true"
          :row-model-type="rowModelType"
          :side-bar="sideBarDef"
          :wrap-text="false"
          @filter-changed="onGridFilterChanged"
          @grid-ready="onGridReady"
          @state-updated="onStateUpdated"
        />
      </v-card-text>
      <v-card-actions class="px-0">
        <v-row
          no-gutters
        >
          <v-col class="text-right">
            <v-btn
              v-if="showCloseButton"
              class="mr-6"
              text
              @click="$emit('close')"
            >
              {{ $t('labels.close') }}
            </v-btn>
            <v-btn
              :disabled="downloadInProgress"
              color="secondary"
              width="176px"
              @click="downloadReport"
            >
              <v-progress-circular
                v-if="downloadInProgress"
                color="primary"
                indeterminate
                size="20"
                width="2"
              />
              <span v-else>
                {{ $t('labels.downloadReport') }}
              </span>
            </v-btn>
          </v-col>
        </v-row>
      </v-card-actions>
    </v-card>
    <v-dialog
      v-model="showDeleteTemplateDialog"
      persistent
      width="330px"
    >
      <v-card
        flat
      >
        <v-card-title class="body-2">
          <span class="body-2 font-weight-medium">
            {{ $t('labels.deleteTemplate?') }}
          </span>
        </v-card-title>
        <v-card-text class="py-0">
          <v-row
            align="center"
          >
            <v-col>
              {{ $t('descriptions.deleteTemplate') }}
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions class="px-6 pb-3">
          <v-row
            no-gutters
          >
            <v-col class="text-right">
              <v-btn
                class="mr-6"
                :disabled="deleteTemplateInProgress"
                text
                @click="showDeleteTemplateDialog = false"
              >
                {{ $t('labels.cancel') }}
              </v-btn>
              <v-btn
                :disabled="deleteTemplateInProgress"
                color="accent"
                width="100px"
                @click="deleteTemplate"
              >
                <v-progress-circular
                  v-if="deleteTemplateInProgress"
                  color="primary"
                  indeterminate
                  size="20"
                  width="2"
                />
                <span v-else>
                  {{ $t('labels.delete') }}
                </span>
              </v-btn>
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import _ from 'lodash';
import moment from 'moment';
import '@ag-grid-community/styles/ag-grid.css';
import '@ag-grid-community/styles/ag-theme-quartz.css';
import { AgGridVue } from '@ag-grid-community/vue';
import { ClipboardModule } from '@ag-grid-enterprise/clipboard';
import { ColumnsToolPanelModule } from '@ag-grid-enterprise/column-tool-panel';
import { FiltersToolPanelModule } from '@ag-grid-enterprise/filter-tool-panel';
import { MenuModule } from '@ag-grid-enterprise/menu';
import { ServerSideRowModelModule } from '@ag-grid-enterprise/server-side-row-model';
import { SetFilterModule } from '@ag-grid-enterprise/set-filter';
import { SideBarModule } from '@ag-grid-enterprise/side-bar';
import { LicenseManager } from '@ag-grid-enterprise/core';
import { showStatus } from '@/plugins/vue-notification';
const DeepDiff = require('deep-diff').diff;

LicenseManager.setLicenseKey('Using_this_{AG_Grid}_Enterprise_key_{AG-052572}_in_excess_of_the_licence_granted_is_not_permitted___Please_report_misuse_to_legal@ag-grid.com___For_help_with_changing_this_key_please_contact_info@ag-grid.com___{Oysterjob_Inc.}_is_granted_a_{Single_Application}_Developer_License_for_the_application_{NurseBrite}_only_for_{1}_Front-End_JavaScript_developer___All_Front-End_JavaScript_developers_working_on_{NurseBrite}_need_to_be_licensed___{NurseBrite}_has_been_granted_a_Deployment_License_Add-on_for_{1}_Production_Environment___This_key_works_with_{AG_Grid}_Enterprise_versions_released_before_{20_December_2024}____[v3]_[01]_MTczNDY1MjgwMDAwMA==d7cc4f0dc1191d299c952392a050e5a5');

export default {
  components: {
    AgGridVue
  },
  props: {
    showCloseButton: {
      default: false,
      type: Boolean
    },
    render: {
      default: true,
      type: Boolean
    }
  },
  data () {
    const templates = _.get(this.$store.state.account.profile, 'schedulePreferences.reports.shifts.templates', {});
    const initialTemplateId = _.get(templates, 'last', null);
    return {
      cacheBlockSize: 500,
      columnApi: null,
      columnDefs: null,
      creatingTemplateInProgress: false,
      deleteTemplateInProgress: false,
      downloadInProgress: false,
      editTemplate: false,
      editTemplateName: '',
      getContextMenuItems: null,
      gridApi: null,
      initialState: null,
      lastTemplateSettings: null,
      loadingData: true,
      maxBlocksInCache: 20,
      modules: [
        ClipboardModule,
        ColumnsToolPanelModule,
        FiltersToolPanelModule,
        MenuModule,
        ServerSideRowModelModule,
        SetFilterModule,
        SideBarModule
      ],
      newTemplate: false,
      newTemplateName: '',
      rowModelType: 'serverSide',
      selectedTemplate: templates[initialTemplateId] ? initialTemplateId : null,
      showCreateTemplateDialog: false,
      showDeleteTemplateDialog: false,
      showGrid: true,
      templateSearchText: '',
      templateSelectorOpened: false,
      updatingTemplateInProgress: false
    };
  },
  computed: {
    departments () {
      return _.sortBy(this.$store.state.org.departments.map(dept => ({ id: dept.id, name: dept.name })), 'name');
    },
    filteredTemplates () {
      if (this.templateSearchText) {
        return _.filter(this.templates, (t) => t.name.toLocaleLowerCase().indexOf(this.templateSearchText.toLocaleLowerCase()) > -1);
      }
      return this.templates;
    },
    flags () {
      return _.sortBy(this.$store.state.org.flags.map(flag => ({ id: flag.id, shortCode: flag.shortCode })), 'shortCode');
    },
    jobStatus () {
      return _.sortBy(
        this.$store.state.org.jobStatus.map(jobType => ({ id: jobType.id, name: jobType.name })),
        'name'
      );
    },
    jobTypes () {
      return _.sortBy(
        this.$store.state.org.jobTypes.map(jobType => ({ id: jobType.id, name: jobType.name })),
        'name'
      );
    },
    selectedGridHasChanges () {
      if (!this.templatesById[this.selectedTemplate]) {
        return false;
      }
      const diff = DeepDiff(this.templatesById[this.selectedTemplate].state, this.lastTemplateSettings);
      return diff && diff.length > 0;
    },
    selectedTemplateHasChanges () {
      return this.selectedGridHasChanges || this.editTemplateName !== this.templatesById[this.selectedTemplate].name;
    },
    selectedTemplateName () {
      let name = this.$t('labels.noTemplateSelected');
      if (this.templatesById[this.selectedTemplate]) {
        name = this.templatesById[this.selectedTemplate].name;
      }
      return name;
    },
    shiftTypes () {
      return _.sortBy(
        this.$store.state.org.shiftTypes.map(shiftType => ({ id: shiftType.id, name: shiftType.name })),
        'name'
      );
    },
    gridHasChanges () {
      if (this.selectedTemplate && !this.newTemplate) {
        return this.selectedGridHasChanges;
      }
      const diff = DeepDiff(this.getDefaultState(), this.lastTemplateSettings);
      return diff && diff.length > 0;
    },
    templates () {
      const templates = _.get(this.$store.state.account.profile.schedulePreferences.reports, 'shifts.templates', {});
      const processedTemplates = [];
      for (let t in templates) {
        if (t !== 'last') {
          processedTemplates.push(templates[t]);
        }
      }
      return _.sortBy(processedTemplates, 'name');
    },
    templatesById () {
      return this.templates.reduce(
        (obj, template) => {
          obj[template.id] = template;
          return obj;
        }, // eslint-disable-line no-return-assign, no-sequences
        {}
      );
    },
    title () {
      if (this.newTemplate) {
        return this.$t('labels.newReportTemplate');
      }
      return this.$tc('labels.report', 1);
    }
  },
  watch: {
    render (value) {
      if (value) {
        this.setDataSource();
      }
    }
  },
  beforeMount () {
    const commonFilterParams = {
      buttons: ['apply', 'reset'],
      closeOnApply: true,
      filterPlaceholder: '',
      maxNumConditions: 1
    };
    this.columnDefs = [];
    this.columnDefs.push({
      field: 'assigneeFullName',
      headerName: this.$t('labels.employeeName'),
      filter: 'agTextColumnFilter',
      filterParams: {
        ...commonFilterParams,
        defaultOption: 'contains',
        filterOptions: ['contains']
      },
      sortable: false
    });
    this.columnDefs.push({
      field: 'canceled',
      headerName: this.$t('labels.canceled'),
      filter: 'agSetColumnFilter',
      filterParams: {
        ...commonFilterParams,
        keyCreator: params => params.value.id,
        suppressMiniFilter: true,
        suppressSorting: true,
        valueFormatter: params => params.value.name,
        values: [{ id: 'false', name: this.$t('labels.no') }, { id: 'true', name: this.$t('labels.yes') }]
      },
      sortable: false
    });
    this.columnDefs.push({
      field: 'comments',
      headerName: this.$tc('labels.comment', 1),
      sortable: false
    });
    this.columnDefs.push({
      field: 'date',
      headerName: this.$tc('labels.date', 1),
      filter: 'agDateColumnFilter',
      filterParams: {
        ...commonFilterParams,
        browserDatePicker: true,
        defaultOption: 'inRange',
        filterOptions: ['equals', 'inRange']
      },
      sortable: true
    });
    if (this.$store.getters['account/isDepartmentManagement']()) {
      this.columnDefs.push({
        field: 'departmentName',
        headerName: this.$tc('labels.department', 1),
        sortable: false
      });
    } else {
      this.columnDefs.push({
        field: 'departmentName',
        headerName: this.$tc('labels.department', 1),
        filter: 'agSetColumnFilter',
        filterParams: {
          ...commonFilterParams,
          keyCreator: params => params.value.id,
          suppressSorting: true,
          valueFormatter: params => params.value.name,
          values: this.departments
        },
        sortable: true
      });
    }
    this.columnDefs.push({
      field: 'employeeId',
      headerName: this.$t('labels.employeeID'),
      sortable: false
    });
    this.columnDefs.push({
      field: 'endTime',
      headerName: this.$t('labels.endTime'),
      sortable: true
    });
    this.columnDefs.push({
      field: 'flagShortCodes',
      headerName: this.$tc('labels.flag', 1),
      filter: 'agSetColumnFilter',
      filterParams: {
        ...commonFilterParams,
        keyCreator: params => params.value.id,
        suppressSorting: true,
        valueFormatter: params => params.value.shortCode,
        values: this.flags
      },
      sortable: false
    });
    this.columnDefs.push({
      field: 'homeDepartmentName',
      headerName: this.$t('labels.homeDepartment'),
      sortable: false
    });
    if (this.$store.getters['account/isOperationsManagement']()) {
      this.columnDefs.push({
        field: 'internalComments',
        headerName: this.$t('labels.internalComments'),
        sortable: false
      });
    }
    this.columnDefs.push({
      field: 'jobStatusName',
      headerName: this.$t('labels.jobStatus'),
      filter: 'agSetColumnFilter',
      filterParams: {
        ...commonFilterParams,
        keyCreator: params => params.value.id,
        suppressSorting: true,
        valueFormatter: params => params.value.name,
        values: this.jobStatus
      },
      sortable: false
    });
    this.columnDefs.push({
      field: 'jobTypeName',
      headerName: this.$tc('labels.jobType', 1),
      filter: 'agSetColumnFilter',
      filterParams: {
        ...commonFilterParams,
        keyCreator: params => params.value.id,
        suppressSorting: true,
        valueFormatter: params => params.value.name,
        values: this.jobTypes
      },
      sortable: false
    });
    this.columnDefs.push({
      field: 'payrollDate',
      headerName: this.$t('labels.payrollDate'),
      filter: 'agDateColumnFilter',
      filterParams: {
        ...commonFilterParams,
        browserDatePicker: true,
        defaultOption: 'inRange',
        filterOptions: ['equals', 'inRange']
      },
      sortable: true
    });
    this.columnDefs.push({
      field: 'startTime',
      headerName: this.$t('labels.startTime'),
      sortable: true
    });
    this.columnDefs.push({
      field: 'totalHours',
      headerName: this.$t('labels.totalHours'),
      sortable: false
    });
    this.columnDefs.push({
      field: 'overtime',
      headerName: this.$t('labels.ot'),
      filter: 'agSetColumnFilter',
      filterParams: {
        ...commonFilterParams,
        keyCreator: params => params.value.id,
        suppressMiniFilter: true,
        suppressSorting: true,
        valueFormatter: params => params.value.name,
        values: [{ id: 'false', name: this.$t('labels.no') }, { id: 'true', name: this.$t('labels.yes') }]
      },
      sortable: false
    });
    this.columnDefs.push({
      field: 'overtimeHours',
      headerName: this.$t('labels.otHours'),
      sortable: false
    });
    this.columnDefs.push({
      field: 'typeName',
      headerName: this.$t('labels.shiftType'),
      filter: 'agSetColumnFilter',
      filterParams: {
        ...commonFilterParams,
        keyCreator: params => params.value.id,
        suppressSorting: true,
        valueFormatter: params => params.value.name,
        values: this.shiftTypes
      },
      sortable: true
    });
    this.columnDefs.push({
      field: 'sitter',
      headerName: this.$t('labels.sitter'),
      filter: 'agSetColumnFilter',
      filterParams: {
        ...commonFilterParams,
        keyCreator: params => params.value.id,
        suppressMiniFilter: true,
        suppressSorting: true,
        valueFormatter: params => params.value.name,
        values: [{ id: 'false', name: this.$t('labels.no') }, { id: 'true', name: this.$t('labels.yes') }]
      },
      sortable: false
    });
    this.columnDefs.push({
      field: 'working',
      headerName: this.$t('labels.working'),
      filter: 'agSetColumnFilter',
      filterParams: {
        ...commonFilterParams,
        keyCreator: params => params.value.id,
        suppressMiniFilter: true,
        suppressSorting: true,
        valueFormatter: params => params.value.name,
        values: [{ id: 'false', name: this.$t('labels.no') }, { id: 'true', name: this.$t('labels.yes') }]
      },
      sortable: false
    });
    this.getContextMenuItems = (params) => {
      const itemsToInclude = ['copy', 'copyWithHeaders'];
      const modifiedItems = [];
      for (const item of params.defaultItems) {
        if (itemsToInclude.indexOf(item) >= 0) {
          modifiedItems.push(item);
        }
      }
      return modifiedItems;
    };
    this.sideBarDef = {
      toolPanels: [
        {
          id: 'columns',
          labelDefault: 'Columns',
          labelKey: 'columns',
          iconKey: 'columns',
          toolPanel: 'agColumnsToolPanel',
          toolPanelParams: {
            suppressPivotMode: true,
            suppressRowGroups: true,
            suppressValues: true
          }
        },
        {
          id: 'filters',
          labelDefault: 'Filters',
          labelKey: 'filters',
          iconKey: 'filter',
          toolPanel: 'agFiltersToolPanel'
        }
      ]
    };
    const templates = _.get(this.$store.state.account.profile, 'schedulePreferences.reports.shifts.templates', {});
    const initialTemplateId = _.get(templates, 'last', null);
    let initialState = this.getDefaultState();
    if (templates[initialTemplateId]) {
      initialState = templates[initialTemplateId].state;
    }
    this.initialState = initialState;
    this.lastTemplateSettings = _.cloneDeep(initialState);
  },
  methods: {
    cancelCreateTemplate () {
      this.newTemplate = false;
    },
    cancelRenameTemplate () {
      this.editTemplate = false;
    },
    clearSelectedTemplate () {
      this.selectedTemplate = null;
      this.updateLastSelectedTemplate();
      this.reset();
    },
    createTemplate () {
      if (!this.creatingTemplateInProgress) {
        this.creatingTemplateInProgress = true;
        const id = `rpt${moment().valueOf()}`;
        const state = _.cloneDeep(this.lastTemplateSettings);
        const preferences = [
          {
            path: ['reports', 'shifts', 'templates', id],
            value: {
              id,
              name: this.newTemplateName,
              state
            }
          }
        ];
        this.dispatch('account/updateProfileSchedulePreferences', {
          profileId: this.$store.state.account.profile.profileId,
          preferences
        }).then(() => {
          this.selectedTemplate = id;
          this.updateLastSelectedTemplate();
          showStatus({
            text: this.$t('descriptions.reportTemplateSaveSuccess')
          });
        }).catch((error) => {
          const data = {
            error: _.get(error, 'response.data')
          };

          showStatus({
            text: this.$t('descriptions.reportTemplateSaveFail'),
            type: 'error',
            data
          });
        }).finally(() => {
          this.newTemplate = false;
          this.creatingTemplateInProgress = false;
        });
      }
    },
    deleteTemplate () {
      if (!this.deleteTemplateInProgress) {
        this.deleteTemplateInProgress = true;
        const id = this.selectedTemplate;
        this.dispatch('account/deleteReportTemplate', {
          id,
          profileId: this.$store.state.account.profile.profileId,
          source: 'shifts'
        }).then(() => {
          this.selectedTemplate = null;
          this.updateLastSelectedTemplate();
          showStatus({
            text: this.$t('descriptions.reportTemplateDeleteSuccess')
          });
        }).catch((error) => {
          const data = {
            error: _.get(error, 'response.data')
          };

          showStatus({
            text: this.$t('descriptions.reportTemplateDeleteFail'),
            type: 'error',
            data
          });
        }).finally(() => {
          this.showDeleteTemplateDialog = false;
          this.deleteTemplateInProgress = false;
        });
      }
    },
    // This function is added mainly for easy of mocking during in unit tests.
    dispatch (action, payload) {
      return new Promise((resolve, reject) => {
        this.$store.dispatch(action, payload).then(response => {
          resolve(response);
        }).catch(error => {
          reject(error);
        });
      });
    },
    downloadReport () {
      this.downloadInProgress = true;
      const filterModel = this.gridApi.getFilterModel();
      const sortModel = this.gridApi.getColumnState()
        .filter(item => item.sort)
        .sort((a, b) => a.sortIndex - b.sortIndex)
        .map(item => ({ sort: item.sort, colId: item.colId }));
      const criteria = { filterModel, sortModel };
      const fieldsToDownload = this.gridApi.getAllDisplayedColumns().reduce((fieldInfo, column) => {
        fieldInfo[column.colDef.field] = column.colDef.headerName;
        return fieldInfo;
      }, {});
      const link = document.createElement('a');
      this.dispatch('report/retrieveShifts', { criteria, fieldsToDownload }).then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        let filename = `shifts-${moment().format('YYYY-MM-DD_HHmmss')}.csv`;
        if (this.selectedTemplate) {
          filename = `${_.snakeCase(this.templatesById[this.selectedTemplate].name)}-${moment().format('YYYY-MM-DD')}.csv`;
        }
        link.href = url;
        link.setAttribute('download', filename);
        document.body.appendChild(link);
        link.click();
      }).catch(() => {
        // TODO
      }).finally(() => {
        document.body.removeChild(link);
        this.downloadInProgress = false;
      });
    },
    getDefaultState () {
      const columnSizingModel = [];
      const orderedColIds = [];
      for (const colDef of this.columnDefs) {
        columnSizingModel.push({
          colId: colDef.field,
          width: 200
        });
        orderedColIds.push(colDef.field);
      }
      return {
        filter: {
          filterModel: {
            date: {
              dateFrom: moment().startOf('day').subtract(366, 'days').format('YYYY-MM-DD HH:mm:ss'),
              dateTo: moment().startOf('day').format('YYYY-MM-DD HH:mm:ss'),
              filterType: 'date',
              type: 'inRange'
            }
          }
        },
        sort: {
          sortModel: [
            {
              colId: 'date',
              sort: 'desc'
            }
          ]
        },
        sideBar: {
          visible: true,
          position: 'right',
          openToolPanel: null,
          toolPanels: {
            columns: {
              expandedGroupIds: []
            },
            filters: {
              expandedGroupIds: [],
              expandedColIds: []
            }
          }
        },
        columnSizing: {
          columnSizingModel
        },
        columnOrder: {
          orderedColIds
        }
      };
    },
    initCreateTemplate () {
      this.templateSelectorOpened = false;
      this.newTemplateName = '';
      this.newTemplate = true;
    },
    initDeleteTemplate () {
      this.showDeleteTemplateDialog = true;
    },
    initUpdateTemplate () {
      this.editTemplateName = _.get(this.templatesById, [this.selectedTemplate, 'name'], '');
      this.editTemplate = true;
    },
    onGridFilterChanged (params) {
      params.columns.forEach((column) => {
        const filterInstance = params.api.getFilterInstance(column.colId);
        if (filterInstance) {
          const filterModel = filterInstance.getModel();
          if (filterModel && filterModel.filterType === 'date' && filterModel.type === 'inRange') {
            const from = new Date(filterModel.dateFrom);
            const to = new Date(filterModel.dateTo);
            // If the range between 'from' and 'to' is more than 366 days, change the 'from' date to 366 days
            // before 'to' date. Using 366 days here to account for leap years.
            if (moment(to).diff(moment(from), 'days') > 366) {
              filterModel.dateFrom = moment(to).subtract(366, 'days').format('YYYY-MM-DD HH:mm:ss');
              filterInstance.setModel(filterModel);
            }
          }
        }
      });
    },
    onGridReady (params) {
      this.columnApi = params.columnApi;
      this.gridApi = params.api;
      if (this.render) {
        this.setDataSource();
      }
    },
    onStateUpdated (params) {
      const state = _.cloneDeep(params.state);
      delete state.focusedCell;
      delete state.pagination;
      // AG grid sets properties values to "undefined" when they are not configured. Axios uses
      // JSON.stringify to send the payload which filters out "undefined" properties. So we use
      // stringify to do the same here in order to detect changes correctly.
      this.lastTemplateSettings = JSON.parse(JSON.stringify(state));
    },
    reset () {
      if (this.selectedTemplate) {
        this.initialState = this.templatesById[this.selectedTemplate].state;
      } else {
        this.initialState = this.getDefaultState();
      }
      this.lastTemplateSettings = _.cloneDeep(this.initialState);
      this.showGrid = false;
      setTimeout(() => {
        this.showGrid = true;
      }, 0);
    },
    setDataSource () {
      if (this.gridApi) {
        this.gridApi.setGridOption('serverSideDatasource', {
          getRows: (params) => {
            const { startRow, endRow, filterModel, sortModel } = params.request;
            const criteria = { startRow, endRow, filterModel, sortModel };
            this.dispatch('report/retrieveShifts', { criteria }).then((response) => {
              params.success(response);
            }).catch(() => {
              params.fail();
            }).finally(() => {
              this.loadingData = false;
            });
          }
        });
      }
    },
    setSelectedTemplate (id) {
      if (this.templatesById[id]) {
        this.loadingData = true;
        this.selectedTemplate = id;
        this.initialState = _.get(this.templatesById, [this.selectedTemplate, 'state'], null);
        this.lastTemplateSettings = _.cloneDeep(this.initialState);
        this.templateSelectorOpened = false;
        this.showGrid = false;
        setTimeout(() => {
          this.showGrid = true;
        }, 0);
        this.updateLastSelectedTemplate();
      }
    },
    updateLastSelectedTemplate () {
      const preferences = [
        {
          path: ['reports', 'shifts', 'templates', 'last'],
          value: this.selectedTemplate
        }
      ];
      this.dispatch('account/updateProfileSchedulePreferences', {
        profileId: this.$store.state.account.profile.profileId,
        preferences
      }).catch(() => {});
    },
    updateTemplate () {
      this.updatingTemplateInProgress = true;
      const id = this.selectedTemplate;
      const state = _.cloneDeep(this.lastTemplateSettings);
      const preferences = [
        {
          path: ['reports', 'shifts', 'templates', id],
          value: {
            id,
            name: this.editTemplate ? this.editTemplateName : this.templatesById[this.selectedTemplate].name,
            state
          }
        }
      ];
      this.dispatch('account/updateProfileSchedulePreferences', {
        profileId: this.$store.state.account.profile.profileId,
        preferences
      }).then(() => {
        showStatus({
          text: this.$t('descriptions.reportTemplateSaveSuccess')
        });
      }).catch((error) => {
        const data = {
          error: _.get(error, 'response.data')
        };

        showStatus({
          text: this.$t('descriptions.reportTemplateSaveFail'),
          type: 'error',
          data
        });
      }).finally(() => {
        this.cancelRenameTemplate();
        this.updatingTemplateInProgress = false;
      });
    }
  }
};
</script>
<style lang='scss'>
@import "src/assets/sass/ag-theme-nb.scss";
.shift-report {
  > .v-card {
    background: transparent;
    height: 100%;
    width: 100%;
  }
}
.template-list {
  max-height: 300px;
  overflow: scroll;
}
</style>
