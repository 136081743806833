<template>
  <v-card
    class="pb-2 shifts-card"
    :elevation="elevation"
    outlined
    width="100%"
    @click="$emit('click')"
  >
    <v-list
      class="py-0"
      dense
      flat
    >
      <v-list-item-group active-class="">
        <v-list-item
          two-line
          @click="$emit('click')"
        >
          <v-list-item-content>
            <span class="body-2 grey--text text--darken-3 font-weight-medium text-truncate">
              <UserName
                v-if="allowShowingProfileDialog"
                :user="latestUser"
              />
              <template v-else>
                {{ user.fullName }}
              </template>
            </span>
            <span class="caption grey--text text--darken-3">{{ [latestUser.jobTypeName, latestUser.jobStatusShortCode].join(' ') }}</span>
          </v-list-item-content>
        </v-list-item>
      </v-list-item-group>
    </v-list>
    <v-divider class="py-1" />
    <template v-for="(shift, idx) in shifts">
      <v-row
        :key="shift.id"
        :class="['px-4 py-0 body-2 grey--text shift-time-details', isShiftNonDuty(shift) ? '' : 'text--darken-3']"
        dense
        no-gutters
      >
        <v-col cols="7">
          <v-icon
            v-if="!shift.id"
            class="info--text text--lighten-1 new-shift"
            x-small
          >
            fas fa-plus
          </v-icon>
          <v-tooltip
            max-width="300px"
            top
          >
            <template #activator="{ on, attrs }">
              <span
                v-bind="attrs"
                v-on="on"
              >
                <span
                  v-if="showShiftDate"
                  :class="[shift.overtime ? 'error--text' : '']"
                >
                  {{ moment(shift.date).format(dateFormatLong) }}
                </span>
                <span
                  :class="[shift.overtime ? 'error--text' : '']"
                >
                  {{ getShiftStartTime(shift) }}
                </span>
                <span class="px-1">-</span>
                <span
                  :class="[shift.overtime ? 'error--text' : '']"
                >
                  {{ getShiftEndTime(shift) }}
                </span>
              </span>
            </template>
            <span class="body-2">
              {{ getShiftName(shift) }}
            </span>
          </v-tooltip>
          <v-icon
            v-if="hasDifferentPayrollDate(shift)"
            class="payroll-diff text--darken-3 ml-1"
            color="grey"
            x-small
          >
            fas fa-level-up
          </v-icon>
        </v-col>
        <v-col
          class="shift-status"
          cols="3"
        >
          <span class="text-capitalize grey--text text--darken-3 caption">
            {{ getShiftWorkingStatus(shift) }}
          </span>
        </v-col>
        <v-col cols="2">
          <span class="grey--text float-right caption">
            {{ getShiftDuration(shift) }}
          </span>
        </v-col>
      </v-row>
      <v-row
        v-if="shift.flags && shift.flags.length > 0"
        :key="`${shift.id}-flags`"
        class="px-4"
        dense
        no-gutters
      >
        <v-col cols="12">
          <v-tooltip
            v-for="flagId in shift.flags"
            :key="flagId"
            max-width="300px"
            top
          >
            <template #activator="{ on, attrs }">
              <v-chip
                class="lighten-2 grey--text text--darken-3 flag-short-code my-1"
                color="info"
                small
                v-bind="attrs"
                v-on="on"
              >
                <span class="flabel">
                  {{ shiftFlags[flagId].shortCode }}
                </span>
              </v-chip>
            </template>
            <span class="body-2">
              {{ shiftFlags[flagId].name }}
            </span>
          </v-tooltip>
        </v-col>
      </v-row>
      <v-divider
        v-if="idx < shifts.length - 1"
        :key="`${shift.id}-div`"
        class="my-1"
      />
    </template>
  </v-card>
</template>

<script>
import _ from 'lodash';
import moment from 'moment';
import { getDuration, isWorkingShiftForValidation } from '@/utils/scheduling';
import UserName from '@/components/scheduling/UserName';

export default {
  components: {
    UserName
  },
  props: {
    allowShowingProfileDialog: {
      default: true,
      type: Boolean
    },
    elevation: {
      default: 0,
      type: Number
    },
    shifts: {
      default: function () {
        return [];
      },
      type: Array,
      required: true
    },
    showShiftDate: {
      default: false,
      type: Boolean
    },
    user: {
      type: Object,
      required: true
    }
  },
  computed: {
    dateFormatLong () {
      return this.$store.getters['org/getDateFormatLong']();
    },
    latestUser () {
      return this.$store.state.org.employees[this.user.userId];
    },
    shiftFlags () {
      return this.$store.state.org.flags.reduce((flags, value) => {
        flags[value.id] = value;
        return flags;
      }, {});
    },
    shiftTypes () {
      return this.$store.state.org.shiftTypes.reduce(
        (obj, shiftType) => (obj[shiftType.id] = shiftType, obj), // eslint-disable-line no-return-assign, no-sequences
        {}
      );
    }
  },
  methods: {
    getShiftDuration (shift) {
      const startTime = shift.startTime ? shift.startTime : this.shiftTypes[shift.typeId].startTime;
      const endTime = shift.endTime ? shift.endTime : this.shiftTypes[shift.typeId].endTime;
      return getDuration(startTime, endTime);
    },
    getShiftEndTime (shift) {
      const endTime = shift.endTime ? shift.endTime : this.shiftTypes[shift.typeId].endTime;
      return _.split(endTime, ':', 2).join(':');
    },
    getShiftName (shift) {
      return this.shiftTypes[shift.typeId].name;
    },
    getShiftStartTime (shift) {
      const startTime = shift.startTime ? shift.startTime : this.shiftTypes[shift.typeId].startTime;
      return _.split(startTime, ':', 2).join(':');
    },
    getShiftWorkingStatus (shift) {
      let status = '';
      if (shift && this.isShiftNonDuty(shift)) {
        if (shift.canceled) {
          status = this.$t('labels.canceled');
        } else if (shift.giveaway) {
          status = this.$t('labels.giveaway');
        } else if (shift.swapped) {
          status = this.$t('labels.swapped');
        } else if (shift.available) {
          status = this.$t('labels.available');
        } else {
          status = this.$t('labels.nonDuty');
        }
      } else if (shift && shift.onCall) {
        status = this.$t('labels.onCall');
      }

      return status;
    },
    hasDifferentPayrollDate (shift) {
      let hasDifferentPayrollDate = false;
      if (shift.payrollDate) {
        hasDifferentPayrollDate = !moment(shift.payrollDate).isSame(moment(shift.date));
      }

      return hasDifferentPayrollDate;
    },
    isShiftNonDuty (shift) {
      return !isWorkingShiftForValidation(shift, this.shiftFlags);
    },
    moment
  }
};
</script>

<style lang="scss">
.shifts-card {
  .flag-short-code {
    margin-right: 8px;
    .flabel {
      text-align: center;
      width: 32px !important;
    }
  }
  .shift-time-details {
    &> .col {
      &.shift-status {
        text-align: center;
      }
      &> span {
        vertical-align: middle;
      }
    }
    .new-shift {
      left: 4px;
      margin-top: 6px;
      position: absolute;
    }
  }
}
</style>
