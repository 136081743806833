var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-card",
    { attrs: { id: "userProfile", flat: "" } },
    [
      _vm.loading
        ? _c(
            "v-row",
            { style: _vm.contentStyle, attrs: { align: "center" } },
            [
              _c("v-spacer"),
              _c(
                "v-col",
                { attrs: { cols: "6" } },
                [
                  _c(
                    "v-row",
                    { staticClass: "text-center" },
                    [
                      _c(
                        "v-col",
                        { staticClass: "text-center" },
                        [
                          _c("v-progress-circular", {
                            attrs: {
                              color: "info",
                              indeterminate: "",
                              size: "75",
                              width: "6"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "v-row",
                    [
                      _c("v-col", { staticClass: "text-center" }, [
                        _c("span", [
                          _vm._v(_vm._s(_vm.$t("descriptions.loading")))
                        ])
                      ])
                    ],
                    1
                  )
                ],
                1
              ),
              _c("v-spacer")
            ],
            1
          )
        : _c("ValidationObserver", {
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(ref) {
                  var invalid = ref.invalid
                  var passes = ref.passes
                  return [
                    _vm.showTitle
                      ? _c(
                          "v-card-title",
                          {
                            staticClass:
                              "grey--text text--darken-3 subtitle-2 d-block pb-0"
                          },
                          [
                            _vm._v(
                              "\n      " +
                                _vm._s(_vm.labels[_vm.mode].title) +
                                "\n      "
                            ),
                            _c(
                              "v-btn",
                              {
                                staticClass: "float-right",
                                attrs: {
                                  disabled: _vm.saving,
                                  icon: "",
                                  small: ""
                                },
                                on: {
                                  click: function($event) {
                                    return _vm.$emit("close")
                                  }
                                }
                              },
                              [_c("v-icon", [_vm._v("fal fa-times")])],
                              1
                            )
                          ],
                          1
                        )
                      : _vm._e(),
                    _c(
                      "v-card-text",
                      { staticClass: "py-0" },
                      [
                        _vm.showHint &&
                        _vm.labels[_vm.mode] &&
                        _vm.labels[_vm.mode].info
                          ? _c(
                              "v-alert",
                              {
                                staticClass:
                                  "caption dense font-weight-medium mx-0 mb-1 mt-3",
                                attrs: {
                                  color: "info",
                                  dense: "",
                                  outlined: "",
                                  text: ""
                                }
                              },
                              [
                                _c(
                                  "v-icon",
                                  {
                                    staticClass: "ml-n1 mr-3",
                                    attrs: {
                                      slot: "prepend",
                                      color: "info",
                                      size: "12"
                                    },
                                    slot: "prepend"
                                  },
                                  [
                                    _vm._v(
                                      "\n          fas fa-info-circle\n        "
                                    )
                                  ]
                                ),
                                _vm._v(
                                  "\n        " +
                                    _vm._s(_vm.labels[_vm.mode].info) +
                                    "\n      "
                                )
                              ],
                              1
                            )
                          : _vm._e(),
                        _c(
                          "v-tabs",
                          {
                            staticClass: "dense mt-0",
                            attrs: {
                              centered: "",
                              color: "secondary",
                              grow: ""
                            }
                          },
                          [
                            _c(
                              "v-tab",
                              { attrs: { href: "#user" } },
                              [
                                _c("portal-target", {
                                  attrs: { name: "userDialogUserInfo" }
                                })
                              ],
                              1
                            ),
                            _c(
                              "v-tab",
                              { attrs: { href: "#profile" } },
                              [
                                _c("portal-target", {
                                  attrs: { name: "userDialogJobInfo" }
                                })
                              ],
                              1
                            ),
                            _c(
                              "v-tab",
                              { attrs: { href: "#status" } },
                              [
                                _c("portal-target", {
                                  attrs: { name: "userDialogStatus" }
                                })
                              ],
                              1
                            ),
                            _vm.user.classification !== "admin"
                              ? _c(
                                  "v-tab",
                                  { attrs: { href: "#accessControl" } },
                                  [
                                    _c("portal-target", {
                                      attrs: { name: "userDialogAccessControl" }
                                    })
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _c(
                              "v-tab-item",
                              { attrs: { value: "user" } },
                              [
                                _c("ValidationObserver", {
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "default",
                                        fn: function(ref) {
                                          var userInfoInvalid = ref.invalid
                                          return [
                                            _c(
                                              "v-container",
                                              {
                                                staticClass: "pa-0 pt-4",
                                                style: _vm.contentStyle
                                              },
                                              [
                                                _c(
                                                  "portal",
                                                  {
                                                    attrs: {
                                                      to: "userDialogUserInfo"
                                                    }
                                                  },
                                                  [
                                                    _c(
                                                      "span",
                                                      [
                                                        _vm._v(
                                                          "\n                  " +
                                                            _vm._s(
                                                              _vm.$t(
                                                                "labels.userInfo"
                                                              )
                                                            ) +
                                                            "\n                  "
                                                        ),
                                                        _c(
                                                          "v-icon",
                                                          {
                                                            style: {
                                                              visibility: userInfoInvalid
                                                                ? "visible"
                                                                : "hidden"
                                                            },
                                                            attrs: {
                                                              color: "error",
                                                              "x-small": ""
                                                            }
                                                          },
                                                          [
                                                            _vm._v(
                                                              "\n                    fas fa-exclamation-circle\n                  "
                                                            )
                                                          ]
                                                        )
                                                      ],
                                                      1
                                                    )
                                                  ]
                                                ),
                                                _c(
                                                  "v-row",
                                                  [
                                                    _c(
                                                      "v-col",
                                                      { attrs: { cols: "6" } },
                                                      [
                                                        _c("VeeTextField", {
                                                          attrs: {
                                                            dense: "",
                                                            disabled:
                                                              _vm.disableUserInfo,
                                                            label: _vm.$t(
                                                              "labels.firstName"
                                                            ),
                                                            name: "firstName",
                                                            outlined: "",
                                                            rules: _vm.disableUserInfo
                                                              ? ""
                                                              : "required|max:30"
                                                          },
                                                          model: {
                                                            value:
                                                              _vm.internalUser
                                                                .firstName,
                                                            callback: function(
                                                              $$v
                                                            ) {
                                                              _vm.$set(
                                                                _vm.internalUser,
                                                                "firstName",
                                                                $$v
                                                              )
                                                            },
                                                            expression:
                                                              "internalUser.firstName"
                                                          }
                                                        })
                                                      ],
                                                      1
                                                    ),
                                                    _c(
                                                      "v-col",
                                                      { attrs: { cols: "6" } },
                                                      [
                                                        _c("VeeTextField", {
                                                          attrs: {
                                                            dense: "",
                                                            disabled:
                                                              _vm.disableUserInfo,
                                                            label: _vm.$t(
                                                              "labels.lastName"
                                                            ),
                                                            name: "lastName",
                                                            outlined: "",
                                                            rules: _vm.disableUserInfo
                                                              ? ""
                                                              : "required|max:150"
                                                          },
                                                          model: {
                                                            value:
                                                              _vm.internalUser
                                                                .lastName,
                                                            callback: function(
                                                              $$v
                                                            ) {
                                                              _vm.$set(
                                                                _vm.internalUser,
                                                                "lastName",
                                                                $$v
                                                              )
                                                            },
                                                            expression:
                                                              "internalUser.lastName"
                                                          }
                                                        })
                                                      ],
                                                      1
                                                    )
                                                  ],
                                                  1
                                                ),
                                                _c(
                                                  "v-row",
                                                  [
                                                    _c(
                                                      "v-col",
                                                      { attrs: { cols: "6" } },
                                                      [
                                                        _c("VeeTextField", {
                                                          attrs: {
                                                            dense: "",
                                                            disabled:
                                                              _vm.disableUserInfo,
                                                            label:
                                                              _vm.$t(
                                                                "labels.alias"
                                                              ) +
                                                              " (" +
                                                              _vm.$t(
                                                                "labels.optional"
                                                              ) +
                                                              ")",
                                                            name: "alias",
                                                            outlined: "",
                                                            rules: _vm.disableUserInfo
                                                              ? ""
                                                              : "max:30"
                                                          },
                                                          model: {
                                                            value:
                                                              _vm.internalUser
                                                                .alias,
                                                            callback: function(
                                                              $$v
                                                            ) {
                                                              _vm.$set(
                                                                _vm.internalUser,
                                                                "alias",
                                                                $$v
                                                              )
                                                            },
                                                            expression:
                                                              "internalUser.alias"
                                                          }
                                                        })
                                                      ],
                                                      1
                                                    ),
                                                    _c(
                                                      "v-col",
                                                      { attrs: { cols: "6" } },
                                                      [
                                                        _c("v-select", {
                                                          attrs: {
                                                            clearable: "",
                                                            dense: "",
                                                            disabled:
                                                              _vm.disableUserInfo,
                                                            "hide-details": "",
                                                            items: [
                                                              {
                                                                text: _vm.$t(
                                                                  "labels.female"
                                                                ),
                                                                value: "female"
                                                              },
                                                              {
                                                                text: _vm.$t(
                                                                  "labels.male"
                                                                ),
                                                                value: "male"
                                                              }
                                                            ],
                                                            label:
                                                              _vm.$t(
                                                                "labels.gender"
                                                              ) +
                                                              " (" +
                                                              _vm.$t(
                                                                "labels.optional"
                                                              ) +
                                                              ")",
                                                            outlined: ""
                                                          },
                                                          model: {
                                                            value:
                                                              _vm.internalUser
                                                                .gender,
                                                            callback: function(
                                                              $$v
                                                            ) {
                                                              _vm.$set(
                                                                _vm.internalUser,
                                                                "gender",
                                                                $$v
                                                              )
                                                            },
                                                            expression:
                                                              "internalUser.gender"
                                                          }
                                                        })
                                                      ],
                                                      1
                                                    )
                                                  ],
                                                  1
                                                ),
                                                _c(
                                                  "v-row",
                                                  [
                                                    _c(
                                                      "v-col",
                                                      { attrs: { cols: "6" } },
                                                      [
                                                        _c("VeeTextField", {
                                                          directives: [
                                                            {
                                                              name: "mask",
                                                              rawName: "v-mask",
                                                              value:
                                                                _vm.phoneMask,
                                                              expression:
                                                                "phoneMask"
                                                            }
                                                          ],
                                                          staticClass:
                                                            "phone-number",
                                                          attrs: {
                                                            dense: "",
                                                            disabled:
                                                              _vm.disableUserInfo,
                                                            label:
                                                              "" +
                                                              _vm.$t(
                                                                "labels.phone"
                                                              ),
                                                            name: "phone",
                                                            outlined: "",
                                                            prefix: "+1",
                                                            rules: _vm.disableUserInfo
                                                              ? ""
                                                              : {
                                                                  phoneRegex_US:
                                                                    _vm.phoneRegex
                                                                }
                                                          },
                                                          model: {
                                                            value:
                                                              _vm.internalUser
                                                                .phonePersonal,
                                                            callback: function(
                                                              $$v
                                                            ) {
                                                              _vm.$set(
                                                                _vm.internalUser,
                                                                "phonePersonal",
                                                                $$v
                                                              )
                                                            },
                                                            expression:
                                                              "internalUser.phonePersonal"
                                                          }
                                                        })
                                                      ],
                                                      1
                                                    ),
                                                    _c(
                                                      "v-col",
                                                      { attrs: { cols: "6" } },
                                                      [
                                                        _c("VeeTextField", {
                                                          attrs: {
                                                            dense: "",
                                                            disabled:
                                                              _vm.disableUserInfo,
                                                            "hide-details": "",
                                                            label: _vm.$t(
                                                              "labels.email"
                                                            ),
                                                            name: "email",
                                                            outlined: "",
                                                            rules: _vm.disableUserInfo
                                                              ? ""
                                                              : "email|required|max:255"
                                                          },
                                                          model: {
                                                            value:
                                                              _vm.internalUser
                                                                .email,
                                                            callback: function(
                                                              $$v
                                                            ) {
                                                              _vm.$set(
                                                                _vm.internalUser,
                                                                "email",
                                                                typeof $$v ===
                                                                  "string"
                                                                  ? $$v.trim()
                                                                  : $$v
                                                              )
                                                            },
                                                            expression:
                                                              "internalUser.email"
                                                          }
                                                        })
                                                      ],
                                                      1
                                                    )
                                                  ],
                                                  1
                                                )
                                              ],
                                              1
                                            )
                                          ]
                                        }
                                      }
                                    ],
                                    null,
                                    true
                                  )
                                })
                              ],
                              1
                            ),
                            _c(
                              "v-tab-item",
                              { attrs: { eager: "", value: "profile" } },
                              [
                                _c("ValidationObserver", {
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "default",
                                        fn: function(ref) {
                                          var jobInfoInvalid = ref.invalid
                                          return [
                                            _c(
                                              "v-container",
                                              {
                                                staticClass: "pa-0 pt-4",
                                                style: _vm.contentStyle
                                              },
                                              [
                                                _c(
                                                  "portal",
                                                  {
                                                    attrs: {
                                                      to: "userDialogJobInfo"
                                                    }
                                                  },
                                                  [
                                                    _c(
                                                      "span",
                                                      [
                                                        _vm._v(
                                                          "\n                  " +
                                                            _vm._s(
                                                              _vm.$t(
                                                                "labels.jobInfo"
                                                              )
                                                            ) +
                                                            "\n                  "
                                                        ),
                                                        _c(
                                                          "v-icon",
                                                          {
                                                            style: {
                                                              visibility: jobInfoInvalid
                                                                ? "visible"
                                                                : "hidden"
                                                            },
                                                            attrs: {
                                                              color: "error",
                                                              "x-small": ""
                                                            }
                                                          },
                                                          [
                                                            _vm._v(
                                                              "\n                    fas fa-exclamation-circle\n                  "
                                                            )
                                                          ]
                                                        )
                                                      ],
                                                      1
                                                    )
                                                  ]
                                                ),
                                                _c(
                                                  "v-row",
                                                  [
                                                    _c(
                                                      "v-col",
                                                      { attrs: { cols: "6" } },
                                                      [
                                                        _c("VeeTextField", {
                                                          attrs: {
                                                            dense: "",
                                                            label: _vm.$t(
                                                              "labels.employeeID"
                                                            ),
                                                            name: "employeeId",
                                                            outlined: "",
                                                            rules:
                                                              "required|max:255"
                                                          },
                                                          model: {
                                                            value:
                                                              _vm.internalUser
                                                                .employeeId,
                                                            callback: function(
                                                              $$v
                                                            ) {
                                                              _vm.$set(
                                                                _vm.internalUser,
                                                                "employeeId",
                                                                $$v
                                                              )
                                                            },
                                                            expression:
                                                              "internalUser.employeeId"
                                                          }
                                                        })
                                                      ],
                                                      1
                                                    ),
                                                    _c(
                                                      "v-col",
                                                      { attrs: { cols: "6" } },
                                                      [
                                                        _c("VeeSelect", {
                                                          attrs: {
                                                            dense: "",
                                                            "item-text": "name",
                                                            "item-value": "id",
                                                            items:
                                                              _vm.filteredDepartments,
                                                            label: _vm.$t(
                                                              "labels.primaryDepartment"
                                                            ),
                                                            name:
                                                              "primaryDepartment",
                                                            outlined: "",
                                                            rules: "required"
                                                          },
                                                          model: {
                                                            value:
                                                              _vm.internalUser
                                                                .departmentId,
                                                            callback: function(
                                                              $$v
                                                            ) {
                                                              _vm.$set(
                                                                _vm.internalUser,
                                                                "departmentId",
                                                                $$v
                                                              )
                                                            },
                                                            expression:
                                                              "internalUser.departmentId"
                                                          }
                                                        })
                                                      ],
                                                      1
                                                    )
                                                  ],
                                                  1
                                                ),
                                                _c(
                                                  "v-row",
                                                  [
                                                    _c(
                                                      "v-col",
                                                      { attrs: { cols: "6" } },
                                                      [
                                                        _c("VeeSelect", {
                                                          attrs: {
                                                            dense: "",
                                                            "item-text": "name",
                                                            "item-value": "id",
                                                            items:
                                                              _vm.filteredJobTypes,
                                                            label: _vm.$tc(
                                                              "labels.jobType",
                                                              1
                                                            ),
                                                            name: "jobType",
                                                            outlined: "",
                                                            rules: "required"
                                                          },
                                                          model: {
                                                            value:
                                                              _vm.internalUser
                                                                .jobTypeId,
                                                            callback: function(
                                                              $$v
                                                            ) {
                                                              _vm.$set(
                                                                _vm.internalUser,
                                                                "jobTypeId",
                                                                $$v
                                                              )
                                                            },
                                                            expression:
                                                              "internalUser.jobTypeId"
                                                          }
                                                        })
                                                      ],
                                                      1
                                                    ),
                                                    _c(
                                                      "v-col",
                                                      { attrs: { cols: "6" } },
                                                      [
                                                        _c("VeeTextField", {
                                                          attrs: {
                                                            dense: "",
                                                            label:
                                                              _vm.$t(
                                                                "labels.jobTitle"
                                                              ) +
                                                              " (" +
                                                              _vm.$t(
                                                                "labels.optional"
                                                              ) +
                                                              ")",
                                                            name: "jobTitle",
                                                            outlined: "",
                                                            rules: "max:50"
                                                          },
                                                          model: {
                                                            value:
                                                              _vm.internalUser
                                                                .jobTitle,
                                                            callback: function(
                                                              $$v
                                                            ) {
                                                              _vm.$set(
                                                                _vm.internalUser,
                                                                "jobTitle",
                                                                $$v
                                                              )
                                                            },
                                                            expression:
                                                              "internalUser.jobTitle"
                                                          }
                                                        })
                                                      ],
                                                      1
                                                    )
                                                  ],
                                                  1
                                                ),
                                                _c(
                                                  "v-row",
                                                  [
                                                    _c(
                                                      "v-col",
                                                      { attrs: { cols: "6" } },
                                                      [
                                                        _c("VeeSelect", {
                                                          attrs: {
                                                            dense: "",
                                                            "item-text": "name",
                                                            "item-value": "id",
                                                            items:
                                                              _vm.shiftTypes,
                                                            label: _vm.$t(
                                                              "labels.primaryShift"
                                                            ),
                                                            name:
                                                              "primaryShift",
                                                            outlined: "",
                                                            rules: "required"
                                                          },
                                                          model: {
                                                            value:
                                                              _vm.internalUser
                                                                .shiftTypeId,
                                                            callback: function(
                                                              $$v
                                                            ) {
                                                              _vm.$set(
                                                                _vm.internalUser,
                                                                "shiftTypeId",
                                                                $$v
                                                              )
                                                            },
                                                            expression:
                                                              "internalUser.shiftTypeId"
                                                          }
                                                        })
                                                      ],
                                                      1
                                                    ),
                                                    _c(
                                                      "v-col",
                                                      { attrs: { cols: "6" } },
                                                      [
                                                        _c(
                                                          "v-menu",
                                                          {
                                                            ref:
                                                              "hiredOnPicker",
                                                            attrs: {
                                                              "close-on-content-click": false,
                                                              transition:
                                                                "scale-transition",
                                                              "offset-y": "",
                                                              "nudge-bottom": -25,
                                                              "max-width":
                                                                "290px",
                                                              "min-width":
                                                                "auto"
                                                            },
                                                            scopedSlots: _vm._u(
                                                              [
                                                                {
                                                                  key:
                                                                    "activator",
                                                                  fn: function(
                                                                    ref
                                                                  ) {
                                                                    var on =
                                                                      ref.on
                                                                    var attrs =
                                                                      ref.attrs
                                                                    return [
                                                                      _c(
                                                                        "VeeTextField",
                                                                        _vm._g(
                                                                          _vm._b(
                                                                            {
                                                                              directives: [
                                                                                {
                                                                                  name:
                                                                                    "mask",
                                                                                  rawName:
                                                                                    "v-mask",
                                                                                  value:
                                                                                    "##/##/####",
                                                                                  expression:
                                                                                    "'##/##/####'"
                                                                                }
                                                                              ],
                                                                              attrs: {
                                                                                autocomplete: false,
                                                                                dense:
                                                                                  "",
                                                                                label:
                                                                                  _vm.$t(
                                                                                    "labels.hireDate"
                                                                                  ) +
                                                                                  " (" +
                                                                                  _vm.$t(
                                                                                    "labels.optional"
                                                                                  ) +
                                                                                  ")",
                                                                                outlined:
                                                                                  "",
                                                                                placeholder:
                                                                                  "MM/DD/YYYY",
                                                                                rules: {
                                                                                  dateRegex_MMDDYYYY:
                                                                                    _vm.dateRegex
                                                                                }
                                                                              },
                                                                              on: {
                                                                                blur: function(
                                                                                  $event
                                                                                ) {
                                                                                  _vm.internalUser.jobInfo.hiredOn = _vm.parseDate(
                                                                                    _vm.hiredOn
                                                                                  )
                                                                                }
                                                                              },
                                                                              model: {
                                                                                value:
                                                                                  _vm.hiredOn,
                                                                                callback: function(
                                                                                  $$v
                                                                                ) {
                                                                                  _vm.hiredOn = $$v
                                                                                },
                                                                                expression:
                                                                                  "hiredOn"
                                                                              }
                                                                            },
                                                                            "VeeTextField",
                                                                            attrs,
                                                                            false
                                                                          ),
                                                                          on
                                                                        )
                                                                      )
                                                                    ]
                                                                  }
                                                                }
                                                              ],
                                                              null,
                                                              true
                                                            ),
                                                            model: {
                                                              value:
                                                                _vm.showHiredOnPicker,
                                                              callback: function(
                                                                $$v
                                                              ) {
                                                                _vm.showHiredOnPicker = $$v
                                                              },
                                                              expression:
                                                                "showHiredOnPicker"
                                                            }
                                                          },
                                                          [
                                                            _c(
                                                              "v-date-picker",
                                                              {
                                                                attrs: {
                                                                  "no-title": ""
                                                                },
                                                                on: {
                                                                  input: function(
                                                                    $event
                                                                  ) {
                                                                    _vm.showHiredOnPicker = false
                                                                  }
                                                                },
                                                                model: {
                                                                  value:
                                                                    _vm
                                                                      .internalUser
                                                                      .jobInfo
                                                                      .hiredOn,
                                                                  callback: function(
                                                                    $$v
                                                                  ) {
                                                                    _vm.$set(
                                                                      _vm
                                                                        .internalUser
                                                                        .jobInfo,
                                                                      "hiredOn",
                                                                      $$v
                                                                    )
                                                                  },
                                                                  expression:
                                                                    "internalUser.jobInfo.hiredOn"
                                                                }
                                                              }
                                                            )
                                                          ],
                                                          1
                                                        )
                                                      ],
                                                      1
                                                    )
                                                  ],
                                                  1
                                                ),
                                                _c(
                                                  "v-row",
                                                  [
                                                    _c(
                                                      "v-col",
                                                      { attrs: { cols: "12" } },
                                                      [
                                                        _c("VeeSelect", {
                                                          attrs: {
                                                            dense: "",
                                                            "item-text": "name",
                                                            "item-value": "id",
                                                            items:
                                                              _vm.jobStatus,
                                                            label: _vm.$t(
                                                              "labels.jobStatus"
                                                            ),
                                                            name: "jobStatus",
                                                            outlined: "",
                                                            rules: "required"
                                                          },
                                                          model: {
                                                            value:
                                                              _vm.internalUser
                                                                .jobStatusId,
                                                            callback: function(
                                                              $$v
                                                            ) {
                                                              _vm.$set(
                                                                _vm.internalUser,
                                                                "jobStatusId",
                                                                $$v
                                                              )
                                                            },
                                                            expression:
                                                              "internalUser.jobStatusId"
                                                          }
                                                        })
                                                      ],
                                                      1
                                                    )
                                                  ],
                                                  1
                                                ),
                                                _c(
                                                  "v-row",
                                                  [
                                                    _c(
                                                      "v-col",
                                                      {
                                                        staticClass: "pr-0",
                                                        attrs: { cols: "5" }
                                                      },
                                                      [
                                                        _c("VeeTextField", {
                                                          attrs: {
                                                            dense: "",
                                                            label: _vm.$t(
                                                              "labels.dailyHours"
                                                            ),
                                                            name: "dailHours",
                                                            outlined: "",
                                                            rules:
                                                              "required|numeric|max_value:24|min_value:0"
                                                          },
                                                          model: {
                                                            value:
                                                              _vm.internalUser
                                                                .dailyHours,
                                                            callback: function(
                                                              $$v
                                                            ) {
                                                              _vm.$set(
                                                                _vm.internalUser,
                                                                "dailyHours",
                                                                _vm._n($$v)
                                                              )
                                                            },
                                                            expression:
                                                              "internalUser.dailyHours"
                                                          }
                                                        })
                                                      ],
                                                      1
                                                    ),
                                                    _c(
                                                      "v-col",
                                                      { attrs: { cols: "1" } },
                                                      [
                                                        _c(
                                                          "v-tooltip",
                                                          {
                                                            attrs: {
                                                              "max-width":
                                                                "300px",
                                                              top: ""
                                                            },
                                                            scopedSlots: _vm._u(
                                                              [
                                                                {
                                                                  key:
                                                                    "activator",
                                                                  fn: function(
                                                                    ref
                                                                  ) {
                                                                    var on =
                                                                      ref.on
                                                                    var attrs =
                                                                      ref.attrs
                                                                    return [
                                                                      _c(
                                                                        "v-icon",
                                                                        _vm._g(
                                                                          _vm._b(
                                                                            {
                                                                              staticClass:
                                                                                "pt-3",
                                                                              attrs: {
                                                                                color:
                                                                                  "info",
                                                                                small:
                                                                                  ""
                                                                              }
                                                                            },
                                                                            "v-icon",
                                                                            attrs,
                                                                            false
                                                                          ),
                                                                          on
                                                                        ),
                                                                        [
                                                                          _vm._v(
                                                                            "\n                        fal fa-question-circle\n                      "
                                                                          )
                                                                        ]
                                                                      )
                                                                    ]
                                                                  }
                                                                }
                                                              ],
                                                              null,
                                                              true
                                                            )
                                                          },
                                                          [
                                                            _c(
                                                              "span",
                                                              {
                                                                staticClass:
                                                                  "body-2"
                                                              },
                                                              [
                                                                _vm._v(
                                                                  "\n                      " +
                                                                    _vm._s(
                                                                      _vm.$t(
                                                                        "descriptions.overtimeDayHint"
                                                                      )
                                                                    ) +
                                                                    "\n                      "
                                                                ),
                                                                _c("br"),
                                                                _vm._v(
                                                                  "\n                      " +
                                                                    _vm._s(
                                                                      _vm.$t(
                                                                        "descriptions.overtimeDaySkipHint"
                                                                      )
                                                                    ) +
                                                                    "\n                    "
                                                                )
                                                              ]
                                                            )
                                                          ]
                                                        )
                                                      ],
                                                      1
                                                    ),
                                                    _c(
                                                      "v-col",
                                                      {
                                                        staticClass: "pr-0",
                                                        attrs: { cols: "5" }
                                                      },
                                                      [
                                                        _c("VeeTextField", {
                                                          attrs: {
                                                            dense: "",
                                                            label: _vm.$t(
                                                              "labels.weeklyHours"
                                                            ),
                                                            name: "weeklyHours",
                                                            outlined: "",
                                                            rules:
                                                              "required|numeric|max_value:168|min_value:0"
                                                          },
                                                          model: {
                                                            value:
                                                              _vm.internalUser
                                                                .weeklyHours,
                                                            callback: function(
                                                              $$v
                                                            ) {
                                                              _vm.$set(
                                                                _vm.internalUser,
                                                                "weeklyHours",
                                                                _vm._n($$v)
                                                              )
                                                            },
                                                            expression:
                                                              "internalUser.weeklyHours"
                                                          }
                                                        })
                                                      ],
                                                      1
                                                    ),
                                                    _c(
                                                      "v-col",
                                                      { attrs: { cols: "1" } },
                                                      [
                                                        _c(
                                                          "v-tooltip",
                                                          {
                                                            attrs: {
                                                              "max-width":
                                                                "300px",
                                                              top: ""
                                                            },
                                                            scopedSlots: _vm._u(
                                                              [
                                                                {
                                                                  key:
                                                                    "activator",
                                                                  fn: function(
                                                                    ref
                                                                  ) {
                                                                    var on =
                                                                      ref.on
                                                                    var attrs =
                                                                      ref.attrs
                                                                    return [
                                                                      _c(
                                                                        "v-icon",
                                                                        _vm._g(
                                                                          _vm._b(
                                                                            {
                                                                              staticClass:
                                                                                "pt-3",
                                                                              attrs: {
                                                                                color:
                                                                                  "info",
                                                                                small:
                                                                                  ""
                                                                              }
                                                                            },
                                                                            "v-icon",
                                                                            attrs,
                                                                            false
                                                                          ),
                                                                          on
                                                                        ),
                                                                        [
                                                                          _vm._v(
                                                                            "\n                        fal fa-question-circle\n                      "
                                                                          )
                                                                        ]
                                                                      )
                                                                    ]
                                                                  }
                                                                }
                                                              ],
                                                              null,
                                                              true
                                                            )
                                                          },
                                                          [
                                                            _c(
                                                              "span",
                                                              {
                                                                staticClass:
                                                                  "body-2"
                                                              },
                                                              [
                                                                _vm._v(
                                                                  "\n                      " +
                                                                    _vm._s(
                                                                      _vm.$t(
                                                                        "descriptions.overtimeWeekHint"
                                                                      )
                                                                    ) +
                                                                    "\n                      "
                                                                ),
                                                                _c("br"),
                                                                _vm._v(
                                                                  "\n                      " +
                                                                    _vm._s(
                                                                      _vm.$t(
                                                                        "descriptions.overtimeWeekSkipHint"
                                                                      )
                                                                    ) +
                                                                    "\n                    "
                                                                )
                                                              ]
                                                            )
                                                          ]
                                                        )
                                                      ],
                                                      1
                                                    )
                                                  ],
                                                  1
                                                ),
                                                _vm.canBeChargeNurse
                                                  ? _c(
                                                      "v-row",
                                                      [
                                                        _c(
                                                          "v-col",
                                                          {
                                                            attrs: {
                                                              cols: "12"
                                                            }
                                                          },
                                                          [
                                                            _c("v-switch", {
                                                              staticClass:
                                                                "pt-0 mt-0 ml-1 d-inline-block charge-toggle",
                                                              attrs: {
                                                                color:
                                                                  "success",
                                                                dense: "",
                                                                "hide-details":
                                                                  "",
                                                                inset: ""
                                                              },
                                                              scopedSlots: _vm._u(
                                                                [
                                                                  {
                                                                    key:
                                                                      "label",
                                                                    fn: function() {
                                                                      return [
                                                                        _c(
                                                                          "span",
                                                                          {
                                                                            staticClass:
                                                                              "mr-4 body-2 grey--text text--darken-3",
                                                                            attrs: {
                                                                              title: _vm.$t(
                                                                                "labels.userCanActAsCharge"
                                                                              )
                                                                            }
                                                                          },
                                                                          [
                                                                            _vm._v(
                                                                              "\n                        " +
                                                                                _vm._s(
                                                                                  _vm.$t(
                                                                                    "labels.userCanActAsCharge"
                                                                                  )
                                                                                ) +
                                                                                "\n                      "
                                                                            )
                                                                          ]
                                                                        )
                                                                      ]
                                                                    },
                                                                    proxy: true
                                                                  }
                                                                ],
                                                                null,
                                                                true
                                                              ),
                                                              model: {
                                                                value:
                                                                  _vm
                                                                    .internalUser
                                                                    .charge,
                                                                callback: function(
                                                                  $$v
                                                                ) {
                                                                  _vm.$set(
                                                                    _vm.internalUser,
                                                                    "charge",
                                                                    $$v
                                                                  )
                                                                },
                                                                expression:
                                                                  "internalUser.charge"
                                                              }
                                                            })
                                                          ],
                                                          1
                                                        )
                                                      ],
                                                      1
                                                    )
                                                  : _vm._e()
                                              ],
                                              1
                                            )
                                          ]
                                        }
                                      }
                                    ],
                                    null,
                                    true
                                  )
                                })
                              ],
                              1
                            ),
                            _c(
                              "v-tab-item",
                              { attrs: { eager: "", value: "status" } },
                              [
                                _c("ValidationObserver", {
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "default",
                                        fn: function(ref) {
                                          var statusInvalid = ref.invalid
                                          return [
                                            _c(
                                              "v-container",
                                              {
                                                staticClass: "pa-0 pt-4",
                                                style: _vm.contentStyle
                                              },
                                              [
                                                _c(
                                                  "portal",
                                                  {
                                                    attrs: {
                                                      to: "userDialogStatus"
                                                    }
                                                  },
                                                  [
                                                    _c(
                                                      "span",
                                                      [
                                                        _vm._v(
                                                          "\n                  " +
                                                            _vm._s(
                                                              _vm.$t(
                                                                "labels.status"
                                                              )
                                                            ) +
                                                            "\n                  "
                                                        ),
                                                        _c(
                                                          "v-icon",
                                                          {
                                                            style: {
                                                              visibility: statusInvalid
                                                                ? "visible"
                                                                : "hidden"
                                                            },
                                                            attrs: {
                                                              color: "error",
                                                              "x-small": ""
                                                            }
                                                          },
                                                          [
                                                            _vm._v(
                                                              "\n                    fas fa-exclamation-circle\n                  "
                                                            )
                                                          ]
                                                        )
                                                      ],
                                                      1
                                                    )
                                                  ]
                                                ),
                                                _vm.mode === _vm.modeNewUser ||
                                                _vm.mode ===
                                                  _vm.modeNewProfile ||
                                                _vm.mode ===
                                                  _vm.modePendingProfile
                                                  ? [
                                                      _c(
                                                        "v-row",
                                                        {
                                                          attrs: {
                                                            dense: "",
                                                            "no-gutters": ""
                                                          }
                                                        },
                                                        [
                                                          _c(
                                                            "v-col",
                                                            {
                                                              attrs: {
                                                                cols: "12"
                                                              }
                                                            },
                                                            [
                                                              _c(
                                                                "span",
                                                                {
                                                                  staticClass:
                                                                    "caption grey--text text--darken-3"
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    "\n                      " +
                                                                      _vm._s(
                                                                        _vm.$t(
                                                                          "labels.activateUser"
                                                                        )
                                                                      ) +
                                                                      "\n                    "
                                                                  )
                                                                ]
                                                              )
                                                            ]
                                                          )
                                                        ],
                                                        1
                                                      ),
                                                      _c(
                                                        "v-row",
                                                        {
                                                          staticClass:
                                                            "new-profile",
                                                          attrs: {
                                                            dense: "",
                                                            "no-gutters": ""
                                                          }
                                                        },
                                                        [
                                                          _c(
                                                            "v-col",
                                                            {
                                                              attrs: {
                                                                cols: "12"
                                                              }
                                                            },
                                                            [
                                                              _c(
                                                                "v-radio-group",
                                                                {
                                                                  staticClass:
                                                                    "mt-2",
                                                                  attrs: {
                                                                    column: "",
                                                                    dense: ""
                                                                  },
                                                                  model: {
                                                                    value:
                                                                      _vm.status
                                                                        .new
                                                                        .activate,
                                                                    callback: function(
                                                                      $$v
                                                                    ) {
                                                                      _vm.$set(
                                                                        _vm
                                                                          .status
                                                                          .new,
                                                                        "activate",
                                                                        $$v
                                                                      )
                                                                    },
                                                                    expression:
                                                                      "status.new.activate"
                                                                  }
                                                                },
                                                                [
                                                                  _c(
                                                                    "v-radio",
                                                                    {
                                                                      attrs: {
                                                                        label: _vm.$t(
                                                                          "labels.immediately"
                                                                        ),
                                                                        value:
                                                                          "now"
                                                                      }
                                                                    }
                                                                  ),
                                                                  _c(
                                                                    "v-radio",
                                                                    {
                                                                      staticClass:
                                                                        "effective-from",
                                                                      attrs: {
                                                                        label: _vm.$t(
                                                                          "labels.specificDate"
                                                                        ),
                                                                        value:
                                                                          "later"
                                                                      },
                                                                      scopedSlots: _vm._u(
                                                                        [
                                                                          {
                                                                            key:
                                                                              "label",
                                                                            fn: function() {
                                                                              return [
                                                                                _c(
                                                                                  "div",
                                                                                  [
                                                                                    _c(
                                                                                      "span",
                                                                                      [
                                                                                        _vm._v(
                                                                                          "\n                              " +
                                                                                            _vm._s(
                                                                                              _vm.$t(
                                                                                                "labels.specificDate"
                                                                                              )
                                                                                            ) +
                                                                                            "\n                            "
                                                                                        )
                                                                                      ]
                                                                                    ),
                                                                                    _c(
                                                                                      "v-menu",
                                                                                      {
                                                                                        ref:
                                                                                          "effectiveFromPicker",
                                                                                        attrs: {
                                                                                          "close-on-content-click": false,
                                                                                          transition:
                                                                                            "scale-transition",
                                                                                          "offset-y":
                                                                                            "",
                                                                                          "nudge-bottom": -25,
                                                                                          "max-width":
                                                                                            "290px",
                                                                                          "min-width":
                                                                                            "auto"
                                                                                        },
                                                                                        scopedSlots: _vm._u(
                                                                                          [
                                                                                            {
                                                                                              key:
                                                                                                "activator",
                                                                                              fn: function(
                                                                                                ref
                                                                                              ) {
                                                                                                var on =
                                                                                                  ref.on
                                                                                                var attrs =
                                                                                                  ref.attrs
                                                                                                return [
                                                                                                  _c(
                                                                                                    "VeeTextField",
                                                                                                    _vm._g(
                                                                                                      _vm._b(
                                                                                                        {
                                                                                                          directives: [
                                                                                                            {
                                                                                                              name:
                                                                                                                "mask",
                                                                                                              rawName:
                                                                                                                "v-mask",
                                                                                                              value:
                                                                                                                "##/##/####",
                                                                                                              expression:
                                                                                                                "'##/##/####'"
                                                                                                            }
                                                                                                          ],
                                                                                                          staticClass:
                                                                                                            "d-inline-block ml-2",
                                                                                                          attrs: {
                                                                                                            autocomplete: false,
                                                                                                            dense:
                                                                                                              "",
                                                                                                            disabled:
                                                                                                              _vm
                                                                                                                .status
                                                                                                                .new
                                                                                                                .activate !==
                                                                                                              "later",
                                                                                                            label: _vm.$tc(
                                                                                                              "labels.date",
                                                                                                              1
                                                                                                            ),
                                                                                                            outlined:
                                                                                                              "",
                                                                                                            placeholder:
                                                                                                              "MM/DD/YYYY",
                                                                                                            rules: {
                                                                                                              dateRegex_MMDDYYYY:
                                                                                                                _vm.dateRegex,
                                                                                                              required:
                                                                                                                _vm
                                                                                                                  .status
                                                                                                                  .new
                                                                                                                  .activate ===
                                                                                                                "later"
                                                                                                            }
                                                                                                          },
                                                                                                          on: {
                                                                                                            blur: function(
                                                                                                              $event
                                                                                                            ) {
                                                                                                              _vm.status.new.effectiveFrom = _vm.parseDate(
                                                                                                                _vm
                                                                                                                  .status
                                                                                                                  .new
                                                                                                                  .effectiveFromFormatted
                                                                                                              )
                                                                                                            }
                                                                                                          },
                                                                                                          model: {
                                                                                                            value:
                                                                                                              _vm
                                                                                                                .status
                                                                                                                .new
                                                                                                                .effectiveFromFormatted,
                                                                                                            callback: function(
                                                                                                              $$v
                                                                                                            ) {
                                                                                                              _vm.$set(
                                                                                                                _vm
                                                                                                                  .status
                                                                                                                  .new,
                                                                                                                "effectiveFromFormatted",
                                                                                                                $$v
                                                                                                              )
                                                                                                            },
                                                                                                            expression:
                                                                                                              "status.new.effectiveFromFormatted"
                                                                                                          }
                                                                                                        },
                                                                                                        "VeeTextField",
                                                                                                        attrs,
                                                                                                        false
                                                                                                      ),
                                                                                                      on
                                                                                                    )
                                                                                                  )
                                                                                                ]
                                                                                              }
                                                                                            }
                                                                                          ],
                                                                                          null,
                                                                                          true
                                                                                        ),
                                                                                        model: {
                                                                                          value:
                                                                                            _vm
                                                                                              .status
                                                                                              .new
                                                                                              .showEffectiveFromPicker,
                                                                                          callback: function(
                                                                                            $$v
                                                                                          ) {
                                                                                            _vm.$set(
                                                                                              _vm
                                                                                                .status
                                                                                                .new,
                                                                                              "showEffectiveFromPicker",
                                                                                              $$v
                                                                                            )
                                                                                          },
                                                                                          expression:
                                                                                            "status.new.showEffectiveFromPicker"
                                                                                        }
                                                                                      },
                                                                                      [
                                                                                        _c(
                                                                                          "v-date-picker",
                                                                                          {
                                                                                            attrs: {
                                                                                              "no-title":
                                                                                                ""
                                                                                            },
                                                                                            on: {
                                                                                              input: function(
                                                                                                $event
                                                                                              ) {
                                                                                                _vm.status.new.showEffectiveFromPicker = false
                                                                                              }
                                                                                            },
                                                                                            model: {
                                                                                              value:
                                                                                                _vm
                                                                                                  .status
                                                                                                  .new
                                                                                                  .effectiveFrom,
                                                                                              callback: function(
                                                                                                $$v
                                                                                              ) {
                                                                                                _vm.$set(
                                                                                                  _vm
                                                                                                    .status
                                                                                                    .new,
                                                                                                  "effectiveFrom",
                                                                                                  $$v
                                                                                                )
                                                                                              },
                                                                                              expression:
                                                                                                "status.new.effectiveFrom"
                                                                                            }
                                                                                          }
                                                                                        )
                                                                                      ],
                                                                                      1
                                                                                    )
                                                                                  ],
                                                                                  1
                                                                                )
                                                                              ]
                                                                            },
                                                                            proxy: true
                                                                          }
                                                                        ],
                                                                        null,
                                                                        true
                                                                      )
                                                                    }
                                                                  )
                                                                ],
                                                                1
                                                              )
                                                            ],
                                                            1
                                                          )
                                                        ],
                                                        1
                                                      )
                                                    ]
                                                  : [
                                                      _c(
                                                        "fieldset",
                                                        {
                                                          staticClass:
                                                            "nb-fieldset"
                                                        },
                                                        [
                                                          _c("legend", [
                                                            _vm._v(
                                                              _vm._s(
                                                                _vm.$t(
                                                                  "labels.currentStatus"
                                                                )
                                                              )
                                                            )
                                                          ]),
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "pb-2"
                                                            },
                                                            [
                                                              _vm._v(
                                                                "\n                    " +
                                                                  _vm._s(
                                                                    _vm.$t(
                                                                      "labels." +
                                                                        _vm.user
                                                                          .state
                                                                    )
                                                                  ) +
                                                                  "\n                  "
                                                              )
                                                            ]
                                                          )
                                                        ]
                                                      ),
                                                      _vm.user.state ===
                                                        _vm.ACCOUNT_STATE
                                                          .inactive &&
                                                      _vm.status.existing
                                                        .appliedShiftFlags
                                                        .length > 0
                                                        ? _c(
                                                            "v-row",
                                                            {
                                                              staticClass:
                                                                "mt-4",
                                                              attrs: {
                                                                "no-gutters": ""
                                                              }
                                                            },
                                                            [
                                                              _c(
                                                                "v-col",
                                                                {
                                                                  attrs: {
                                                                    cols: "12"
                                                                  }
                                                                },
                                                                [
                                                                  _c(
                                                                    "v-combobox",
                                                                    {
                                                                      staticClass:
                                                                        "shift-flags mb-2",
                                                                      attrs: {
                                                                        "small-chips":
                                                                          "",
                                                                        dense:
                                                                          "",
                                                                        disabled:
                                                                          "",
                                                                        filter:
                                                                          _vm.flagsFilter,
                                                                        "hide-details":
                                                                          "",
                                                                        "item-text":
                                                                          "shortCode",
                                                                        "item-value":
                                                                          "id",
                                                                        items:
                                                                          _vm.shiftFlags,
                                                                        label: _vm.$tc(
                                                                          "labels.flag",
                                                                          2
                                                                        ),
                                                                        multiple:
                                                                          "",
                                                                        outlined:
                                                                          "",
                                                                        "return-object": false
                                                                      },
                                                                      scopedSlots: _vm._u(
                                                                        [
                                                                          {
                                                                            key:
                                                                              "selection",
                                                                            fn: function(
                                                                              ref
                                                                            ) {
                                                                              var flagId =
                                                                                ref.item
                                                                              return [
                                                                                _vm
                                                                                  .shiftFlagsById[
                                                                                  flagId
                                                                                ]
                                                                                  ? _c(
                                                                                      "v-chip",
                                                                                      {
                                                                                        staticClass:
                                                                                          "lighten-2 grey--text text--darken-3 flag-short-code mb-1",
                                                                                        attrs: {
                                                                                          color:
                                                                                            "info",
                                                                                          small:
                                                                                            "",
                                                                                          title:
                                                                                            _vm
                                                                                              .shiftFlagsById[
                                                                                              flagId
                                                                                            ]
                                                                                              .name
                                                                                        }
                                                                                      },
                                                                                      [
                                                                                        _vm._v(
                                                                                          "\n                          " +
                                                                                            _vm._s(
                                                                                              _vm
                                                                                                .shiftFlagsById[
                                                                                                flagId
                                                                                              ]
                                                                                                .shortCode
                                                                                            ) +
                                                                                            "\n                        "
                                                                                        )
                                                                                      ]
                                                                                    )
                                                                                  : _vm._e()
                                                                              ]
                                                                            }
                                                                          },
                                                                          {
                                                                            key:
                                                                              "item",
                                                                            fn: function(
                                                                              ref
                                                                            ) {
                                                                              var attrs =
                                                                                ref.attrs
                                                                              var flag =
                                                                                ref.item
                                                                              var on =
                                                                                ref.on
                                                                              return [
                                                                                _c(
                                                                                  "v-list-item",
                                                                                  _vm._g(
                                                                                    _vm._b(
                                                                                      {
                                                                                        class: [
                                                                                          _vm.status.existing.appliedShiftFlags.includes(
                                                                                            flag.id
                                                                                          )
                                                                                            ? "v-list-item--active"
                                                                                            : "",
                                                                                          "flag-item"
                                                                                        ],
                                                                                        attrs: {
                                                                                          title:
                                                                                            flag.name
                                                                                        }
                                                                                      },
                                                                                      "v-list-item",
                                                                                      attrs,
                                                                                      false
                                                                                    ),
                                                                                    on
                                                                                  ),
                                                                                  [
                                                                                    _c(
                                                                                      "v-list-item-icon",
                                                                                      {
                                                                                        staticClass:
                                                                                          "mr-0",
                                                                                        staticStyle: {
                                                                                          width:
                                                                                            "60px"
                                                                                        }
                                                                                      },
                                                                                      [
                                                                                        _c(
                                                                                          "v-chip",
                                                                                          {
                                                                                            staticClass:
                                                                                              "lighten-2 grey--text text--darken-3 flag-short-code",
                                                                                            attrs: {
                                                                                              color:
                                                                                                "info",
                                                                                              small:
                                                                                                ""
                                                                                            }
                                                                                          },
                                                                                          [
                                                                                            _vm._v(
                                                                                              "\n                              " +
                                                                                                _vm._s(
                                                                                                  flag.shortCode
                                                                                                ) +
                                                                                                "\n                            "
                                                                                            )
                                                                                          ]
                                                                                        )
                                                                                      ],
                                                                                      1
                                                                                    ),
                                                                                    _c(
                                                                                      "v-list-item-content",
                                                                                      [
                                                                                        _c(
                                                                                          "span",
                                                                                          {
                                                                                            staticClass:
                                                                                              "grey--text text--darken-3 body-2 d-inline-block text-truncate",
                                                                                            staticStyle: {
                                                                                              width:
                                                                                                "100px"
                                                                                            }
                                                                                          },
                                                                                          [
                                                                                            _vm._v(
                                                                                              "\n                              " +
                                                                                                _vm._s(
                                                                                                  flag.name
                                                                                                ) +
                                                                                                "\n                            "
                                                                                            )
                                                                                          ]
                                                                                        )
                                                                                      ]
                                                                                    ),
                                                                                    _c(
                                                                                      "v-list-item-action",
                                                                                      [
                                                                                        _c(
                                                                                          "v-icon",
                                                                                          {
                                                                                            style: {
                                                                                              visibility: _vm.status.existing.appliedShiftFlags.includes(
                                                                                                flag.id
                                                                                              )
                                                                                                ? "visible"
                                                                                                : "hidden"
                                                                                            },
                                                                                            attrs: {
                                                                                              color:
                                                                                                "secondary",
                                                                                              small:
                                                                                                ""
                                                                                            }
                                                                                          },
                                                                                          [
                                                                                            _vm._v(
                                                                                              "\n                              fas fa-check-circle\n                            "
                                                                                            )
                                                                                          ]
                                                                                        )
                                                                                      ],
                                                                                      1
                                                                                    )
                                                                                  ],
                                                                                  1
                                                                                )
                                                                              ]
                                                                            }
                                                                          }
                                                                        ],
                                                                        null,
                                                                        true
                                                                      ),
                                                                      model: {
                                                                        value:
                                                                          _vm
                                                                            .status
                                                                            .existing
                                                                            .appliedShiftFlags,
                                                                        callback: function(
                                                                          $$v
                                                                        ) {
                                                                          _vm.$set(
                                                                            _vm
                                                                              .status
                                                                              .existing,
                                                                            "appliedShiftFlags",
                                                                            $$v
                                                                          )
                                                                        },
                                                                        expression:
                                                                          "status.existing.appliedShiftFlags"
                                                                      }
                                                                    }
                                                                  ),
                                                                  _c(
                                                                    "span",
                                                                    {
                                                                      staticClass:
                                                                        "grey--text caption mt-2"
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        "\n                      " +
                                                                          _vm._s(
                                                                            _vm.$t(
                                                                              "descriptions.userStatusShiftFlags"
                                                                            )
                                                                          ) +
                                                                          "\n                    "
                                                                      )
                                                                    ]
                                                                  )
                                                                ],
                                                                1
                                                              )
                                                            ],
                                                            1
                                                          )
                                                        : _vm._e(),
                                                      _c(
                                                        "v-card",
                                                        {
                                                          attrs: {
                                                            color:
                                                              "grey lighten-4 pa-4 mt-6",
                                                            elevation: 0
                                                          }
                                                        },
                                                        [
                                                          _c(
                                                            "v-row",
                                                            {
                                                              attrs: {
                                                                "no-gutters": ""
                                                              }
                                                            },
                                                            [
                                                              _c(
                                                                "v-col",
                                                                {
                                                                  attrs: {
                                                                    cols: "12"
                                                                  }
                                                                },
                                                                [
                                                                  _c("span", [
                                                                    _vm._v(
                                                                      "\n                        " +
                                                                        _vm._s(
                                                                          _vm.$t(
                                                                            "labels.changeStatus"
                                                                          )
                                                                        ) +
                                                                        "\n                      "
                                                                    )
                                                                  ])
                                                                ]
                                                              )
                                                            ],
                                                            1
                                                          ),
                                                          _c(
                                                            "v-row",
                                                            {
                                                              staticClass:
                                                                "mt-4",
                                                              attrs: {
                                                                "no-gutters": ""
                                                              }
                                                            },
                                                            [
                                                              _c(
                                                                "v-col",
                                                                {
                                                                  attrs: {
                                                                    cols: "12"
                                                                  }
                                                                },
                                                                [
                                                                  _c(
                                                                    "v-select",
                                                                    {
                                                                      attrs: {
                                                                        clearable:
                                                                          "",
                                                                        dense:
                                                                          "",
                                                                        "hide-details":
                                                                          "",
                                                                        "item-text":
                                                                          "name",
                                                                        "item-value":
                                                                          "id",
                                                                        items:
                                                                          _vm.statusList,
                                                                        label: _vm.$t(
                                                                          "labels.newStatus"
                                                                        ),
                                                                        name:
                                                                          "newState",
                                                                        outlined:
                                                                          ""
                                                                      },
                                                                      model: {
                                                                        value:
                                                                          _vm
                                                                            .status
                                                                            .existing
                                                                            .newStatus,
                                                                        callback: function(
                                                                          $$v
                                                                        ) {
                                                                          _vm.$set(
                                                                            _vm
                                                                              .status
                                                                              .existing,
                                                                            "newStatus",
                                                                            $$v
                                                                          )
                                                                        },
                                                                        expression:
                                                                          "status.existing.newStatus"
                                                                      }
                                                                    }
                                                                  )
                                                                ],
                                                                1
                                                              )
                                                            ],
                                                            1
                                                          ),
                                                          _vm.status.existing
                                                            .newStatus ===
                                                          _vm.ACCOUNT_STATE
                                                            .inactive
                                                            ? _c(
                                                                "v-row",
                                                                {
                                                                  staticClass:
                                                                    "mt-4",
                                                                  attrs: {
                                                                    "no-gutters":
                                                                      ""
                                                                  }
                                                                },
                                                                [
                                                                  _c(
                                                                    "v-col",
                                                                    {
                                                                      attrs: {
                                                                        cols:
                                                                          "12"
                                                                      }
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "v-combobox",
                                                                        {
                                                                          ref:
                                                                            "selectShiftFlag",
                                                                          staticClass:
                                                                            "shift-flags mb-2",
                                                                          attrs: {
                                                                            "small-chips":
                                                                              "",
                                                                            dense:
                                                                              "",
                                                                            filter:
                                                                              _vm.flagsFilter,
                                                                            "hide-details":
                                                                              "",
                                                                            "item-text":
                                                                              "shortCode",
                                                                            "item-value":
                                                                              "id",
                                                                            items:
                                                                              _vm.shiftFlags,
                                                                            label: _vm.$tc(
                                                                              "labels.flag",
                                                                              2
                                                                            ),
                                                                            multiple:
                                                                              "",
                                                                            outlined:
                                                                              "",
                                                                            "return-object": false
                                                                          },
                                                                          scopedSlots: _vm._u(
                                                                            [
                                                                              {
                                                                                key:
                                                                                  "selection",
                                                                                fn: function(
                                                                                  ref
                                                                                ) {
                                                                                  var flagId =
                                                                                    ref.item
                                                                                  return [
                                                                                    _vm
                                                                                      .shiftFlagsById[
                                                                                      flagId
                                                                                    ]
                                                                                      ? _c(
                                                                                          "v-chip",
                                                                                          {
                                                                                            staticClass:
                                                                                              "lighten-2 grey--text text--darken-3 flag-short-code mb-1",
                                                                                            attrs: {
                                                                                              color:
                                                                                                "info",
                                                                                              small:
                                                                                                "",
                                                                                              title:
                                                                                                _vm
                                                                                                  .shiftFlagsById[
                                                                                                  flagId
                                                                                                ]
                                                                                                  .name
                                                                                            }
                                                                                          },
                                                                                          [
                                                                                            _vm._v(
                                                                                              "\n                            " +
                                                                                                _vm._s(
                                                                                                  _vm
                                                                                                    .shiftFlagsById[
                                                                                                    flagId
                                                                                                  ]
                                                                                                    .shortCode
                                                                                                ) +
                                                                                                "\n                          "
                                                                                            )
                                                                                          ]
                                                                                        )
                                                                                      : _vm._e()
                                                                                  ]
                                                                                }
                                                                              },
                                                                              {
                                                                                key:
                                                                                  "item",
                                                                                fn: function(
                                                                                  ref
                                                                                ) {
                                                                                  var attrs =
                                                                                    ref.attrs
                                                                                  var flag =
                                                                                    ref.item
                                                                                  var on =
                                                                                    ref.on
                                                                                  return [
                                                                                    _c(
                                                                                      "v-list-item",
                                                                                      _vm._g(
                                                                                        _vm._b(
                                                                                          {
                                                                                            class: [
                                                                                              _vm.status.existing.shiftFlags.includes(
                                                                                                flag.id
                                                                                              )
                                                                                                ? "v-list-item--active"
                                                                                                : "",
                                                                                              "flag-item"
                                                                                            ],
                                                                                            attrs: {
                                                                                              title:
                                                                                                flag.name
                                                                                            }
                                                                                          },
                                                                                          "v-list-item",
                                                                                          attrs,
                                                                                          false
                                                                                        ),
                                                                                        on
                                                                                      ),
                                                                                      [
                                                                                        _c(
                                                                                          "v-list-item-icon",
                                                                                          {
                                                                                            staticClass:
                                                                                              "mr-0",
                                                                                            staticStyle: {
                                                                                              width:
                                                                                                "60px"
                                                                                            }
                                                                                          },
                                                                                          [
                                                                                            _c(
                                                                                              "v-chip",
                                                                                              {
                                                                                                staticClass:
                                                                                                  "lighten-2 grey--text text--darken-3 flag-short-code",
                                                                                                attrs: {
                                                                                                  color:
                                                                                                    "info",
                                                                                                  small:
                                                                                                    ""
                                                                                                }
                                                                                              },
                                                                                              [
                                                                                                _vm._v(
                                                                                                  "\n                                " +
                                                                                                    _vm._s(
                                                                                                      flag.shortCode
                                                                                                    ) +
                                                                                                    "\n                              "
                                                                                                )
                                                                                              ]
                                                                                            )
                                                                                          ],
                                                                                          1
                                                                                        ),
                                                                                        _c(
                                                                                          "v-list-item-content",
                                                                                          [
                                                                                            _c(
                                                                                              "span",
                                                                                              {
                                                                                                staticClass:
                                                                                                  "grey--text text--darken-3 body-2 d-inline-block text-truncate",
                                                                                                staticStyle: {
                                                                                                  width:
                                                                                                    "100px"
                                                                                                }
                                                                                              },
                                                                                              [
                                                                                                _vm._v(
                                                                                                  "\n                                " +
                                                                                                    _vm._s(
                                                                                                      flag.name
                                                                                                    ) +
                                                                                                    "\n                              "
                                                                                                )
                                                                                              ]
                                                                                            )
                                                                                          ]
                                                                                        ),
                                                                                        _c(
                                                                                          "v-list-item-action",
                                                                                          [
                                                                                            _c(
                                                                                              "v-icon",
                                                                                              {
                                                                                                style: {
                                                                                                  visibility: _vm.status.existing.shiftFlags.includes(
                                                                                                    flag.id
                                                                                                  )
                                                                                                    ? "visible"
                                                                                                    : "hidden"
                                                                                                },
                                                                                                attrs: {
                                                                                                  color:
                                                                                                    "secondary",
                                                                                                  small:
                                                                                                    ""
                                                                                                }
                                                                                              },
                                                                                              [
                                                                                                _vm._v(
                                                                                                  "\n                                fas fa-check-circle\n                              "
                                                                                                )
                                                                                              ]
                                                                                            )
                                                                                          ],
                                                                                          1
                                                                                        )
                                                                                      ],
                                                                                      1
                                                                                    )
                                                                                  ]
                                                                                }
                                                                              }
                                                                            ],
                                                                            null,
                                                                            true
                                                                          ),
                                                                          model: {
                                                                            value:
                                                                              _vm
                                                                                .status
                                                                                .existing
                                                                                .shiftFlags,
                                                                            callback: function(
                                                                              $$v
                                                                            ) {
                                                                              _vm.$set(
                                                                                _vm
                                                                                  .status
                                                                                  .existing,
                                                                                "shiftFlags",
                                                                                $$v
                                                                              )
                                                                            },
                                                                            expression:
                                                                              "status.existing.shiftFlags"
                                                                          }
                                                                        }
                                                                      ),
                                                                      _c(
                                                                        "span",
                                                                        {
                                                                          staticClass:
                                                                            "grey--text caption mt-2"
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            "\n                        " +
                                                                              _vm._s(
                                                                                _vm.$t(
                                                                                  "descriptions.userStatusShiftFlagsToApply"
                                                                                )
                                                                              ) +
                                                                              "\n                      "
                                                                          )
                                                                        ]
                                                                      )
                                                                    ],
                                                                    1
                                                                  )
                                                                ],
                                                                1
                                                              )
                                                            : _vm._e(),
                                                          _c(
                                                            "v-row",
                                                            {
                                                              staticClass:
                                                                "mt-4",
                                                              attrs: {
                                                                "no-gutters": ""
                                                              }
                                                            },
                                                            [
                                                              _c(
                                                                "v-col",
                                                                {
                                                                  attrs: {
                                                                    cols: "12"
                                                                  }
                                                                },
                                                                [
                                                                  _c(
                                                                    "v-switch",
                                                                    {
                                                                      staticClass:
                                                                        "pt-0 mt-0 ml-1 d-inline-block",
                                                                      attrs: {
                                                                        color:
                                                                          "success",
                                                                        dense:
                                                                          "",
                                                                        disabled: !_vm
                                                                          .status
                                                                          .existing
                                                                          .newStatus,
                                                                        "hide-details":
                                                                          "",
                                                                        inset:
                                                                          ""
                                                                      },
                                                                      scopedSlots: _vm._u(
                                                                        [
                                                                          {
                                                                            key:
                                                                              "label",
                                                                            fn: function() {
                                                                              return [
                                                                                _c(
                                                                                  "span",
                                                                                  {
                                                                                    staticClass:
                                                                                      "mr-4 body-2 grey--text text--darken-3",
                                                                                    attrs: {
                                                                                      title: _vm.$t(
                                                                                        "labels.effectiveImmediately"
                                                                                      )
                                                                                    }
                                                                                  },
                                                                                  [
                                                                                    _vm._v(
                                                                                      "\n                            " +
                                                                                        _vm._s(
                                                                                          _vm.$t(
                                                                                            "labels.effectiveImmediately"
                                                                                          )
                                                                                        ) +
                                                                                        "\n                          "
                                                                                    )
                                                                                  ]
                                                                                )
                                                                              ]
                                                                            },
                                                                            proxy: true
                                                                          }
                                                                        ],
                                                                        null,
                                                                        true
                                                                      ),
                                                                      model: {
                                                                        value:
                                                                          _vm
                                                                            .status
                                                                            .existing
                                                                            .effectiveImmediately,
                                                                        callback: function(
                                                                          $$v
                                                                        ) {
                                                                          _vm.$set(
                                                                            _vm
                                                                              .status
                                                                              .existing,
                                                                            "effectiveImmediately",
                                                                            $$v
                                                                          )
                                                                        },
                                                                        expression:
                                                                          "status.existing.effectiveImmediately"
                                                                      }
                                                                    }
                                                                  )
                                                                ],
                                                                1
                                                              )
                                                            ],
                                                            1
                                                          ),
                                                          _c(
                                                            "v-row",
                                                            {
                                                              staticClass:
                                                                "mt-4"
                                                            },
                                                            [
                                                              _c(
                                                                "v-col",
                                                                {
                                                                  attrs: {
                                                                    cols: "6"
                                                                  }
                                                                },
                                                                [
                                                                  _c(
                                                                    "v-menu",
                                                                    {
                                                                      ref:
                                                                        "effectiveFromPicker",
                                                                      attrs: {
                                                                        "close-on-content-click": false,
                                                                        transition:
                                                                          "scale-transition",
                                                                        "offset-y":
                                                                          "",
                                                                        "nudge-bottom": -25,
                                                                        "max-width":
                                                                          "290px",
                                                                        "min-width":
                                                                          "auto"
                                                                      },
                                                                      scopedSlots: _vm._u(
                                                                        [
                                                                          {
                                                                            key:
                                                                              "activator",
                                                                            fn: function(
                                                                              ref
                                                                            ) {
                                                                              var on =
                                                                                ref.on
                                                                              var attrs =
                                                                                ref.attrs
                                                                              return [
                                                                                _c(
                                                                                  "VeeTextField",
                                                                                  _vm._g(
                                                                                    _vm._b(
                                                                                      {
                                                                                        directives: [
                                                                                          {
                                                                                            name:
                                                                                              "mask",
                                                                                            rawName:
                                                                                              "v-mask",
                                                                                            value:
                                                                                              "##/##/####",
                                                                                            expression:
                                                                                              "'##/##/####'"
                                                                                          }
                                                                                        ],
                                                                                        attrs: {
                                                                                          autocomplete: false,
                                                                                          dense:
                                                                                            "",
                                                                                          disabled:
                                                                                            _vm
                                                                                              .status
                                                                                              .existing
                                                                                              .effectiveImmediately ||
                                                                                            !_vm
                                                                                              .status
                                                                                              .existing
                                                                                              .newStatus,
                                                                                          label: _vm.$t(
                                                                                            "labels.effectiveFrom"
                                                                                          ),
                                                                                          outlined:
                                                                                            "",
                                                                                          placeholder:
                                                                                            "MM/DD/YYYY",
                                                                                          rules: _vm
                                                                                            .status
                                                                                            .existing
                                                                                            .effectiveImmediately
                                                                                            ? {}
                                                                                            : {
                                                                                                required: true,
                                                                                                dateRegex_MMDDYYYY:
                                                                                                  _vm.dateRegex
                                                                                              }
                                                                                        },
                                                                                        on: {
                                                                                          blur: function(
                                                                                            $event
                                                                                          ) {
                                                                                            _vm.status.existing.effectiveFrom = _vm.parseDate(
                                                                                              _vm
                                                                                                .status
                                                                                                .existing
                                                                                                .effectiveFromFormatted
                                                                                            )
                                                                                          }
                                                                                        },
                                                                                        model: {
                                                                                          value:
                                                                                            _vm
                                                                                              .status
                                                                                              .existing
                                                                                              .effectiveFromFormatted,
                                                                                          callback: function(
                                                                                            $$v
                                                                                          ) {
                                                                                            _vm.$set(
                                                                                              _vm
                                                                                                .status
                                                                                                .existing,
                                                                                              "effectiveFromFormatted",
                                                                                              $$v
                                                                                            )
                                                                                          },
                                                                                          expression:
                                                                                            "status.existing.effectiveFromFormatted"
                                                                                        }
                                                                                      },
                                                                                      "VeeTextField",
                                                                                      attrs,
                                                                                      false
                                                                                    ),
                                                                                    on
                                                                                  )
                                                                                )
                                                                              ]
                                                                            }
                                                                          }
                                                                        ],
                                                                        null,
                                                                        true
                                                                      ),
                                                                      model: {
                                                                        value:
                                                                          _vm
                                                                            .status
                                                                            .existing
                                                                            .showEffectiveFromPicker,
                                                                        callback: function(
                                                                          $$v
                                                                        ) {
                                                                          _vm.$set(
                                                                            _vm
                                                                              .status
                                                                              .existing,
                                                                            "showEffectiveFromPicker",
                                                                            $$v
                                                                          )
                                                                        },
                                                                        expression:
                                                                          "status.existing.showEffectiveFromPicker"
                                                                      }
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "v-date-picker",
                                                                        {
                                                                          attrs: {
                                                                            min:
                                                                              _vm.tommorow,
                                                                            "no-title":
                                                                              ""
                                                                          },
                                                                          on: {
                                                                            input: function(
                                                                              $event
                                                                            ) {
                                                                              _vm.status.existing.showEffectiveFromPicker = false
                                                                            }
                                                                          },
                                                                          model: {
                                                                            value:
                                                                              _vm
                                                                                .status
                                                                                .existing
                                                                                .effectiveFrom,
                                                                            callback: function(
                                                                              $$v
                                                                            ) {
                                                                              _vm.$set(
                                                                                _vm
                                                                                  .status
                                                                                  .existing,
                                                                                "effectiveFrom",
                                                                                $$v
                                                                              )
                                                                            },
                                                                            expression:
                                                                              "status.existing.effectiveFrom"
                                                                          }
                                                                        }
                                                                      )
                                                                    ],
                                                                    1
                                                                  )
                                                                ],
                                                                1
                                                              ),
                                                              _c(
                                                                "v-col",
                                                                {
                                                                  attrs: {
                                                                    cols: "6"
                                                                  }
                                                                },
                                                                [
                                                                  _vm.status
                                                                    .existing
                                                                    .newStatus !==
                                                                  _vm
                                                                    .ACCOUNT_STATE
                                                                    .severed
                                                                    ? _c(
                                                                        "v-menu",
                                                                        {
                                                                          ref:
                                                                            "effectiveUntilPicker",
                                                                          attrs: {
                                                                            "close-on-content-click": false,
                                                                            transition:
                                                                              "scale-transition",
                                                                            "offset-y":
                                                                              "",
                                                                            "nudge-bottom": -25,
                                                                            "max-width":
                                                                              "290px",
                                                                            "min-width":
                                                                              "auto"
                                                                          },
                                                                          scopedSlots: _vm._u(
                                                                            [
                                                                              {
                                                                                key:
                                                                                  "activator",
                                                                                fn: function(
                                                                                  ref
                                                                                ) {
                                                                                  var on =
                                                                                    ref.on
                                                                                  var attrs =
                                                                                    ref.attrs
                                                                                  return [
                                                                                    _c(
                                                                                      "VeeTextField",
                                                                                      _vm._g(
                                                                                        _vm._b(
                                                                                          {
                                                                                            directives: [
                                                                                              {
                                                                                                name:
                                                                                                  "mask",
                                                                                                rawName:
                                                                                                  "v-mask",
                                                                                                value:
                                                                                                  "##/##/####",
                                                                                                expression:
                                                                                                  "'##/##/####'"
                                                                                              }
                                                                                            ],
                                                                                            attrs: {
                                                                                              autocomplete: false,
                                                                                              dense:
                                                                                                "",
                                                                                              disabled:
                                                                                                _vm
                                                                                                  .status
                                                                                                  .existing
                                                                                                  .effectiveImmediately ||
                                                                                                !_vm
                                                                                                  .status
                                                                                                  .existing
                                                                                                  .newStatus,
                                                                                              label:
                                                                                                _vm.$t(
                                                                                                  "labels.effectiveUntil"
                                                                                                ) +
                                                                                                " (" +
                                                                                                _vm.$t(
                                                                                                  "labels.optional"
                                                                                                ) +
                                                                                                ")",
                                                                                              outlined:
                                                                                                "",
                                                                                              placeholder:
                                                                                                "MM/DD/YYYY",
                                                                                              rules: _vm
                                                                                                .status
                                                                                                .existing
                                                                                                .effectiveImmediately
                                                                                                ? {}
                                                                                                : {
                                                                                                    dateRegex_MMDDYYYY:
                                                                                                      _vm.dateRegex
                                                                                                  }
                                                                                            },
                                                                                            on: {
                                                                                              blur: function(
                                                                                                $event
                                                                                              ) {
                                                                                                _vm.status.existing.effectiveUntil = _vm.parseDate(
                                                                                                  _vm
                                                                                                    .status
                                                                                                    .existing
                                                                                                    .effectiveUntilFormatted
                                                                                                )
                                                                                              }
                                                                                            },
                                                                                            model: {
                                                                                              value:
                                                                                                _vm
                                                                                                  .status
                                                                                                  .existing
                                                                                                  .effectiveUntilFormatted,
                                                                                              callback: function(
                                                                                                $$v
                                                                                              ) {
                                                                                                _vm.$set(
                                                                                                  _vm
                                                                                                    .status
                                                                                                    .existing,
                                                                                                  "effectiveUntilFormatted",
                                                                                                  $$v
                                                                                                )
                                                                                              },
                                                                                              expression:
                                                                                                "status.existing.effectiveUntilFormatted"
                                                                                            }
                                                                                          },
                                                                                          "VeeTextField",
                                                                                          attrs,
                                                                                          false
                                                                                        ),
                                                                                        on
                                                                                      )
                                                                                    )
                                                                                  ]
                                                                                }
                                                                              }
                                                                            ],
                                                                            null,
                                                                            true
                                                                          ),
                                                                          model: {
                                                                            value:
                                                                              _vm
                                                                                .status
                                                                                .existing
                                                                                .showEffectiveUntilPicker,
                                                                            callback: function(
                                                                              $$v
                                                                            ) {
                                                                              _vm.$set(
                                                                                _vm
                                                                                  .status
                                                                                  .existing,
                                                                                "showEffectiveUntilPicker",
                                                                                $$v
                                                                              )
                                                                            },
                                                                            expression:
                                                                              "status.existing.showEffectiveUntilPicker"
                                                                          }
                                                                        },
                                                                        [
                                                                          _c(
                                                                            "v-date-picker",
                                                                            {
                                                                              attrs: {
                                                                                min:
                                                                                  _vm.dayAfterTommorow,
                                                                                "no-title":
                                                                                  ""
                                                                              },
                                                                              on: {
                                                                                input: function(
                                                                                  $event
                                                                                ) {
                                                                                  _vm.status.existing.showEffectiveUntilPicker = false
                                                                                }
                                                                              },
                                                                              model: {
                                                                                value:
                                                                                  _vm
                                                                                    .status
                                                                                    .existing
                                                                                    .effectiveUntil,
                                                                                callback: function(
                                                                                  $$v
                                                                                ) {
                                                                                  _vm.$set(
                                                                                    _vm
                                                                                      .status
                                                                                      .existing,
                                                                                    "effectiveUntil",
                                                                                    $$v
                                                                                  )
                                                                                },
                                                                                expression:
                                                                                  "status.existing.effectiveUntil"
                                                                              }
                                                                            }
                                                                          )
                                                                        ],
                                                                        1
                                                                      )
                                                                    : _vm._e()
                                                                ],
                                                                1
                                                              )
                                                            ],
                                                            1
                                                          ),
                                                          _vm.newStatusMessage
                                                            ? _c(
                                                                "v-row",
                                                                [
                                                                  _c(
                                                                    "v-col",
                                                                    {
                                                                      staticClass:
                                                                        "pt-0 mb-5",
                                                                      attrs: {
                                                                        cols:
                                                                          "12"
                                                                      }
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "span",
                                                                        {
                                                                          staticClass:
                                                                            "caption grey--text"
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            "\n                        " +
                                                                              _vm._s(
                                                                                _vm.newStatusMessage
                                                                              ) +
                                                                              "\n                      "
                                                                          )
                                                                        ]
                                                                      )
                                                                    ]
                                                                  )
                                                                ],
                                                                1
                                                              )
                                                            : _vm._e(),
                                                          _vm.status.existing
                                                            .newStatus ===
                                                            _vm.ACCOUNT_STATE
                                                              .severed &&
                                                          (_vm.status.existing
                                                            .effectiveImmediately ||
                                                            _vm.newStatusMessage)
                                                            ? _c(
                                                                "v-row",
                                                                {
                                                                  staticClass:
                                                                    "mt-0",
                                                                  attrs: {
                                                                    "no-gutters":
                                                                      ""
                                                                  }
                                                                },
                                                                [
                                                                  _c(
                                                                    "v-col",
                                                                    {
                                                                      attrs: {
                                                                        cols:
                                                                          "12"
                                                                      }
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "p",
                                                                        {
                                                                          staticClass:
                                                                            "body-2 mb-1"
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            "\n                        " +
                                                                              _vm._s(
                                                                                _vm.$t(
                                                                                  "labels.optional"
                                                                                )
                                                                              ) +
                                                                              "\n                      "
                                                                          )
                                                                        ]
                                                                      ),
                                                                      _c(
                                                                        "v-checkbox",
                                                                        {
                                                                          staticClass:
                                                                            "pt-0 mt-0 ml-n1 mb-1 d-inline-block extra-dense-checkbox",
                                                                          attrs: {
                                                                            color:
                                                                              "success",
                                                                            dense:
                                                                              "",
                                                                            "hide-details":
                                                                              "",
                                                                            inset:
                                                                              "",
                                                                            label: _vm
                                                                              .status
                                                                              .existing
                                                                              .effectiveImmediately
                                                                              ? _vm.$t(
                                                                                  "labels.severedDeleteShiftsImmediately"
                                                                                )
                                                                              : _vm.$t(
                                                                                  "labels.severedDeleteShiftsOn",
                                                                                  {
                                                                                    date:
                                                                                      _vm
                                                                                        .status
                                                                                        .existing
                                                                                        .effectiveFromFormatted
                                                                                  }
                                                                                )
                                                                          },
                                                                          model: {
                                                                            value:
                                                                              _vm
                                                                                .status
                                                                                .existing
                                                                                .severedDeleteShifts,
                                                                            callback: function(
                                                                              $$v
                                                                            ) {
                                                                              _vm.$set(
                                                                                _vm
                                                                                  .status
                                                                                  .existing,
                                                                                "severedDeleteShifts",
                                                                                $$v
                                                                              )
                                                                            },
                                                                            expression:
                                                                              "status.existing.severedDeleteShifts"
                                                                          }
                                                                        }
                                                                      ),
                                                                      _c("p", {
                                                                        staticClass:
                                                                          "body-2 mb-1",
                                                                        domProps: {
                                                                          innerHTML: _vm._s(
                                                                            _vm.$t(
                                                                              "labels.severedDeleteShiftsConfirmation"
                                                                            )
                                                                          )
                                                                        }
                                                                      }),
                                                                      _c(
                                                                        "VeeTextField",
                                                                        {
                                                                          attrs: {
                                                                            autocomplete: false,
                                                                            dense:
                                                                              "",
                                                                            disabled: !_vm
                                                                              .status
                                                                              .existing
                                                                              .severedDeleteShifts,
                                                                            outlined:
                                                                              "",
                                                                            placeholder: _vm.$t(
                                                                              "labels.deleteShifts"
                                                                            ),
                                                                            rules: _vm
                                                                              .status
                                                                              .existing
                                                                              .severedDeleteShifts
                                                                              ? {
                                                                                  required: true,
                                                                                  is:
                                                                                    "delete shifts"
                                                                                }
                                                                              : {}
                                                                          },
                                                                          model: {
                                                                            value:
                                                                              _vm
                                                                                .status
                                                                                .existing
                                                                                .severedDeleteShiftsConfirmation,
                                                                            callback: function(
                                                                              $$v
                                                                            ) {
                                                                              _vm.$set(
                                                                                _vm
                                                                                  .status
                                                                                  .existing,
                                                                                "severedDeleteShiftsConfirmation",
                                                                                $$v
                                                                              )
                                                                            },
                                                                            expression:
                                                                              "status.existing.severedDeleteShiftsConfirmation"
                                                                          }
                                                                        }
                                                                      )
                                                                    ],
                                                                    1
                                                                  )
                                                                ],
                                                                1
                                                              )
                                                            : _vm._e()
                                                        ],
                                                        1
                                                      )
                                                    ]
                                              ],
                                              2
                                            )
                                          ]
                                        }
                                      }
                                    ],
                                    null,
                                    true
                                  )
                                })
                              ],
                              1
                            ),
                            _c(
                              "v-tab-item",
                              { attrs: { eager: "", value: "accessControl" } },
                              [
                                _c(
                                  "v-container",
                                  {
                                    staticClass: "pa-0 pt-4",
                                    style: _vm.contentStyle
                                  },
                                  [
                                    _c(
                                      "portal",
                                      {
                                        attrs: { to: "userDialogAccessControl" }
                                      },
                                      [
                                        _c("span", [
                                          _vm._v(
                                            "\n                " +
                                              _vm._s(
                                                _vm.$t("labels.accessControl")
                                              ) +
                                              "\n              "
                                          )
                                        ])
                                      ]
                                    ),
                                    _vm.internalUser.jobTypeId
                                      ? _c("v-data-table", {
                                          attrs: {
                                            dense: "",
                                            "fixed-header": "",
                                            headers: _vm.accessControlHeaders,
                                            "hide-default-footer": "",
                                            items: _vm.permissions.screens.web,
                                            "items-per-page":
                                              _vm.permissions.screens.web.length
                                          },
                                          scopedSlots: _vm._u(
                                            [
                                              {
                                                key: "header.default",
                                                fn: function(ref) {
                                                  var header = ref.header
                                                  return [
                                                    _c(
                                                      "span",
                                                      {
                                                        staticClass:
                                                          "grey--text text--darken-3 font-weight-regular"
                                                      },
                                                      [
                                                        _vm._v(
                                                          "\n                  " +
                                                            _vm._s(
                                                              header.text
                                                            ) +
                                                            "\n                "
                                                        )
                                                      ]
                                                    )
                                                  ]
                                                }
                                              },
                                              {
                                                key: "header.viewOnly",
                                                fn: function(ref) {
                                                  var header = ref.header
                                                  return [
                                                    _c(
                                                      "span",
                                                      {
                                                        staticClass:
                                                          "grey--text text--darken-3 font-weight-regular"
                                                      },
                                                      [
                                                        _vm._v(
                                                          "\n                  " +
                                                            _vm._s(
                                                              header.text
                                                            ) +
                                                            "\n                "
                                                        )
                                                      ]
                                                    )
                                                  ]
                                                }
                                              },
                                              {
                                                key: "header.noAccess",
                                                fn: function(ref) {
                                                  var header = ref.header
                                                  return [
                                                    _c(
                                                      "span",
                                                      {
                                                        staticClass:
                                                          "grey--text text--darken-3 font-weight-regular"
                                                      },
                                                      [
                                                        _vm._v(
                                                          "\n                  " +
                                                            _vm._s(
                                                              header.text
                                                            ) +
                                                            "\n                "
                                                        )
                                                      ]
                                                    )
                                                  ]
                                                }
                                              },
                                              {
                                                key: "item",
                                                fn: function(ref) {
                                                  var item = ref.item
                                                  var tableHeaders = ref.headers
                                                  return [
                                                    _c(
                                                      "tr",
                                                      _vm._l(
                                                        tableHeaders,
                                                        function(header) {
                                                          return _c(
                                                            "td",
                                                            {
                                                              key: header.value,
                                                              staticClass:
                                                                "text-start"
                                                            },
                                                            [
                                                              header.value ===
                                                              "label"
                                                                ? _c(
                                                                    "span",
                                                                    {
                                                                      staticClass:
                                                                        "grey--text text--darken-3 text-truncate d-block",
                                                                      style: {
                                                                        width:
                                                                          header.width +
                                                                          "px"
                                                                      },
                                                                      attrs: {
                                                                        title:
                                                                          item.label
                                                                      }
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        "\n                      " +
                                                                          _vm._s(
                                                                            item.label
                                                                          ) +
                                                                          "\n                    "
                                                                      )
                                                                    ]
                                                                  )
                                                                : _c(
                                                                    "v-radio-group",
                                                                    {
                                                                      staticClass:
                                                                        "mt-0 pt-0",
                                                                      attrs: {
                                                                        "hide-details":
                                                                          ""
                                                                      },
                                                                      model: {
                                                                        value:
                                                                          item.value,
                                                                        callback: function(
                                                                          $$v
                                                                        ) {
                                                                          _vm.$set(
                                                                            item,
                                                                            "value",
                                                                            $$v
                                                                          )
                                                                        },
                                                                        expression:
                                                                          "item.value"
                                                                      }
                                                                    },
                                                                    [
                                                                      header.value ===
                                                                      "default"
                                                                        ? _c(
                                                                            "v-radio",
                                                                            {
                                                                              staticClass:
                                                                                "extra-dense-radio",
                                                                              attrs: {
                                                                                color:
                                                                                  "secondary",
                                                                                disabled: !item.editable.includes(
                                                                                  "default"
                                                                                ),
                                                                                label:
                                                                                  "",
                                                                                value:
                                                                                  "default"
                                                                              }
                                                                            }
                                                                          )
                                                                        : _vm._e(),
                                                                      header.value ===
                                                                      "viewOnly"
                                                                        ? _c(
                                                                            "v-radio",
                                                                            {
                                                                              staticClass:
                                                                                "extra-dense-radio",
                                                                              attrs: {
                                                                                color:
                                                                                  "secondary",
                                                                                disabled: !item.editable.includes(
                                                                                  "view-only"
                                                                                ),
                                                                                label:
                                                                                  "",
                                                                                value:
                                                                                  "view-only"
                                                                              }
                                                                            }
                                                                          )
                                                                        : _vm._e(),
                                                                      header.value ===
                                                                      "noAccess"
                                                                        ? _c(
                                                                            "v-radio",
                                                                            {
                                                                              staticClass:
                                                                                "extra-dense-radio",
                                                                              attrs: {
                                                                                color:
                                                                                  "secondary",
                                                                                disabled: !item.editable.includes(
                                                                                  "no-access"
                                                                                ),
                                                                                label:
                                                                                  "",
                                                                                value:
                                                                                  "no-access"
                                                                              }
                                                                            }
                                                                          )
                                                                        : _vm._e()
                                                                    ],
                                                                    1
                                                                  )
                                                            ],
                                                            1
                                                          )
                                                        }
                                                      ),
                                                      0
                                                    )
                                                  ]
                                                }
                                              }
                                            ],
                                            null,
                                            true
                                          )
                                        })
                                      : _c(
                                          "v-container",
                                          [
                                            _c("NoContent", {
                                              attrs: {
                                                exciting: "",
                                                height: 140,
                                                width: 140,
                                                message: _vm.$t(
                                                  "descriptions.acMissingJobTypeId"
                                                )
                                              }
                                            })
                                          ],
                                          1
                                        )
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ],
                      1
                    ),
                    _c("v-divider"),
                    _c(
                      "v-card-actions",
                      { staticClass: "px-3" },
                      [
                        _vm.isPending && _vm.$can("edit", "user")
                          ? _c(
                              "v-btn",
                              {
                                staticClass: "px-4",
                                attrs: { color: "error", text: "" },
                                on: { click: _vm.reject }
                              },
                              [
                                _vm._v(
                                  "\n        " +
                                    _vm._s(_vm.$t("labels.rejectUser")) +
                                    "\n      "
                                )
                              ]
                            )
                          : _vm._e(),
                        _c("v-spacer"),
                        _c(
                          "v-btn",
                          {
                            staticClass: "mr-3",
                            attrs: { disabled: _vm.saving, text: "" },
                            on: {
                              click: function($event) {
                                return _vm.$emit("close")
                              }
                            }
                          },
                          [
                            _vm._v(
                              "\n        " +
                                _vm._s(_vm.$t("labels.close")) +
                                "\n      "
                            )
                          ]
                        ),
                        _vm.$can("edit", "user")
                          ? _c(
                              "v-btn",
                              {
                                staticClass: "ml-3 verify",
                                attrs: {
                                  color: "secondary",
                                  disabled: invalid || _vm.saving
                                },
                                on: {
                                  click: function($event) {
                                    return passes(_vm.save)
                                  }
                                }
                              },
                              [
                                _vm.saving
                                  ? _c("v-progress-circular", {
                                      attrs: {
                                        color: "secondary",
                                        indeterminate: "",
                                        size: "22",
                                        width: "2"
                                      }
                                    })
                                  : _c("span", [
                                      _vm._v(
                                        "\n          " +
                                          _vm._s(_vm.labels[_vm.mode].save) +
                                          "\n        "
                                      )
                                    ])
                              ],
                              1
                            )
                          : _vm._e()
                      ],
                      1
                    )
                  ]
                }
              }
            ])
          })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }