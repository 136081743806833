<template>
  <v-card
    id="userProfile"
    flat
  >
    <v-row
      v-if="loading"
      align="center"
      :style="contentStyle"
    >
      <v-spacer />
      <v-col cols="6">
        <v-row class="text-center">
          <v-col class="text-center">
            <v-progress-circular
              color="info"
              indeterminate
              size="75"
              width="6"
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col class="text-center">
            <span>{{ $t('descriptions.loading') }}</span>
          </v-col>
        </v-row>
      </v-col>
      <v-spacer />
    </v-row>
    <ValidationObserver
      v-else
      v-slot="{ invalid, passes }"
    >
      <v-card-title
        v-if="showTitle"
        class="grey--text text--darken-3 subtitle-2 d-block pb-0"
      >
        {{ labels[mode].title }}
        <v-btn
          class="float-right"
          :disabled="saving"
          icon
          small
          @click="$emit('close')"
        >
          <v-icon>fal fa-times</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text class="py-0">
        <v-alert
          v-if="showHint && labels[mode] && labels[mode].info"
          class="caption dense font-weight-medium mx-0 mb-1 mt-3"
          color="info"
          dense
          outlined
          text
        >
          <v-icon
            slot="prepend"
            class="ml-n1 mr-3"
            color="info"
            size="12"
          >
            fas fa-info-circle
          </v-icon>
          {{ labels[mode].info }}
        </v-alert>
        <v-tabs
          v-model="selectedTab"
          centered
          class="dense mt-0"
          color="secondary"
          grow
        >
          <v-tab
            href="#user"
          >
            <portal-target name="userDialogUserInfo" />
          </v-tab>
          <v-tab
            href="#profile"
          >
            <portal-target name="userDialogJobInfo" />
          </v-tab>
          <v-tab
            href="#status"
          >
            <portal-target name="userDialogStatus" />
          </v-tab>
          <v-tab
            v-if="user.classification !== 'admin'"
            href="#accessControl"
          >
            <portal-target name="userDialogAccessControl" />
          </v-tab>
          <v-tab-item
            eager
            value="user"
          >
            <ValidationObserver v-slot="{ invalid: userInfoInvalid }">
              <v-container
                class="pa-0 pt-4"
                :style="contentStyle"
              >
                <portal to="userDialogUserInfo">
                  <span>
                    {{ $t('labels.userInfo') }}
                    <v-icon
                      color="error"
                      x-small
                      :style="{ visibility: userInfoInvalid ? 'visible' : 'hidden' }"
                    >
                      fas fa-exclamation-circle
                    </v-icon>
                  </span>
                </portal>
                <v-row>
                  <v-col cols="6">
                    <VeeTextField
                      v-model="internalUser.firstName"
                      dense
                      :disabled="disableUserInfo"
                      :label="$t('labels.firstName')"
                      name="firstName"
                      outlined
                      :rules="disableUserInfo ? '' : 'required|max:30'"
                    />
                  </v-col>
                  <v-col cols="6">
                    <VeeTextField
                      v-model="internalUser.lastName"
                      dense
                      :disabled="disableUserInfo"
                      :label="$t('labels.lastName')"
                      name="lastName"
                      outlined
                      :rules="disableUserInfo ? '' : 'required|max:150'"
                    />
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="6">
                    <VeeTextField
                      v-model="internalUser.alias"
                      dense
                      :disabled="disableUserInfo"
                      :label="`${$t('labels.alias')} (${$t('labels.optional')})`"
                      name="alias"
                      outlined
                      :rules="disableUserInfo ? '' : 'max:30'"
                    />
                  </v-col>
                  <v-col cols="6">
                    <v-select
                      v-model="internalUser.gender"
                      clearable
                      dense
                      :disabled="disableUserInfo"
                      hide-details
                      :items="[{ text: $t('labels.female'), value: 'female' }, { text: $t('labels.male'), value: 'male' }]"
                      :label="`${$t('labels.gender')} (${$t('labels.optional')})`"
                      outlined
                    />
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="6">
                    <VeeTextField
                      v-model="internalUser.phonePersonal"
                      v-mask="phoneMask"
                      class="phone-number"
                      dense
                      :disabled="disableUserInfo"
                      :label="`${$t('labels.phone')}`"
                      name="phone"
                      outlined
                      prefix="+1"
                      :rules="disableUserInfo ? '' : { phoneRegex_US: phoneRegex }"
                    />
                  </v-col>
                  <v-col cols="6">
                    <VeeTextField
                      v-model.trim="internalUser.email"
                      dense
                      :disabled="disableUserInfo"
                      hide-details
                      :label="$t('labels.email')"
                      name="email"
                      outlined
                      :rules="disableUserInfo ? '' : 'email|required|max:255'"
                    />
                  </v-col>
                </v-row>
              </v-container>
            </ValidationObserver>
          </v-tab-item>
          <v-tab-item
            eager
            value="profile"
          >
            <ValidationObserver v-slot="{ invalid: jobInfoInvalid }">
              <v-container
                class="pa-0 pt-4"
                :style="contentStyle"
              >
                <portal to="userDialogJobInfo">
                  <span>
                    {{ $t('labels.jobInfo') }}
                    <v-icon
                      color="error"
                      x-small
                      :style="{ visibility: jobInfoInvalid ? 'visible' : 'hidden' }"
                    >
                      fas fa-exclamation-circle
                    </v-icon>
                  </span>
                </portal>
                <v-row>
                  <v-col cols="6">
                    <VeeTextField
                      v-model="internalUser.employeeId"
                      dense
                      :label="$t('labels.employeeID')"
                      name="employeeId"
                      outlined
                      rules="required|max:255"
                    />
                  </v-col>
                  <v-col cols="6">
                    <VeeSelect
                      v-model="internalUser.departmentId"
                      dense
                      item-text="name"
                      item-value="id"
                      :items="filteredDepartments"
                      :label="$t('labels.primaryDepartment')"
                      name="primaryDepartment"
                      outlined
                      rules="required"
                    />
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="6">
                    <VeeSelect
                      v-model="internalUser.jobTypeId"
                      dense
                      item-text="name"
                      item-value="id"
                      :items="filteredJobTypes"
                      :label="$tc('labels.jobType', 1)"
                      name="jobType"
                      outlined
                      rules="required"
                    />
                  </v-col>
                  <v-col cols="6">
                    <VeeTextField
                      v-model="internalUser.jobTitle"
                      dense
                      :label="`${$t('labels.jobTitle')} (${$t('labels.optional')})`"
                      name="jobTitle"
                      outlined
                      rules="max:50"
                    />
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="6">
                    <VeeSelect
                      v-model="internalUser.shiftTypeId"
                      dense
                      item-text="name"
                      item-value="id"
                      :items="shiftTypes"
                      :label="$t('labels.primaryShift')"
                      name="primaryShift"
                      outlined
                      rules="required"
                    />
                  </v-col>
                  <v-col cols="6">
                    <v-menu
                      ref="hiredOnPicker"
                      v-model="showHiredOnPicker"
                      :close-on-content-click="false"
                      transition="scale-transition"
                      offset-y
                      :nudge-bottom="-25"
                      max-width="290px"
                      min-width="auto"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <VeeTextField
                          v-model="hiredOn"
                          v-mask="'##/##/####'"
                          :autocomplete="false"
                          dense
                          :label="`${$t('labels.hireDate')} (${$t('labels.optional')})`"
                          outlined
                          placeholder="MM/DD/YYYY"
                          :rules="{ dateRegex_MMDDYYYY: dateRegex }"
                          v-bind="attrs"
                          @blur="internalUser.jobInfo.hiredOn = parseDate(hiredOn)"
                          v-on="on"
                        />
                      </template>
                      <v-date-picker
                        v-model="internalUser.jobInfo.hiredOn"
                        no-title
                        @input="showHiredOnPicker = false"
                      />
                    </v-menu>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12">
                    <VeeSelect
                      v-model="internalUser.jobStatusId"
                      dense
                      item-text="name"
                      item-value="id"
                      :items="jobStatus"
                      :label="$t('labels.jobStatus')"
                      name="jobStatus"
                      outlined
                      rules="required"
                    />
                  </v-col>
                </v-row>
                <v-row>
                  <v-col
                    class="pr-0"
                    cols="5"
                  >
                    <VeeTextField
                      v-model.number="internalUser.dailyHours"
                      dense
                      :label="$t('labels.dailyHours')"
                      name="dailHours"
                      outlined
                      rules="required|numeric|max_value:24|min_value:0"
                    />
                  </v-col>
                  <v-col cols="1">
                    <v-tooltip
                      max-width="300px"
                      top
                    >
                      <template #activator="{ on, attrs }">
                        <v-icon
                          class="pt-3"
                          color="info"
                          small
                          v-bind="attrs"
                          v-on="on"
                        >
                          fal fa-question-circle
                        </v-icon>
                      </template>
                      <span class="body-2">
                        {{ $t('descriptions.overtimeDayHint') }}
                        <br>
                        {{ $t('descriptions.overtimeDaySkipHint') }}
                      </span>
                    </v-tooltip>
                  </v-col>
                  <v-col
                    class="pr-0"
                    cols="5"
                  >
                    <VeeTextField
                      v-model.number="internalUser.weeklyHours"
                      dense
                      :label="$t('labels.weeklyHours')"
                      name="weeklyHours"
                      outlined
                      rules="required|numeric|max_value:168|min_value:0"
                    />
                  </v-col>
                  <v-col cols="1">
                    <v-tooltip
                      max-width="300px"
                      top
                    >
                      <template #activator="{ on, attrs }">
                        <v-icon
                          class="pt-3"
                          color="info"
                          small
                          v-bind="attrs"
                          v-on="on"
                        >
                          fal fa-question-circle
                        </v-icon>
                      </template>
                      <span class="body-2">
                        {{ $t('descriptions.overtimeWeekHint') }}
                        <br>
                        {{ $t('descriptions.overtimeWeekSkipHint') }}
                      </span>
                    </v-tooltip>
                  </v-col>
                </v-row>
                <v-row v-if="canBeChargeNurse">
                  <v-col cols="12">
                    <v-switch
                      v-model="internalUser.charge"
                      class="pt-0 mt-0 ml-1 d-inline-block charge-toggle"
                      color="success"
                      dense
                      hide-details
                      inset
                    >
                      <template v-slot:label>
                        <span
                          class="mr-4 body-2 grey--text text--darken-3"
                          :title="$t('labels.userCanActAsCharge')"
                        >
                          {{ $t('labels.userCanActAsCharge') }}
                        </span>
                      </template>
                    </v-switch>
                  </v-col>
                </v-row>
              </v-container>
            </ValidationObserver>
          </v-tab-item>
          <v-tab-item
            eager
            value="status"
          >
            <ValidationObserver v-slot="{ invalid: statusInvalid }">
              <v-container
                class="pa-0 pt-4"
                :style="contentStyle"
              >
                <portal to="userDialogStatus">
                  <span>
                    {{ $t('labels.status') }}
                    <v-icon
                      color="error"
                      x-small
                      :style="{ visibility: statusInvalid ? 'visible' : 'hidden' }"
                    >
                      fas fa-exclamation-circle
                    </v-icon>
                  </span>
                </portal>
                <template v-if="mode === modeNewUser || mode === modeNewProfile || mode === modePendingProfile">
                  <v-row
                    dense
                    no-gutters
                  >
                    <v-col cols="12">
                      <span class="caption grey--text text--darken-3">
                        {{ $t('labels.activateUser') }}
                      </span>
                    </v-col>
                  </v-row>
                  <v-row
                    class="new-profile"
                    dense
                    no-gutters
                  >
                    <v-col cols="12">
                      <v-radio-group
                        v-model="status.new.activate"
                        class="mt-2"
                        column
                        dense
                      >
                        <v-radio
                          :label="$t('labels.immediately')"
                          value="now"
                        />
                        <v-radio
                          class="effective-from"
                          :label="$t('labels.specificDate')"
                          value="later"
                        >
                          <template v-slot:label>
                            <div>
                              <span>
                                {{ $t('labels.specificDate') }}
                              </span>
                              <v-menu
                                ref="effectiveFromPicker"
                                v-model="status.new.showEffectiveFromPicker"
                                :close-on-content-click="false"
                                transition="scale-transition"
                                offset-y
                                :nudge-bottom="-25"
                                max-width="290px"
                                min-width="auto"
                              >
                                <template v-slot:activator="{ on, attrs }">
                                  <VeeTextField
                                    v-model="status.new.effectiveFromFormatted"
                                    v-mask="'##/##/####'"
                                    :autocomplete="false"
                                    class="d-inline-block ml-2"
                                    dense
                                    :disabled="status.new.activate !== 'later'"
                                    :label="$tc('labels.date', 1)"
                                    outlined
                                    placeholder="MM/DD/YYYY"
                                    :rules="{ dateRegex_MMDDYYYY: dateRegex, required: status.new.activate === 'later' }"
                                    v-bind="attrs"
                                    @blur="status.new.effectiveFrom = parseDate(status.new.effectiveFromFormatted)"
                                    v-on="on"
                                  />
                                </template>
                                <v-date-picker
                                  v-model="status.new.effectiveFrom"
                                  no-title
                                  @input="status.new.showEffectiveFromPicker = false"
                                />
                              </v-menu>
                            </div>
                          </template>
                        </v-radio>
                      </v-radio-group>
                    </v-col>
                  </v-row>
                </template>
                <template v-else>
                  <fieldset class="nb-fieldset">
                    <legend>{{ $t('labels.currentStatus') }}</legend>
                    <div class="pb-2">
                      {{ $t(`labels.${user.state}`) }}
                    </div>
                  </fieldset>
                  <v-row
                    v-if="[ACCOUNT_STATE.inactive, ACCOUNT_STATE.severed].includes(user.state) && status.existing.appliedShiftFlags.length > 0"
                    class="mt-4"
                    no-gutters
                  >
                    <v-col cols="12">
                      <v-combobox
                        v-model="status.existing.appliedShiftFlags"
                        small-chips
                        class="shift-flags mb-2"
                        dense
                        disabled
                        :filter="flagsFilter"
                        hide-details
                        item-text="shortCode"
                        item-value="id"
                        :items="shiftFlags"
                        :label="$tc('labels.flag', 2)"
                        multiple
                        outlined
                        :return-object="false"
                      >
                        <template #selection="{ item: flagId }">
                          <v-chip
                            v-if="shiftFlagsById[flagId]"
                            class="lighten-2 grey--text text--darken-3 flag-short-code mb-1"
                            color="info"
                            small
                            :title="shiftFlagsById[flagId].name"
                          >
                            {{ shiftFlagsById[flagId].shortCode }}
                          </v-chip>
                        </template>
                        <template v-slot:item="{ attrs, item: flag, on }">
                          <v-list-item
                            :class="[status.existing.appliedShiftFlags.includes(flag.id) ? 'v-list-item--active' : '', 'flag-item']"
                            :title="flag.name"
                            v-bind="attrs"
                            v-on="on"
                          >
                            <v-list-item-icon
                              class="mr-0"
                              style="width: 60px"
                            >
                              <v-chip
                                class="lighten-2 grey--text text--darken-3 flag-short-code"
                                color="info"
                                small
                              >
                                {{ flag.shortCode }}
                              </v-chip>
                            </v-list-item-icon>
                            <v-list-item-content>
                              <span
                                class="grey--text text--darken-3 body-2 d-inline-block text-truncate"
                                style="width: 100px;"
                              >
                                {{ flag.name }}
                              </span>
                            </v-list-item-content>
                            <v-list-item-action>
                              <v-icon
                                color="secondary"
                                small
                                :style="{ visibility: status.existing.appliedShiftFlags.includes(flag.id) ? 'visible' : 'hidden' }"
                              >
                                fas fa-check-circle
                              </v-icon>
                            </v-list-item-action>
                          </v-list-item>
                        </template>
                      </v-combobox>
                      <span class="grey--text caption mt-2">
                        {{ $t('descriptions.userStatusShiftFlags') }}
                      </span>
                    </v-col>
                  </v-row>
                  <v-card
                    color="grey lighten-4 pa-4 mt-6"
                    :elevation="0"
                  >
                    <v-row
                      no-gutters
                    >
                      <v-col cols="12">
                        <span>
                          {{ $t('labels.changeStatus') }}
                        </span>
                      </v-col>
                    </v-row>
                    <v-row
                      class="mt-4"
                      no-gutters
                    >
                      <v-col cols="12">
                        <v-select
                          v-model="status.existing.newStatus"
                          clearable
                          dense
                          hide-details
                          item-text="name"
                          item-value="id"
                          :items="statusList"
                          :label="$t('labels.newStatus')"
                          name="newState"
                          outlined
                        />
                      </v-col>
                    </v-row>
                    <v-row
                      v-if="[ACCOUNT_STATE.inactive, ACCOUNT_STATE.severed].includes(status.existing.newStatus)"
                      class="mt-4"
                      no-gutters
                    >
                      <v-col cols="12">
                        <v-combobox
                          ref="selectShiftFlag"
                          v-model="status.existing.shiftFlags"
                          small-chips
                          class="shift-flags mb-2"
                          dense
                          :filter="flagsFilter"
                          hide-details
                          item-text="shortCode"
                          item-value="id"
                          :items="shiftFlags"
                          :label="$tc('labels.flag', 2)"
                          multiple
                          outlined
                          :return-object="false"
                        >
                          <template #selection="{ item: flagId }">
                            <v-chip
                              v-if="shiftFlagsById[flagId]"
                              class="lighten-2 grey--text text--darken-3 flag-short-code mb-1"
                              color="info"
                              small
                              :title="shiftFlagsById[flagId].name"
                            >
                              {{ shiftFlagsById[flagId].shortCode }}
                            </v-chip>
                          </template>
                          <template v-slot:item="{ attrs, item: flag, on }">
                            <v-list-item
                              :class="[status.existing.shiftFlags.includes(flag.id) ? 'v-list-item--active' : '', 'flag-item']"
                              :title="flag.name"
                              v-bind="attrs"
                              v-on="on"
                            >
                              <v-list-item-icon
                                class="mr-0"
                                style="width: 60px"
                              >
                                <v-chip
                                  class="lighten-2 grey--text text--darken-3 flag-short-code"
                                  color="info"
                                  small
                                >
                                  {{ flag.shortCode }}
                                </v-chip>
                              </v-list-item-icon>
                              <v-list-item-content>
                                <span
                                  class="grey--text text--darken-3 body-2 d-inline-block text-truncate"
                                  style="width: 100px;"
                                >
                                  {{ flag.name }}
                                </span>
                              </v-list-item-content>
                              <v-list-item-action>
                                <v-icon
                                  color="secondary"
                                  small
                                  :style="{ visibility: status.existing.shiftFlags.includes(flag.id) ? 'visible' : 'hidden' }"
                                >
                                  fas fa-check-circle
                                </v-icon>
                              </v-list-item-action>
                            </v-list-item>
                          </template>
                        </v-combobox>
                        <span class="grey--text caption mt-2">
                          {{ $t('descriptions.userStatusShiftFlagsToApply') }}
                        </span>
                      </v-col>
                    </v-row>
                    <v-row
                      class="mt-4"
                      no-gutters
                    >
                      <v-col cols="12">
                        <v-switch
                          v-model="status.existing.effectiveImmediately"
                          class="pt-0 mt-0 ml-1 d-inline-block"
                          color="success"
                          dense
                          :disabled="!status.existing.newStatus"
                          hide-details
                          inset
                        >
                          <template v-slot:label>
                            <span
                              class="mr-4 body-2 grey--text text--darken-3"
                              :title="$t('labels.effectiveImmediately')"
                            >
                              {{ $t('labels.effectiveImmediately') }}
                            </span>
                          </template>
                        </v-switch>
                      </v-col>
                    </v-row>
                    <v-row
                      class="mt-4"
                    >
                      <v-col cols="6">
                        <v-menu
                          ref="effectiveFromPicker"
                          v-model="status.existing.showEffectiveFromPicker"
                          :close-on-content-click="false"
                          transition="scale-transition"
                          offset-y
                          :nudge-bottom="-25"
                          max-width="290px"
                          min-width="auto"
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <VeeTextField
                              v-model="status.existing.effectiveFromFormatted"
                              v-mask="'##/##/####'"
                              :autocomplete="false"
                              dense
                              :disabled="status.existing.effectiveImmediately || !status.existing.newStatus"
                              :label="$t('labels.effectiveFrom')"
                              outlined
                              placeholder="MM/DD/YYYY"
                              :rules="status.existing.effectiveImmediately ? {} : { required: true, dateRegex_MMDDYYYY: dateRegex }"
                              v-bind="attrs"
                              @blur="status.existing.effectiveFrom = parseDate(status.existing.effectiveFromFormatted)"
                              v-on="on"
                            />
                          </template>
                          <v-date-picker
                            v-model="status.existing.effectiveFrom"
                            :min="tommorow"
                            no-title
                            @input="status.existing.showEffectiveFromPicker = false"
                          />
                        </v-menu>
                      </v-col>
                      <v-col cols="6">
                        <v-menu
                          v-if="status.existing.newStatus === ACCOUNT_STATE.inactive"
                          ref="effectiveUntilPicker"
                          v-model="status.existing.showEffectiveUntilPicker"
                          :close-on-content-click="false"
                          transition="scale-transition"
                          offset-y
                          :nudge-bottom="-25"
                          max-width="290px"
                          min-width="auto"
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <VeeTextField
                              v-model="status.existing.effectiveUntilFormatted"
                              v-mask="'##/##/####'"
                              :autocomplete="false"
                              dense
                              :disabled="status.existing.effectiveImmediately || !status.existing.newStatus"
                              :label="`${$t('labels.effectiveUntil')} (${$t('labels.optional')})`"
                              outlined
                              placeholder="MM/DD/YYYY"
                              :rules="status.existing.effectiveImmediately ? {} : { dateRegex_MMDDYYYY: dateRegex }"
                              v-bind="attrs"
                              @blur="status.existing.effectiveUntil = parseDate(status.existing.effectiveUntilFormatted)"
                              v-on="on"
                            />
                          </template>
                          <v-date-picker
                            v-model="status.existing.effectiveUntil"
                            :min="dayAfterTommorow"
                            no-title
                            @input="status.existing.showEffectiveUntilPicker = false"
                          />
                        </v-menu>
                      </v-col>
                    </v-row>
                    <v-row
                      v-if="newStatusMessage"
                    >
                      <v-col
                        class="pt-0 mb-5"
                        cols="12"
                      >
                        <span class="caption grey--text">
                          {{ newStatusMessage }}
                        </span>
                      </v-col>
                    </v-row>
                    <v-row
                      v-if="status.existing.newStatus === ACCOUNT_STATE.severed && (status.existing.effectiveImmediately || newStatusMessage)"
                      class="mt-0"
                      no-gutters
                    >
                      <v-col cols="12">
                        <p class="body-2 mb-1">
                          {{ $t('labels.optional') }}
                        </p>
                        <v-checkbox
                          v-model="status.existing.severedDeleteShifts"
                          class="pt-0 mt-0 ml-n1 mb-1 d-inline-block extra-dense-checkbox"
                          color="success"
                          dense
                          hide-details
                          inset
                          :label="status.existing.effectiveImmediately ? $t('labels.severedDeleteShiftsImmediately') : $t('labels.severedDeleteShiftsOn', {date: status.existing.effectiveFromFormatted})"
                        />
                        <!-- eslint-disable vue/no-v-html -->
                        <p
                          class="body-2 mb-1"
                          v-html="$t('labels.severedDeleteShiftsConfirmation')"
                        />
                        <VeeTextField
                          v-model="status.existing.severedDeleteShiftsConfirmation"
                          :autocomplete="false"
                          dense
                          :disabled="!status.existing.severedDeleteShifts"
                          outlined
                          :placeholder="$t('labels.deleteShifts')"
                          :rules="status.existing.severedDeleteShifts ? { required: true, is: 'delete shifts' } : {}"
                        />
                      </v-col>
                    </v-row>
                  </v-card>
                </template>
              </v-container>
            </ValidationObserver>
          </v-tab-item>
          <v-tab-item
            eager
            value="accessControl"
          >
            <v-container
              class="pa-0 pt-4"
              :style="contentStyle"
            >
              <portal to="userDialogAccessControl">
                <span>
                  {{ $t('labels.accessControl') }}
                </span>
              </portal>
              <v-data-table
                v-if="internalUser.jobTypeId"
                dense
                fixed-header
                :headers="accessControlHeaders"
                hide-default-footer
                :items="permissions.screens.web"
                :items-per-page="permissions.screens.web.length"
              >
                <template #header.default="{ header }">
                  <span class="grey--text text--darken-3 font-weight-regular">
                    {{ header.text }}
                  </span>
                </template>
                <template #header.viewOnly="{ header }">
                  <span class="grey--text text--darken-3 font-weight-regular">
                    {{ header.text }}
                  </span>
                </template>
                <template #header.noAccess="{ header }">
                  <span class="grey--text text--darken-3 font-weight-regular">
                    {{ header.text }}
                  </span>
                </template>
                <template #item="{ item, headers: tableHeaders }">
                  <tr>
                    <td
                      v-for="header in tableHeaders"
                      :key="header.value"
                      class="text-start"
                    >
                      <span
                        v-if="header.value === 'label'"
                        class="grey--text text--darken-3 text-truncate d-block"
                        :style="{ width: `${header.width}px` }"
                        :title="item.label"
                      >
                        {{ item.label }}
                      </span>
                      <v-radio-group
                        v-else
                        v-model="item.value"
                        class="mt-0 pt-0"
                        hide-details
                      >
                        <v-radio
                          v-if="header.value === 'default'"
                          class="extra-dense-radio"
                          color="secondary"
                          :disabled="!item.editable.includes('default')"
                          label=""
                          value="default"
                        />
                        <v-radio
                          v-if="header.value === 'viewOnly'"
                          class="extra-dense-radio"
                          color="secondary"
                          :disabled="!item.editable.includes('view-only')"
                          label=""
                          value="view-only"
                        />
                        <v-radio
                          v-if="header.value === 'noAccess'"
                          class="extra-dense-radio"
                          color="secondary"
                          :disabled="!item.editable.includes('no-access')"
                          label=""
                          value="no-access"
                        />
                      </v-radio-group>
                    </td>
                  </tr>
                </template>
              </v-data-table>
              <v-container
                v-else
              >
                <NoContent
                  exciting
                  :height="140"
                  :width="140"
                  :message="$t('descriptions.acMissingJobTypeId')"
                />
              </v-container>
            </v-container>
          </v-tab-item>
        </v-tabs>
      </v-card-text>
      <v-divider />
      <v-card-actions class="px-3">
        <v-btn
          v-if="isPending && $can('edit', 'user')"
          class="px-4"
          color="error"
          text
          @click="reject"
        >
          {{ $t('labels.rejectUser') }}
        </v-btn>
        <v-spacer />
        <v-btn
          class="mr-3"
          :disabled="saving"
          text
          @click="$emit('close')"
        >
          {{ $t('labels.close') }}
        </v-btn>
        <v-btn
          v-if="$can('edit', 'user')"
          class="ml-3 verify"
          color="secondary"
          :disabled="invalid || saving"
          @click="passes(save)"
        >
          <v-progress-circular
            v-if="saving"
            color="secondary"
            indeterminate
            size="22"
            width="2"
          />
          <span v-else>
            {{ labels[mode].save }}
          </span>
        </v-btn>
      </v-card-actions>
    </ValidationObserver>
  </v-card>
</template>

<script>
import _ from 'lodash';
import moment from 'moment';
import VeeTextField from '@/components/form_controls/VeeTextField';
import VeeSelect from '@/components/form_controls/VeeSelect';
import NoContent from '@/components/NoContent';
import * as Sentry from '@sentry/vue';
import { DATE_REGEX, PHONE_REGEX_US } from '@/components/form_controls/constants';
import { showStatus } from '@/plugins/vue-notification';
import { processPhoneNumberForUIDisplay } from '@/utils';
import { DATE_FORMAT, DATETIME_FORMAT } from '@/utils/ui';
import { ACCOUNT_STATE } from '@/services/constants';
import { getHospitalObjectsById, getProfileComputedProps, hasDepartmentAccess, hasJobTypeAccess } from '@/utils/org';

export default {
  components: {
    NoContent,
    VeeSelect,
    VeeTextField
  },
  props: {
    showHint: {
      default: false,
      type: Boolean
    },
    showTitle: {
      default: false,
      type: Boolean
    },
    tab: {
      default: 'user',
      type: String
    },
    user: {
      required: true,
      type: Object
    }
  },
  data () {
    const modeNewUser = 'newUser';
    const modeNewProfile = 'newProfile';
    const modeUpdateProfile = 'updateProfile';
    const modePendingProfile = 'pendingProfile';
    const labels = {};
    labels[modeNewUser] = {
      info: '',
      save: this.$t('labels.add'),
      title: this.$t('labels.addUser')
    };
    labels[modeNewProfile] = {
      info: this.$t('descriptions.addProfile'),
      save: this.$t('labels.add'),
      title: this.$t('labels.addUser')
    };
    labels[modeUpdateProfile] = {
      info: '',
      save: this.$t('labels.update'),
      title: this.$t('labels.updateUser')
    };
    labels[modePendingProfile] = {
      info: this.$t('descriptions.pendingProfile'),
      save: this.$t('labels.approve'),
      title: this.$t('labels.reviewUser')
    };

    let mode = '';
    let loading = true;
    let canEditUserDetails = false;
    if (!this.user.userId) {
      mode = modeNewUser;
      loading = false;
      canEditUserDetails = true;
    } else if (!this.user.id) {
      mode = modeNewProfile;
    } else if (this.user.state === ACCOUNT_STATE.pending) {
      mode = modePendingProfile;
    } else {
      mode = modeUpdateProfile;
    }

    const internalUser = _.cloneDeep(this.user);
    internalUser.jobInfo = {
      hiredOn: '',
      ...internalUser.jobInfo
    };
    internalUser.settings = {
      state: {
        modifiedBy: null,
        nextChangeEffectiveOn: null,
        scheduledChanges: [],
        shiftFlags: {
          end: null,
          start: null,
          flags: []
        }
      },
      ...internalUser.settings
    };
    processPhoneNumberForUIDisplay(internalUser);

    if (!_.has(internalUser.settings, 'state.shiftFlags')) {
      _.set(internalUser.settings, 'state.shiftFlags', {
        end: null,
        start: null,
        flags: []
      });
    }

    if (_.isArray(internalUser.settings.state.shiftFlags)) {
      internalUser.settings.state.shiftFlags = {
        end: null,
        start: moment().utc().format(DATETIME_FORMAT),
        flags: internalUser.settings.state.shiftFlags
      };
    }

    let appliedShiftFlags = _.cloneDeep(_.get(internalUser.settings, 'state.shiftFlags.flags', []));
    let newShiftFlags = [];
    let newStatus = '';
    let effectiveFrom = '';
    let effectiveFromFormatted = '';
    let effectiveImmediately = true;
    let effectiveUntil = '';
    let effectiveUntilFormatted = '';
    let activate = 'now';
    let severedDeleteShifts = false;
    if (mode === modeUpdateProfile) {
      const scheduledChanges = _.get(internalUser, 'settings.state.scheduledChanges', []);
      if (scheduledChanges.length > 0) {
        effectiveImmediately = false;
        newStatus = scheduledChanges[0].state;
        effectiveFrom = moment.utc(scheduledChanges[0].effectiveOn).local().format(DATE_FORMAT);
        effectiveFromFormatted = this.formatDate(effectiveFrom);

        if (newStatus === ACCOUNT_STATE.inactive) {
          newShiftFlags = _.cloneDeep(_.get(scheduledChanges[0], 'shiftFlags', []));
        }
        if (newStatus === ACCOUNT_STATE.severed) {
          severedDeleteShifts = _.get(scheduledChanges[0], 'deleteShifts', false);
          newShiftFlags = _.cloneDeep(_.get(scheduledChanges[0], 'shiftFlags', []));
        }

        if (scheduledChanges[1]) {
          effectiveUntil = moment.utc(scheduledChanges[1].effectiveOn).local().format(DATE_FORMAT);
          effectiveUntilFormatted = this.formatDate(effectiveUntil);
        }
      }
    } else if (mode === modePendingProfile) {
      const scheduledChanges = _.get(internalUser, 'settings.state.scheduledChanges', []);
      if (scheduledChanges.length > 0) {
        activate = 'later';
        effectiveFrom = moment.utc(scheduledChanges[0].effectiveOn).local().format(DATE_FORMAT);
        effectiveFromFormatted = this.formatDate(effectiveFrom);
        labels[modePendingProfile].save = this.$t('labels.update');
      }
    }

    return {
      ACCOUNT_STATE,
      accessControlScreens: [],
      canEditUserDetails,
      contentStyle: {
        height: '500px',
        'overflow-y': 'scroll'
      },
      dateRegex: DATE_REGEX,
      dayAfterTommorow: moment().add(2, 'd').format(DATE_FORMAT),
      defaultScreenPermissions: {
        'web': {
          'anonymous': {
            'masterSchedule': {
              'editable': [],
              'value': 'no-access'
            },
            'dailySchedule': {
              'editable': [],
              'value': 'no-access'
            },
            'snapshot': {
              'editable': [],
              'value': 'no-access'
            },
            'template': {
              'editable': [],
              'value': 'no-access'
            },
            'openShift': {
              'editable': [],
              'value': 'no-access'
            },
            'request': {
              'editable': [],
              'value': 'no-access'
            },
            'user': {
              'editable': [],
              'value': 'no-access'
            },
            'report': {
              'editable': [],
              'value': 'no-access'
            }
          },
          'admin': {
            'masterSchedule': {
              'editable': [],
              'value': 'no-access'
            },
            'dailySchedule': {
              'editable': [],
              'value': 'no-access'
            },
            'snapshot': {
              'editable': [],
              'value': 'no-access'
            },
            'template': {
              'editable': [],
              'value': 'no-access'
            },
            'openShift': {
              'editable': [],
              'value': 'no-access'
            },
            'request': {
              'editable': [],
              'value': 'no-access'
            },
            'user': {
              'editable': [],
              'value': 'default'
            },
            'report': {
              'editable': [],
              'value': 'no-access'
            }
          },
          'director': {
            'masterSchedule': {
              'editable': ['default', 'view-only', 'no-access'],
              'value': 'default'
            },
            'dailySchedule': {
              'editable': [],
              'value': 'no-access'
            },
            'snapshot': {
              'editable': ['default', 'view-only', 'no-access'],
              'value': 'default'
            },
            'template': {
              'editable': ['default', 'view-only', 'no-access'],
              'value': 'default'
            },
            'openShift': {
              'editable': [],
              'value': 'no-access'
            },
            'request': {
              'editable': ['default', 'view-only', 'no-access'],
              'value': 'default'
            },
            'user': {
              'editable': ['default', 'view-only', 'no-access'],
              'value': 'default'
            },
            'report': {
              'editable': ['default', 'view-only', 'no-access'],
              'value': 'default'
            }
          },
          'nurse': {
            'masterSchedule': {
              'editable': ['default', 'view-only', 'no-access'],
              'value': 'default'
            },
            'dailySchedule': {
              'editable': ['view-only', 'no-access'],
              'value': 'view-only'
            },
            'snapshot': {
              'editable': [],
              'value': 'no-access'
            },
            'template': {
              'editable': [],
              'value': 'no-access'
            },
            'openShift': {
              'editable': [],
              'value': 'no-access'
            },
            'request': {
              'editable': [],
              'value': 'no-access'
            },
            'user': {
              'editable': [],
              'value': 'no-access'
            },
            'report': {
              'editable': [],
              'value': 'no-access'
            }
          },
          'operator': {
            'masterSchedule': {
              'editable': ['default', 'view-only', 'no-access'],
              'value': 'default'
            },
            'dailySchedule': {
              'editable': ['default', 'view-only', 'no-access'],
              'value': 'default'
            },
            'snapshot': {
              'editable': ['default', 'view-only', 'no-access'],
              'value': 'default'
            },
            'template': {
              'editable': ['default', 'view-only', 'no-access'],
              'value': 'default'
            },
            'openShift': {
              'editable': ['default', 'view-only', 'no-access'],
              'value': 'default'
            },
            'request': {
              'editable': ['default', 'view-only', 'no-access'],
              'value': 'default'
            },
            'user': {
              'editable': ['default', 'view-only', 'no-access'],
              'value': 'default'
            },
            'report': {
              'editable': ['default', 'view-only', 'no-access'],
              'value': 'default'
            }
          },
          'scheduler': {
            'masterSchedule': {
              'editable': ['default', 'view-only', 'no-access'],
              'value': 'default'
            },
            'dailySchedule': {
              'editable': [],
              'value': 'no-access'
            },
            'snapshot': {
              'editable': ['default', 'view-only', 'no-access'],
              'value': 'default'
            },
            'template': {
              'editable': ['default', 'view-only', 'no-access'],
              'value': 'default'
            },
            'openShift': {
              'editable': [],
              'value': 'no-access'
            },
            'request': {
              'editable': ['default', 'view-only', 'no-access'],
              'value': 'default'
            },
            'user': {
              'editable': ['default', 'view-only', 'no-access'],
              'value': 'default'
            },
            'report': {
              'editable': ['default', 'view-only', 'no-access'],
              'value': 'default'
            }
          },
          'secretary': {
            'masterSchedule': {
              'editable': ['default', 'view-only', 'no-access'],
              'value': 'default'
            },
            'dailySchedule': {
              'editable': ['view-only', 'no-access'],
              'value': 'view-only'
            },
            'snapshot': {
              'editable': [],
              'value': 'no-access'
            },
            'template': {
              'editable': [],
              'value': 'no-access'
            },
            'openShift': {
              'editable': [],
              'value': 'no-access'
            },
            'request': {
              'editable': [],
              'value': 'no-access'
            },
            'user': {
              'editable': [],
              'value': 'no-access'
            },
            'report': {
              'editable': [],
              'value': 'no-access'
            }
          },
          'supervisor': {
            'masterSchedule': {
              'editable': ['default', 'view-only', 'no-access'],
              'value': 'default'
            },
            'dailySchedule': {
              'editable': ['default', 'view-only', 'no-access'],
              'value': 'default'
            },
            'snapshot': {
              'editable': ['default', 'view-only', 'no-access'],
              'value': 'default'
            },
            'template': {
              'editable': ['default', 'view-only', 'no-access'],
              'value': 'default'
            },
            'openShift': {
              'editable': ['default', 'view-only', 'no-access'],
              'value': 'default'
            },
            'request': {
              'editable': ['default', 'view-only', 'no-access'],
              'value': 'default'
            },
            'user': {
              'editable': ['default', 'view-only', 'no-access'],
              'value': 'default'
            },
            'report': {
              'editable': ['default', 'view-only', 'no-access'],
              'value': 'default'
            }
          },
          'transporter': {
            'masterSchedule': {
              'editable': ['default', 'view-only', 'no-access'],
              'value': 'default'
            },
            'dailySchedule': {
              'editable': ['view-only', 'no-access'],
              'value': 'view-only'
            },
            'snapshot': {
              'editable': [],
              'value': 'no-access'
            },
            'template': {
              'editable': [],
              'value': 'no-access'
            },
            'openShift': {
              'editable': [],
              'value': 'no-access'
            },
            'request': {
              'editable': [],
              'value': 'no-access'
            },
            'user': {
              'editable': [],
              'value': 'no-access'
            },
            'report': {
              'editable': [],
              'value': 'no-access'
            }
          }
        }
      },
      defaultScreenPermissionsOrder: [
        'masterSchedule',
        'dailySchedule',
        'snapshot',
        'template',
        'openShift',
        'request',
        'user',
        'report'
      ],
      hiredOn: this.formatDate(internalUser.jobInfo.hiredOn),
      internalUser,
      labels,
      loading,
      mode,
      modeNewUser,
      modeNewProfile,
      modePendingProfile,
      modeUpdateProfile,
      permissions: {
        screens: {
          web: []
        }
      },
      permissionsId: null,
      phoneRegex: PHONE_REGEX_US,
      phoneCountryCode: '+1',
      phoneMask: '(###) ###-####',
      saving: false,
      selectedTab: this.tab,
      showHiredOnPicker: false,
      status: {
        new: {
          activate,
          effectiveFrom,
          effectiveFromFormatted,
          showEffectiveFromPicker: false
        },
        existing: {
          appliedShiftFlags,
          newStatus,
          effectiveFrom,
          effectiveFromFormatted,
          effectiveImmediately,
          effectiveUntil,
          effectiveUntilFormatted,
          showEffectiveFromPicker: false,
          showEffectiveUntilPicker: false,
          shiftFlags: newShiftFlags,
          severedDeleteShifts,
          severedDeleteShiftsConfirmation: ''
        }
      },
      today: moment().format(DATE_FORMAT),
      tommorow: moment().add(1, 'd').format(DATE_FORMAT)
    };
  },
  computed: {
    accessControlHeaders () {
      if (!this.internalUser.jobTypeId) {
        return [];
      }
      const group = _.get(this.jobTypesById, [this.internalUser.jobTypeId, 'settings', 'associatedGroups', 0], null);
      if (!group) {
        return [];
      }

      return [
        {
          sortable: false,
          text: '',
          value: 'label',
          width: 200
        },
        {
          align: 'center',
          sortable: false,
          text: this.$t(`labels.defaultAccess${_.capitalize(_.camelCase(group))}`),
          value: 'default',
          width: 100
        },
        {
          align: 'center',
          sortable: false,
          text: this.$t('labels.viewOnly'),
          value: 'viewOnly',
          width: 100
        },
        {
          align: 'center',
          sortable: false,
          text: this.$t('labels.noAccess'),
          value: 'noAccess',
          width: 100
        }
      ];
    },
    canBeChargeNurse () {
      const jobType = _.find(this.jobTypes, (j) => j.id === this.internalUser.jobTypeId);
      let canBeChargeNurse = false;
      if (jobType) {
        canBeChargeNurse = _.get(jobType, 'settings.canBeChargeNurse', false);
      }

      return canBeChargeNurse;
    },
    departments () {
      return _.sortBy(this.$store.state.org.departments, ['name']);
    },
    disableUserInfo () {
      return !!this.internalUser.userId && !this.canEditUserDetails;
    },
    filteredDepartments () {
      return _.filter(this.departments, (d) => hasDepartmentAccess(this.$store.state.account.profile, d.id));
    },
    filteredJobTypes () {
      return _.filter(this.jobTypes, (jt) => hasJobTypeAccess(this.$store.state.account.profile, jt));
    },
    isPending () {
      return this.internalUser.state === ACCOUNT_STATE.pending;
    },
    jobStatus () {
      return _.sortBy(this.$store.state.org.jobStatus, ['name']);
    },
    jobTypes () {
      return _.sortBy(this.$store.state.org.jobTypes, ['name']);
    },
    jobTypesById () {
      return this.jobTypes.reduce((jobType, value) => {
        jobType[value.id] = value;
        return jobType;
      }, {});
    },
    newStatusMessage () {
      let message = '';
      if (this.mode === this.modeUpdateProfile && this.status.existing.newStatus && !this.status.existing.effectiveImmediately) {
        if (this.status.existing.effectiveFrom && this.status.existing.effectiveUntil) {
          message = this.$t('descriptions.userStatusFromAndTo', {
            currentStatus: this.internalUser.state,
            from: this.status.existing.effectiveFromFormatted,
            newStatus: this.status.existing.newStatus,
            to: this.status.existing.effectiveUntilFormatted
          });
        } else if (this.status.existing.effectiveFrom) {
          message = this.$t('descriptions.userStatusFrom', { from: this.status.existing.effectiveFromFormatted, status: this.status.existing.newStatus });
        }
      }
      return message;
    },
    shiftFlags () {
      return _.filter(_.sortBy(this.$store.state.org.flags, ['name']), (f) => !f.working);
    },
    shiftFlagsById () {
      return this.shiftFlags.reduce((flags, value) => {
        flags[value.id] = value;
        return flags;
      }, {});
    },
    shiftTypes () {
      const jobType = _.find(this.jobTypes, (j) => j.id === this.internalUser.jobTypeId);
      if (jobType) {
        const shiftTypes = _.cloneDeep(this.$store.state.org.shiftTypes);
        for (let i = 0, count = shiftTypes.length; i < count; i++) {
          const time = [
            _.split(shiftTypes[i].startTime, ':', 2).join(':'),
            _.split(shiftTypes[i].endTime, ':', 2).join(':')
          ].join(' - ');
          shiftTypes[i].name = `${shiftTypes[i].name} (${time})`;
        }
        const associatedShiftTypes = _.get(jobType, 'settings.associatedShiftTypes', []);
        return _.sortBy(_.filter(shiftTypes, (s) => associatedShiftTypes.includes(s.id)), ['name']);
      }

      return [];
    },
    statusList () {
      return _.filter([
        { id: ACCOUNT_STATE.active, name: this.$t('labels.active') },
        { id: ACCOUNT_STATE.inactive, name: this.$t('labels.inactive') },
        { id: ACCOUNT_STATE.severed, name: this.$t('labels.severed') }
      ], (s) => s.id !== this.user.state);
    }
  },
  watch: {
    'selectedTab' () {
      this.$emit('tabchanged', this.selectedTab);
    },
    'internalUser.jobTypeId' () {
      this.preparePermissions();
    },
    'internalUser.jobInfo.hiredOn' () {
      this.hiredOn = this.formatDate(this.internalUser.jobInfo.hiredOn);
    },
    'status.new.effectiveFrom' () {
      this.status.new.effectiveFromFormatted = this.formatDate(this.status.new.effectiveFrom);
    },
    'status.existing.effectiveFrom' () {
      this.status.existing.effectiveFromFormatted = this.formatDate(this.status.existing.effectiveFrom);
    },
    'status.existing.effectiveUntil' () {
      this.status.existing.effectiveUntilFormatted = this.formatDate(this.status.existing.effectiveUntil);
    }
  },
  mounted () {
    if (this.loading) {
      this.loadUserInfo();
    }
  },
  methods: {
    // This function is added mainly for mocking during unit tests.
    dispatch (action, payload) {
      return new Promise((resolve, reject) => {
        this.$store.dispatch(action, payload).then(response => {
          resolve(response);
        }).catch(error => {
          reject(error);
        });
      });
    },
    flagsFilter (item, queryText) {
      return item.name.toLocaleLowerCase().indexOf(queryText.toLocaleLowerCase()) > -1 ||
        item.shortCode.toLocaleLowerCase().indexOf(queryText.toLocaleLowerCase()) > -1;
    },
    formatDate (date) {
      if (!date) {
        return null;
      }

      const [year, month, day] = date.split('-');
      return `${month}/${day}/${year}`;
    },
    getPermissions () {
      const permissions = {
        screens: {
          web: {}
        }
      };

      for (let p of this.permissions.screens.web) {
        if (p.name === 'schedule') {
          permissions.screens.web['masterSchedule'] = p.value;
          permissions.screens.web['dailySchedule'] = p.value;
        } else {
          permissions.screens.web[p.name] = p.value;
        }
      }
      return permissions;
    },
    getUserWithComputedFields (user) {
      const hospital = getHospitalObjectsById(this.$store.state.org);
      return {
        ...this.$store.state.org.employees[user.userId] ? this.$store.state.org.employees[user.userId] : {},
        ...user,
        ...getProfileComputedProps(user, hospital)
      };
    },
    loadUserInfo () {
      this.dispatch('account/retrieveUserAndProfilesById', { userId: this.user.userId }).then((response) => {
        if (this.user.id) {
          this.preparePermissions(_.get(_.find(response.profiles, (p) => p.id === this.user.id), 'permissions', {}));
        } else {
          this.preparePermissions({});
        }
        return this.dispatch('account/canUpdateUser', this.user.userId).then(() => {
          this.canEditUserDetails = true;
        }).catch(() => {
          this.canEditUserDetails = false;
        });
      }).catch((error) => {
        Sentry.captureException(error);
      }).finally(() => {
        this.loading = false;
      });
    },
    parseDate (date) {
      if (!date) {
        return null;
      }

      const [month, day, year] = date.split('/');
      return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`;
    },
    preparePermissions (permissions) {
      if (!this.internalUser.jobTypeId) {
        this.permissions.screens.web = [];
        return;
      }
      const group = _.get(this.jobTypesById, [this.internalUser.jobTypeId, 'settings', 'associatedGroups', 0], null);
      if (!group) {
        this.permissions.screens.web = [];
        return;
      }
      const webScreens = [];
      const isOperationsManagement = ['operator', 'supervisor'].includes(group);
      for (const platform in this.defaultScreenPermissions) {
        let defaultPermissions = this.defaultScreenPermissions[platform]['anonymous'];
        if (this.defaultScreenPermissions[platform][group]) {
          defaultPermissions = this.defaultScreenPermissions[platform][group];
        }

        const platformPermissions = [];
        for (const permission in defaultPermissions) {
          const permissionCopy = _.cloneDeep(defaultPermissions[permission]);

          if (isOperationsManagement && ['masterSchedule', 'dailySchedule'].includes(permission)) {
            if (permission === 'masterSchedule') {
              permissionCopy.name = 'schedule';
              permissionCopy.label = this.$tc('labels.schedule', 1);
              if (_.has(permissions, `screens.web.${permission}`) && ['default', 'view-only', 'no-access'].includes(permissions.screens.web[permission])) {
                permissionCopy.value = permissions.screens.web[permission];
              }
            } else {
              continue;
            }
          } else {
            permissionCopy.name = permission;
            permissionCopy.label = this.$tc(`labels.${permission}`, 1);
            if (_.has(permissions, `screens.web.${permission}`) && ['default', 'view-only', 'no-access'].includes(permissions.screens.web[permission])) {
              permissionCopy.value = permissions.screens.web[permission];
            }
          }
          platformPermissions.push(permissionCopy);
        }

        webScreens[platform] = platformPermissions;
      }

      this.permissions.screens = webScreens;
      this.permissionsId = _.get(permissions, 'permissions_id', null);
      if (!this.permissionsId) {
        this.permissionsId = _.get(permissions, 'permissionsId', null);
      }
    },
    reject () {
      this.saving = true;
      const profile = {
        state: ACCOUNT_STATE.rejected,
        settings: {
          ...this.internalUser.settings,
          state: {
            modifiedBy: null,
            nextChangeEffectiveOn: null,
            scheduledChanges: [],
            shiftFlags: []
          }
        }
      };
      this.dispatch('account/updateProfile', {
        id: this.internalUser.id,
        profile
      }).then(() => {
        this.$emit('saved', {
          ...this.internalUser,
          ...profile
        });
        showStatus({
          text: this.$t('descriptions.updatedProfile'),
          type: 'success'
        });
      }).catch(error => {
        const data = {
          error: _.get(error, 'response.data')
        };

        showStatus({
          text: this.$t('descriptions.genericError'),
          type: 'error',
          data
        });
      }).finally(() => {
        this.saving = false;
      });
    },
    save () {
      this.saving = true;
      let state = '';
      let settings = this.internalUser.settings;
      let effectiveOn = '';
      let effectiveUntil = '';
      let scheduledChanges = [];
      let shiftFlags = [];
      let phonePersonal = '';
      let phoneWork = null;
      let profile = null;
      if (this.internalUser.phonePersonal) {
        phonePersonal = this.phoneCountryCode + this.internalUser.phonePersonal;
      }
      if (this.internalUser.phoneWork) {
        phoneWork = this.phoneCountryCode + this.internalUser.phoneWork;
      }
      const userInfo = {
        alias: this.internalUser.alias,
        email: this.internalUser.email,
        firstName: this.internalUser.firstName,
        gender: this.internalUser.gender,
        lastName: this.internalUser.lastName,
        phonePersonal
      };
      switch (this.mode) {
        case this.modeNewUser:
          if (this.status.new.activate === 'now') {
            state = ACCOUNT_STATE.active;
          } else {
            state = ACCOUNT_STATE.inactive;
            effectiveOn = moment(`${this.status.new.effectiveFrom} 00:00:00`).utc().format(DATETIME_FORMAT);
            _.set(settings, 'state', {
              modifiedBy: this.$store.state.account.userId,
              nextChangeEffectiveOn: effectiveOn,
              scheduledChanges: [{
                effectiveOn: effectiveOn,
                state: ACCOUNT_STATE.active
              }],
              shiftFlags: {
                end: null,
                start: null,
                flags: []
              }
            });
          }
          this.dispatch('account/createNewAccount', {
            alias: this.internalUser.alias,
            email: this.internalUser.email,
            firstName: this.internalUser.firstName,
            gender: this.internalUser.gender,
            lastName: this.internalUser.lastName,
            phonePersonal,
            profiles: [
              {
                charge: this.canBeChargeNurse ? this.internalUser.charge : false,
                dailyHours: this.internalUser.dailyHours,
                departmentId: this.internalUser.departmentId,
                employeeId: this.internalUser.employeeId,
                jobInfo: this.internalUser.jobInfo,
                jobStatusId: this.internalUser.jobStatusId,
                jobTitle: this.internalUser.jobTitle,
                jobTypeId: this.internalUser.jobTypeId,
                phoneWork,
                orgId: this.$store.state.org.id,
                settings,
                shiftTypeId: this.internalUser.shiftTypeId,
                state,
                weeklyHours: this.internalUser.weeklyHours
              }
            ],
            timezone: this.$store.state.org.info.timezone
          }).then((newUser) => {
            const profile = _.find(newUser.profiles, (p) => p.orgId === this.$store.state.org.id);
            this.$emit('saved', this.getUserWithComputedFields({
              ...newUser,
              ...profile,
              userId: newUser.id
            }));
            showStatus({
              text: this.$t('descriptions.userCreated'),
              type: 'success'
            });
            return this.dispatch('account/createProfilePermissions', {
              ...this.getPermissions(),
              profile_id: profile.id
            }).then((response) => {
              this.permissionsId = response.id;
            }).catch((error) => {
              const data = {
                error: _.get(error, 'response.data')
              };

              showStatus({
                text: this.$t('descriptions.permissionsSaveFail'),
                type: 'error',
                data
              });
            });
          }).catch(error => {
            const data = {
              error: _.get(error, 'response.data')
            };

            showStatus({
              text: this.$t('descriptions.genericError'),
              type: 'error',
              data
            });
          }).finally(() => {
            this.saving = false;
          });
          break;
        case this.modeNewProfile:
          if (this.status.new.activate === 'now') {
            state = ACCOUNT_STATE.active;
          } else {
            state = ACCOUNT_STATE.inactive;
            effectiveOn = moment(`${this.status.new.effectiveFrom} 00:00:00`).utc().format(DATETIME_FORMAT);
            _.set(settings, 'state', {
              modifiedBy: this.$store.state.account.userId,
              nextChangeEffectiveOn: effectiveOn,
              scheduledChanges: [{
                effectiveOn: effectiveOn,
                state: ACCOUNT_STATE.active
              }],
              shiftFlags: {
                end: null,
                start: null,
                flags: []
              }
            });
          }
          this.dispatch('account/createProfile', {
            charge: this.canBeChargeNurse ? this.internalUser.charge : false,
            dailyHours: this.internalUser.dailyHours,
            departmentId: this.internalUser.departmentId,
            employeeId: this.internalUser.employeeId,
            jobInfo: this.internalUser.jobInfo,
            jobStatusId: this.internalUser.jobStatusId,
            jobTitle: this.internalUser.jobTitle,
            jobTypeId: this.internalUser.jobTypeId,
            phoneWork,
            orgId: this.$store.state.org.id,
            settings,
            shiftTypeId: this.internalUser.shiftTypeId,
            state,
            userId: this.internalUser.userId,
            weeklyHours: this.internalUser.weeklyHours
          }).then((result) => {
            profile = result;
            if (!this.disableUserInfo) {
              return this.dispatch('account/updateUser', {
                userId: this.internalUser.userId,
                data: {
                  ...userInfo
                }
              });
            }
          }).then(() => {
            this.$emit('saved', this.getUserWithComputedFields({
              ...this.internalUser,
              ...profile,
              ...userInfo
            }));
            showStatus({
              text: this.$t('descriptions.newProfile'),
              type: 'success'
            });
            return this.dispatch('account/createProfilePermissions', {
              ...this.getPermissions(),
              profile_id: profile.id
            }).then((response) => {
              this.permissionsId = response.id;
            }).catch((error) => {
              const data = {
                error: _.get(error, 'response.data')
              };

              showStatus({
                text: this.$t('descriptions.permissionsSaveFail'),
                type: 'error',
                data
              });
            });
          }).catch(error => {
            const data = {
              error: _.get(error, 'response.data')
            };

            showStatus({
              text: this.$t('descriptions.genericError'),
              type: 'error',
              data
            });
          }).finally(() => {
            this.saving = false;
          });
          break;
        case this.modeUpdateProfile:
          shiftFlags = _.filter(this.status.existing.shiftFlags, _.isFinite);
          if (this.status.existing.newStatus && this.status.existing.effectiveImmediately) {
            state = this.status.existing.newStatus;
            settings.state = {
              deleteShifts: this.status.existing.newStatus === ACCOUNT_STATE.severed ? this.status.existing.severedDeleteShifts : false,
              modifiedBy: null,
              nextChangeEffectiveOn: null,
              scheduledChanges: [],
              shiftFlags: {
                end: null,
                start: moment().utc().format(DATETIME_FORMAT),
                flags: this.status.existing.newStatus === ACCOUNT_STATE.active ? [] : shiftFlags
              }
            };
          } else if (this.status.existing.newStatus && !this.status.existing.effectiveImmediately) {
            state = this.internalUser.state;
            effectiveOn = moment(`${this.status.existing.effectiveFrom} 00:00:00`).utc().format(DATETIME_FORMAT);
            scheduledChanges.push({
              deleteShifts: this.status.existing.newStatus === ACCOUNT_STATE.severed ? this.status.existing.severedDeleteShifts : false,
              effectiveOn: effectiveOn,
              state: this.status.existing.newStatus,
              shiftFlags: this.status.existing.newStatus === ACCOUNT_STATE.active ? [] : shiftFlags
            });
            if (this.status.existing.effectiveUntil && this.status.existing.newStatus === ACCOUNT_STATE.inactive) {
              effectiveUntil = moment(`${this.status.existing.effectiveUntil} 00:00:00`).utc().format(DATETIME_FORMAT);
              scheduledChanges.push({
                effectiveOn: effectiveUntil,
                state: this.internalUser.state,
                shiftFlags: []
              });
            }
            settings.state = {
              deleteShifts: false,
              modifiedBy: this.$store.state.account.userId,
              nextChangeEffectiveOn: effectiveOn,
              scheduledChanges,
              shiftFlags: _.get(settings, 'state.shiftFlags', {
                end: null,
                start: null,
                flags: []
              })
            };
          } else {
            state = this.internalUser.state;
            if (!this.status.existing.newStatus) {
              settings.state = {
                deleteShifts: false,
                modifiedBy: null,
                nextChangeEffectiveOn: null,
                scheduledChanges: [],
                shiftFlags: {
                  end: null,
                  start: null,
                  flags: []
                }
              };
            }
          }

          this.dispatch('account/updateProfile', {
            id: this.internalUser.id,
            profile: {
              charge: this.canBeChargeNurse ? this.internalUser.charge : false,
              dailyHours: this.internalUser.dailyHours,
              departmentId: this.internalUser.departmentId,
              employeeId: this.internalUser.employeeId,
              jobInfo: this.internalUser.jobInfo,
              jobStatusId: this.internalUser.jobStatusId,
              jobTitle: this.internalUser.jobTitle,
              jobTypeId: this.internalUser.jobTypeId,
              phoneWork,
              settings,
              shiftTypeId: this.internalUser.shiftTypeId,
              state,
              weeklyHours: this.internalUser.weeklyHours
            }
          }).then((result) => {
            profile = result;
            if (!this.disableUserInfo) {
              return this.dispatch('account/updateUser', {
                userId: this.internalUser.userId,
                data: {
                  ...userInfo
                }
              });
            }
          }).then(() => {
            this.$emit('saved', this.getUserWithComputedFields({
              ...this.internalUser,
              ...profile,
              ...userInfo
            }));
            showStatus({
              text: this.$t('descriptions.updatedProfile'),
              type: 'success'
            });
            if (this.permissionsId) {
              return this.dispatch('account/updateProfilePermissions', {
                id: this.permissionsId,
                permissions: this.getPermissions()
              }).catch((error) => {
                const data = {
                  error: _.get(error, 'response.data')
                };

                showStatus({
                  text: this.$t('descriptions.permissionsSaveFail'),
                  type: 'error',
                  data
                });
              });
            } else {
              return this.dispatch('account/createProfilePermissions', {
                ...this.getPermissions(),
                profile_id: profile.id
              }).then((response) => {
                this.permissionsId = response.id;
              }).catch((error) => {
                const data = {
                  error: _.get(error, 'response.data')
                };

                showStatus({
                  text: this.$t('descriptions.permissionsSaveFail'),
                  type: 'error',
                  data
                });
              });
            }
          }).catch(error => {
            const data = {
              error: _.get(error, 'response.data')
            };

            showStatus({
              text: this.$t('descriptions.genericError'),
              type: 'error',
              data
            });
          }).finally(() => {
            this.saving = false;
          });
          break;
        case this.modePendingProfile:
          if (this.status.new.activate === 'now') {
            state = ACCOUNT_STATE.active;
            _.set(settings, 'state', {
              modifiedBy: null,
              nextChangeEffectiveOn: null,
              scheduledChanges: [],
              shiftFlags: {
                end: null,
                start: null,
                flags: []
              }
            });
          } else {
            state = ACCOUNT_STATE.pending;
            effectiveOn = moment(`${this.status.new.effectiveFrom} 00:00:00`).utc().format(DATETIME_FORMAT);
            _.set(settings, 'state', {
              modifiedBy: this.$store.state.account.userId,
              nextChangeEffectiveOn: effectiveOn,
              scheduledChanges: [{
                effectiveOn: effectiveOn,
                state: ACCOUNT_STATE.active
              }],
              shiftFlags: {
                end: null,
                start: null,
                flags: []
              }
            });
          }

          this.dispatch('account/updateProfile', {
            id: this.internalUser.id,
            profile: {
              charge: this.canBeChargeNurse ? this.internalUser.charge : false,
              dailyHours: this.internalUser.dailyHours,
              departmentId: this.internalUser.departmentId,
              employeeId: this.internalUser.employeeId,
              jobInfo: this.internalUser.jobInfo,
              jobStatusId: this.internalUser.jobStatusId,
              jobTitle: this.internalUser.jobTitle,
              jobTypeId: this.internalUser.jobTypeId,
              phoneWork,
              settings,
              shiftTypeId: this.internalUser.shiftTypeId,
              state,
              weeklyHours: this.internalUser.weeklyHours
            }
          }).then((result) => {
            profile = result;
            if (!this.disableUserInfo) {
              return this.dispatch('account/updateUser', {
                userId: this.internalUser.userId,
                data: {
                  ...userInfo
                }
              });
            }
          }).then(() => {
            this.$emit('saved', this.getUserWithComputedFields({
              ...this.internalUser,
              ...profile,
              ...userInfo
            }));
            showStatus({
              text: this.$t('descriptions.updatedProfile'),
              type: 'success'
            });
            if (this.permissionsId) {
              return this.dispatch('account/updateProfilePermissions', {
                id: this.permissionsId,
                permissions: this.getPermissions()
              }).catch((error) => {
                const data = {
                  error: _.get(error, 'response.data')
                };

                showStatus({
                  text: this.$t('descriptions.permissionsSaveFail'),
                  type: 'error',
                  data
                });
              });
            } else {
              return this.dispatch('account/createProfilePermissions', {
                ...this.getPermissions(),
                profile_id: profile.id
              }).then((response) => {
                this.permissionsId = response.id;
              }).catch((error) => {
                const data = {
                  error: _.get(error, 'response.data')
                };

                showStatus({
                  text: this.$t('descriptions.permissionsSaveFail'),
                  type: 'error',
                  data
                });
              });
            }
          }).catch(error => {
            const data = {
              error: _.get(error, 'response.data')
            };

            showStatus({
              text: this.$t('descriptions.genericError'),
              type: 'error',
              data
            });
          }).finally(() => {
            this.saving = false;
          });
          break;
      }
    }
  }
};
</script>

<style lang="scss">
#userProfile {
  .col {
    padding-bottom: 0px;
    padding-top: 4px;
  }
  .new-profile {
    .effective-from {
      .v-input--selection-controls__input {
        margin-top: -24px;
      }
    }
  }
  // At the moment we don't ask user to enter country code when entering their phone numbers.
  // And we only handles US phone number. And the US country code is always displayed.
  // inside the input box with greyed out color.
  .phone-number .v-text-field__prefix {
    color: map-get($grey, 'base');
  }
  .shift-flags {
    .v-select__selections {
      margin-top: 4px !important;
    }
  }
  .v-text-field__details {
    padding-right: 0px;
  }
  .v-window {
    overflow: hidden;
  }
}

#accessControl {
  table {
    border: 1px solid map-get($grey, 'lighten-2');
    border-radius: 4px;
    tbody {
      td {
        border-bottom: none !important;
      }
    }
    thead {
      th {
        background-color: map-get($grey, 'lighten-4');
      }
    }
    .v-input--selection-controls__ripple {
      display: none;
    }
    .v-radio {
      display: inline-block;
      text-align: center;
    }
  }
}
</style>
