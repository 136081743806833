<template>
  <v-app :class="[$vuetify.breakpoint.smAndDown ? 'mobile' : 'desktop' ]">
    <template v-if="browserSupported">
      <template v-if="isAuthenticated">
        <template v-if="activateUser || confirmEmailChange">
          <router-view />
        </template>
        <template v-else-if="$store.getters.appInitialized">
          <template v-if="mobileAppOnly">
            <MobileAppOnly />
          </template>
          <template v-else>
            <AppBar />
            <AdminNavigation v-if="$store.getters['account/isAdmin']()" />
            <ManagementNavigation v-else-if="$store.getters['account/isDepartmentManagement']() || $store.getters['account/isOperationsManagement']()" />
            <StaffNavigation v-else />
            <v-main class="default-bg">
              <router-view />
              <SidePanel
                :panels="sidePanels"
                @transitionend="onSidePanelTransitionEnd"
              />
            </v-main>
          </template>
        </template>
        <template v-else>
          <AppInitializer />
        </template>
      </template>
      <template v-else>
        <template v-if="activateUser || confirmEmailChange">
          <router-view />
        </template>
        <template v-else>
          <Auth />
        </template>
      </template>
      <notifications
        classes="vn-status-message"
        group="status"
        :max="3"
        position="top center"
        :width="$vuetify.breakpoint.smAndDown ? '85%' : '500px'"
      >
        <template
          slot="body"
          slot-scope="props"
        >
          <v-row
            :class="[props.item.type, 'caption', 'elevation-2', 'py-2', 'vn-status-message']"
            dense
          >
            <v-col class="pa-0">
              <v-row
                align="center"
                dense
              >
                <v-col
                  cols="9"
                  md="10"
                  sm="9"
                >
                  <div
                    v-if="props.item.title"
                    class="font-weight-bold notification-title"
                  >
                    {{ props.item.title }}
                  </div>
                  <div
                    v-if="props.item.text"
                    class="font-weight-bold notification-content"
                  >
                    {{ props.item.text }}
                  </div>
                </v-col>
                <v-divider
                  class="ml-1 mr-n2"
                  vertical
                />
                <v-col
                  class="text-center"
                  cols="3"
                  md="2"
                  sm="3"
                >
                  <a
                    class="close font-weight-medium pl-5 pr-4 py-3 white--text"
                    @click="props.close"
                  >
                    {{ $t('labels.close') }}
                  </a>
                </v-col>
              </v-row>
              <v-row
                v-if="props.item.data && props.item.data.error"
                dense
              >
                <v-col>
                  <v-expansion-panels accordion>
                    <v-expansion-panel>
                      <v-expansion-panel-header expand-icon="fal fa-chevron-down">
                        <div>
                          <span>{{ $t('labels.details') }}</span>
                        </div>
                      </v-expansion-panel-header>
                      <v-expansion-panel-content>
                        {{ props.item.data.error }}
                      </v-expansion-panel-content>
                    </v-expansion-panel>
                  </v-expansion-panels>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </template>
      </notifications>
      <notifications
        classes="vn-notification"
        group="notification"
        :max="5"
        position="top right"
        width="320"
      >
        <template
          slot="body"
          slot-scope="props"
        >
          <v-row
            align="center"
            :class="[props.item.type, 'elevation-2', 'pa-2', 'vn-notification']"
            dense
            @click="onNotificationClicked(props)"
          >
            <v-col cols="1">
              <v-icon
                v-if="props.item.type == 'error'"
                dense
              >
                fas fa-times-circle
              </v-icon>
              <v-icon
                v-if="props.item.type == 'info'"
                dense
              >
                fas fa-exclamation-circle
              </v-icon>
              <v-icon
                v-if="props.item.type == 'success'"
                dense
              >
                fas fa-check-circle
              </v-icon>
              <v-icon
                v-if="props.item.type == 'warning'"
                dense
              >
                fas fa-exclamation-square
              </v-icon>
            </v-col>
            <v-col cols="10">
              <div class="caption grey--text font-weight-medium ml-2 notification-content text--darken-3">
                {{ props.item.text }}
              </div>
            </v-col>
            <v-col
              class="text-center"
              cols="1"
            >
              <a
                class="grey--text text--darken-1"
                @click.stop="props.close"
              >
                <v-icon
                  class="close"
                  small
                >
                  fal fa-times
                </v-icon>
              </a>
            </v-col>
          </v-row>
        </template>
      </notifications>
    </template>
    <template v-else>
      <BrowserNotSupported />
    </template>
  </v-app>
</template>

<script>
import _ from 'lodash';
import AppInitializer from './AppInitializer';
import AppBar from '@/views/AppBar';
import Auth from './views/auth/Auth';
import BrowserNotSupported from './components/BrowserNotSupported';
import MobileAppOnly from './components/MobileAppOnly';
import AdminNavigation from '@/views/navigation/AdminNavigation';
import ManagementNavigation from '@/views/navigation/ManagementNavigation';
import StaffNavigation from '@/views/navigation/StaffNavigation';
import SidePanel from '@/components/SidePanel';
import { mapGetters } from 'vuex';
import { isVersionLessThan, getVersion, getMetadataURL } from '@/utils';
import axios from 'axios';

const { detect } = require('detect-browser');
const axiosInstance = axios.create();

export default {
  name: 'App',
  components: {
    AdminNavigation,
    AppBar,
    AppInitializer,
    Auth,
    BrowserNotSupported,
    ManagementNavigation,
    MobileAppOnly,
    SidePanel,
    StaffNavigation
  },
  data () {
    return {
      intervalId: null,
      newVersion: null,
      version: getVersion()
    };
  },
  computed: {
    activateUser () {
      const uid = _.get(this.$route, 'params.uid', null);
      const token = _.get(this.$route, 'params.token', null);

      return (uid && token);
    },

    browserSupported () {
      const browser = detect();

      let browserSupported = true;
      let majorVersion;

      switch (browser && browser.name) {
        // Per Vuetify documentation at: https://vuetifyjs.com/en/getting-started/quick-start
        // We don't support any version of IE and any Safari below version 10.
        case 'ie':
          browserSupported = false;
          break;
        case 'safari':
          majorVersion = parseInt(browser.version.split('.')[0]);
          if (majorVersion < 10) {
            browserSupported = false;
          }
          break;
      }

      return browserSupported;
    },

    confirmEmailChange () {
      const uid = _.get(this.$route, 'params.uid', null);
      const newEmail = _.get(this.$route, 'params.newEmail', null);
      const token = _.get(this.$route, 'params.token', null);

      return (uid && newEmail && token);
    },

    mobileAppOnly () {
      return this.$store.getters['account/isStaff']() && !this.$store.state.org.activeSubscriptionId;
    },

    sidePanels () {
      return this.$store.state.sidePanels;
    },

    ...mapGetters('auth', [
      'isAuthenticated'
    ])
  },
  created () {
    window.addEventListener('beforeunload', (event) => {
      if (this.$store.getters.hasUnsavedChanges) {
        event.preventDefault();
        event.returnValue = '';
      }
    });
    window.addEventListener('blur', () => {
      if (!this.newVersion) {
        this.stopVersionCheck();
      }
    });
    window.addEventListener('focus', () => {
      if (!this.newVersion) {
        this.startVersionCheck();
      }
    });
  },
  mounted () {
    if (this.version) {
      this.startVersionCheck();
      this.checkForNewVersion();
    }
  },
  methods: {
    checkForNewVersion () {
      try {
        const file = getMetadataURL(`${process.env.NODE_ENV}_web.json`);
        axiosInstance.get(file, {
          headers: {
            'Cache-Control': 'no-cache',
            'Pragma': 'no-cache',
            'Expires': 0
          }
        }).then(response => {
          const latestVersion = _.trim(_.get(response.data, ['version'], '')).replace('v', '');
          if (!latestVersion) {
            return;
          }
          if (isVersionLessThan(this.version, latestVersion)) {
            this.$dialog.acknowledge({
              body: this.$t('descriptions.newVersionAvailable'),
              confirmText: this.$t('labels.refresh'),
              title: this.$t('labels.newVersionAvailable')
            }, { 'content-class': 'new-version', 'hide-overlay': true, 'no-click-animation': true, persistent: true, width: 350 }).then(() => {
              location.reload(true);
            });
            this.newVersion = latestVersion;
            this.stopVersionCheck();
          }
        }).catch((e) => {
          // Ignore any errors intentionally
        });
      } catch (e) {
        // Ignore any errors intentionally
      }
    },

    onNotificationClicked (props) {
      this.$store.dispatch('notification/navigateToAssocContent', { ...props.item.data, callback: props.close });
    },

    onSidePanelTransitionEnd () {
      this.$store.commit('update_panel_open_status');
    },

    startVersionCheck () {
      if (!this.intervalId) {
        this.intervalId = setInterval(() => {
          this.checkForNewVersion();
        }, 10 * 60 * 1000);
      }
    },

    stopVersionCheck () {
      if (this.intervalId) {
        clearInterval(this.intervalId);
        this.intervalId = null;
      }
    }
  }
};
</script>

<style lang="scss" >
@import 'src/assets/sass/app.scss';

.new-version {
  bottom: 0px;
  left: 0px;
  position: fixed;
}
</style>
