import _ from 'lodash';
import moment from 'moment';
import Vue from 'vue';
import AccountServices from '@/services/account';
import RequestServices from '@/services/request';
import SchedulingServices from '@/services/scheduling';
import wsProxy from '@/services/ws_proxy';
import * as Sentry from '@sentry/vue';
import { validators as validatorClasses } from '@/views/scheduling/validators';
import { snakeCasePropertyNames, copyProperties, DATE_FORMAT, getAvatar, preparePayload, PersistentStorage } from '@/utils';
import { REQUEST_STATES } from '@/views/scheduling/constants';
import { convertRequests } from '@/utils/requests';
import { getEventScheduleIds, getScheduleId } from '@/utils/scheduling';
import { ACCOUNT_STATE } from '@/services/constants';

const schedulingServices = new SchedulingServices(wsProxy);
const accountServices = new AccountServices(wsProxy);
const requestServices = new RequestServices(wsProxy);

const SHIFT_MAP = {
  flexOn: 'available'
};

const SHIFT_MAP_RAW = {
  flex_on: 'available'
};

const eventResponseTemplate = {
  id: null,
  approvals: [],
  assigneeId: null,
  comments: '',
  createdBy: null,
  createdOn: '',
  dates: [],
  departmentId: null,
  endTime: '',
  modifiedBy: null,
  modifiedOn: '',
  startTime: '',
  state: '',
  title: '',
  typeId: null
};

const newEventPayloadTemplate = {
  'assignee_id': 'assigneeId',
  'dates': 'dates',
  'department_id': 'departmentId',
  'end_time': 'endTime',
  'start_time': 'startTime',
  'type_id': 'typeId'
};

const existingEventPayloadTemplate = {
  'assignee_id': 'assigneeId',
  'dates': 'dates',
  'department_id': 'departmentId',
  'end_time': 'endTime',
  'id': 'id',
  'start_time': 'startTime',
  'type_id': 'typeId'
};

const shiftResponseTemplate = {
  id: null,
  assigneeId: null,
  available: false,
  canceled: false,
  comments: '',
  createdBy: null,
  createdOn: '',
  date: '',
  departmentId: null,
  endTime: '',
  flags: [],
  giveaway: false,
  internalComments: '',
  modifiedBy: null,
  modifiedOn: '',
  newAssigneeId: null,
  obligatory: false,
  onCall: false,
  openShiftId: null,
  overtime: false,
  payrollDate: '',
  scheduleId: null,
  settings: {},
  sitter: false,
  startTime: '',
  swapped: false,
  typeId: null
};

const newShiftPayloadTemplate = {
  'assignee_id': 'assigneeId',
  'canceled': 'canceled',
  'comments': 'comments',
  'date': 'date',
  'department_id': 'departmentId',
  'end_time': 'endTime',
  'flags': 'flags',
  'flex_on': 'available',
  'giveaway': 'giveaway',
  'internal_comments': 'internalComments',
  'new_assignee_id': 'newAssigneeId',
  'obligatory': 'obligatory',
  'on_call': 'onCall',
  'overtime': 'overtime',
  'payroll_date': 'payrollDate',
  'settings': 'settings',
  'sitter': 'sitter',
  'start_time': 'startTime',
  'swapped': 'swapped',
  'type_id': 'typeId'
};

const existingShiftPayloadTemplate = {
  'assignee_id': 'assigneeId',
  'canceled': 'canceled',
  'comments': 'comments',
  'date': 'date',
  'department_id': 'departmentId',
  'end_time': 'endTime',
  'flags': 'flags',
  'flex_on': 'available',
  'giveaway': 'giveaway',
  'id': 'id',
  'internal_comments': 'internalComments',
  'new_assignee_id': 'newAssigneeId',
  'obligatory': 'obligatory',
  'on_call': 'onCall',
  'overtime': 'overtime',
  'payroll_date': 'payrollDate',
  'settings': 'settings',
  'sitter': 'sitter',
  'start_time': 'startTime',
  'swapped': 'swapped',
  'type_id': 'typeId'
};

const shiftRequestResponseTemplate = {
  id: null,
  approvals: [],
  assigneeId: null,
  comments: '',
  date: '',
  departmentId: null,
  endTime: '',
  flags: [],
  latestApproval: null,
  newAssigneeId: null,
  requestType: '',
  scheduleId: null,
  shift: null,
  shiftComments: '',
  shiftId: null,
  shiftInternalComments: '',
  splits: [],
  startTime: '',
  state: '',
  title: '',
  typeId: null,
  createdBy: null,
  createdOn: '',
  modifiedBy: null,
  modifiedOn: ''
};

const censusResponseTemplate = {
  acuity1: null,
  acuity2: null,
  acuity3: null,
  acuity4: null,
  acuity5: null,
  acuityStaff: null,
  censusBySpecialty: null,
  dayOffset: 0,
  description: '',
  extraStaff: [],
  id: null,
  name: '',
  notes: '',
  settings: null,
  staffCount: [],
  time: '',
  total: null,
  dailyScheduleId: null
};

const dailyScheduleResponseTemplate = {
  census: [],
  date: '',
  departmentId: null,
  id: null,
  memos: [],
  sharedById: null,
  sharedOn: null,
  state: '',
  typeId: null,
  createdBy: null,
  createdOn: '',
  modifiedBy: null,
  modifiedOn: ''
};

const newDailySchedulePayloadTemplate = {
  'date': 'date',
  'department_id': 'departmentId',
  'memos': 'memos',
  'state': 'state',
  'type_id': 'typeId'
};

const existingDailySchedulePayloadTemplate = {
  'date': 'date',
  'department_id': 'departmentId',
  'id': 'id',
  'memos': 'memos',
  'state': 'state',
  'type_id': 'typeId'
};

const swapResponseTemplate = {
  id: null,
  approvals: [],
  comments: '',
  createdOn: '',
  sourceDepartmentId: null,
  sourceScheduleId: null,
  sourceShiftComments: '',
  sourceShiftDate: '',
  sourceShiftEndTime: '',
  sourceShiftId: null,
  sourceShiftInternalComments: '',
  sourceShiftFlagIds: [],
  sourceShiftStartTime: '',
  sourceShiftTypeId: null,
  sourceUserId: null,
  state: '',
  targetDepartmentId: null,
  targetScheduleId: null,
  targetShiftComments: '',
  targetShiftDate: '',
  targetShiftEndTime: '',
  targetShiftId: null,
  targetShiftInternalComments: '',
  targetShiftFlagIds: [],
  targetShiftStartTime: '',
  targetShiftTypeId: null,
  targetUserId: null,
  title: ''
};

const openShiftResponseTemplate = {
  id: null,
  assignees: [],
  bidders: [],
  biddingClosed: false,
  biddingEnded: false,
  biddingEndsOn: '',
  comments: '',
  date: '',
  departmentId: null,
  eligibleDepartments: [],
  eligibleJobStatus: [],
  endTime: '',
  external: false,
  externalInfo: {},
  fcfs: false,
  flags: [],
  jobTypes: [],
  opening: 0,
  onCall: false,
  payrollDate: '',
  scheduleId: null,
  selectedBidders: [],
  selfScheduleOnly: false,
  settings: {},
  typeId: null,
  sitter: false,
  startTime: '',
  title: '',
  createdBy: null,
  createdOn: '',
  modifiedBy: null,
  modifiedOn: ''
};

function getRecords (store, scheduleId) {
  return store.state.scheduling.grids[scheduleId].records;
}

function getUserRecordMap (store, scheduleId) {
  return store.state.scheduling.grids[scheduleId].userRecordMap;
}

function prepScheduleData (store, schedule, userList, events, extraShifts) {
  const records = [];
  const userRecordMap = {};
  const users = {};
  const requests = {};
  requests[REQUEST_STATES.PENDING_DIRECTOR_APPROVAL] = {};
  requests[REQUEST_STATES.PENDING_OPERATOR_APPROVAL] = {};
  requests[REQUEST_STATES.PENDING_SCHEDULER_APPROVAL] = {};
  requests[REQUEST_STATES.PENDING_SUPERVISOR_APPROVAL] = {};

  const headers = [
    {
      field: 'user',
      caption: '',
      type: 'user'
    }
  ];
  let day = 1;
  let week = 1;
  let date = moment(schedule.startOn);
  const scheduleEndDate = moment(schedule.endOn);
  const scheduleStartTimestamp = date.valueOf();
  const scheduleEndTimestamp = scheduleEndDate.valueOf();
  // eslint-disable-next-line no-unmodified-loop-condition
  while (date <= scheduleEndDate) {
    // cheetah-grid does not have dependencies on momentjs, so we need to get the
    // Date object that is wrapped by momentjs to supply to cheetah-grid.
    let slotDate = date.toDate();
    // Every column in the grid represents one day with multiple shift slots
    // therefore we use the date of the column as the field as there should be
    // no duplicate dates. The date value should not have the time field.
    headers.push({
      field: slotDate.getTime(),
      caption: slotDate,
      type: 'schedule'
    });
    if (day === 7) {
      headers.push({
        field: `week${week}`,
        caption: week,
        end: date.clone(),
        start: date.clone().subtract(6, 'd'),
        type: 'week'
      });
      day = 0;
      week++;
    }
    day++;
    date.add(1, 'd');
  }

  let index = 0;
  userList.forEach(function (user) {
    let row = {
      user: {
        ...user,
        avatar: {
          bgColor: user.avatarBgColor,
          symbol: getAvatar(user)
        }
      }
    };

    userRecordMap[user.userId] = index;
    users[user.userId] = row.user;
    date = moment(schedule.startOn);
    // eslint-disable-next-line no-unmodified-loop-condition
    while (date <= scheduleEndDate) {
      const dateValue = date.valueOf();
      row[dateValue] = {
        activities: [],
        request: null
      };
      if (schedule.departmentId !== user.departmentId) {
        row[dateValue].exclude = true;
      }
      date.add(1, 'd');
    }

    records.push(row);
    index++;
  });

  const swaps = {};
  const approvedSwaps = [];
  if (schedule.swaps) {
    schedule.swaps.forEach(function (rawSwap) {
      const swap = _.cloneDeep(swapResponseTemplate);
      copyProperties(swap, rawSwap);
      const swapId = `${swap.sourceUserId}-${moment(swap.sourceShiftDate).valueOf()}`;
      if (!swaps[swapId] && _.indexOf([
        REQUEST_STATES.PENDING_DIRECTOR_APPROVAL, REQUEST_STATES.PENDING_OPERATOR_APPROVAL,
        REQUEST_STATES.PENDING_SCHEDULER_APPROVAL, REQUEST_STATES.PENDING_SUPERVISOR_APPROVAL
      ], swap.state) >= 0) {
        swaps[swapId] = { ...swap, type: 'swap' };
      } else if (swap.state === REQUEST_STATES.APPROVED) {
        approvedSwaps.push({ ...swap });
      }
      if (!requests[swap.state]) {
        requests[swap.state] = {};
      }
      requests[swap.state][`swap${swap.id}`] = { ...swap, type: 'swap' };
    });
  }
  const shiftRequests = {};
  const approvedShiftRequests = [];
  if (schedule.shiftRequests) {
    schedule.shiftRequests.forEach(function (rawShiftRequest) {
      const shiftRequest = _.cloneDeep(shiftRequestResponseTemplate);
      copyProperties(shiftRequest, rawShiftRequest);
      const shiftRequestId = `${shiftRequest.assigneeId}-${moment(shiftRequest.date).valueOf()}`;
      if (!shiftRequests[shiftRequestId] && _.indexOf([
        REQUEST_STATES.PENDING_DIRECTOR_APPROVAL, REQUEST_STATES.PENDING_OPERATOR_APPROVAL,
        REQUEST_STATES.PENDING_SCHEDULER_APPROVAL, REQUEST_STATES.PENDING_SUPERVISOR_APPROVAL
      ], shiftRequest.state) >= 0) {
        shiftRequests[shiftRequestId] = { ...shiftRequest, type: 'shift' };
      } else if (shiftRequest.state === REQUEST_STATES.APPROVED) {
        approvedShiftRequests.push({ ...shiftRequest });
      }
      if (!requests[shiftRequest.state]) {
        requests[shiftRequest.state] = {};
      }
      requests[shiftRequest.state][`shift${shiftRequest.id}`] = { ...shiftRequest, type: 'shift' };
    });
  }
  schedule.shifts.forEach(function (rawShift) {
    const shift = _.cloneDeep(shiftResponseTemplate);
    copyProperties(shift, rawShift, SHIFT_MAP);
    const shiftDate = moment(shift.date);
    let field = shiftDate.valueOf();
    if (shift.payrollDate && shift.payrollDate !== shift.date) {
      field = moment(shift.payrollDate).valueOf();
    }
    if (_.has(userRecordMap, shift.assigneeId) && records[userRecordMap[shift.assigneeId]][field]) {
      records[userRecordMap[shift.assigneeId]][field].activities.push({
        ...shift,
        action: '',
        date: shiftDate.toDate(),
        type: 'shift'
      });
      records[userRecordMap[shift.assigneeId]][field].activities = _.sortBy(records[userRecordMap[shift.assigneeId]][field].activities, [(a) => {
        return `${moment(a.date).format(DATE_FORMAT)} ${a.startTime}`;
      }]);
      if (schedule.departmentId !== records[userRecordMap[shift.assigneeId]].user.departmentId) {
        records[userRecordMap[shift.assigneeId]][field].exclude = false;
      }
    }
  });

  const extraRecords = [];
  const extraShiftsHandler = (rawShift) => {
    const shift = _.cloneDeep(shiftResponseTemplate);
    copyProperties(shift, rawShift, SHIFT_MAP);
    if (shift.scheduleId === schedule.id) {
      return;
    }
    const shiftDate = moment(shift.date);
    if (_.has(userRecordMap, shift.assigneeId)) {
      const row = userRecordMap[shift.assigneeId];
      let field = shiftDate.valueOf();
      if (shift.payrollDate && shift.payrollDate !== shift.date) {
        field = moment(shift.payrollDate).valueOf();
      }

      if (_.has(records, [row, field])) {
        records[row][field].activities.push({
          ...shift,
          action: '',
          date: shiftDate.toDate(),
          type: 'shift'
        });
      } else {
        if (!extraRecords[row]) {
          extraRecords[row] = {};
        }
        if (!extraRecords[row][field]) {
          extraRecords[row][field] = {
            activities: []
          };
        }
        extraRecords[row][field].activities.push({
          ...shift,
          action: '',
          date: shiftDate.toDate(),
          type: 'shift'
        });
      }
    }
  };
  extraShifts.previousScheduleShifts.forEach(extraShiftsHandler);
  extraShifts.nextScheduleShifts.forEach(extraShiftsHandler);

  const isDateInSchedule = (timestamp) => {
    return timestamp >= scheduleStartTimestamp && timestamp <= scheduleEndTimestamp;
  };

  // Add the events to the data. This assumes that a cell in the grid cannot have a shift
  // and event at the same time. For example, a user cannot have a shift and vacation event
  // at the same time.
  events.forEach(function (event) {
    const moments = event.dates.map(date => moment(date));

    const dates = moments.map(m => m.toDate());
    if (_.indexOf([REQUEST_STATES.EMPTY, REQUEST_STATES.APPROVED], event.state) >= 0) {
      for (let i = 0, count = moments.length; i < count; i++) {
        const field = moments[i].valueOf();
        if (isDateInSchedule(field)) {
          if (records[userRecordMap[event.assigneeId]]) {
            records[userRecordMap[event.assigneeId]][field].activities.push({
              ...event,
              action: '',
              dates,
              type: 'event'
            });
          }
        }
      }
    } else if (_.indexOf([
      REQUEST_STATES.PENDING_DIRECTOR_APPROVAL, REQUEST_STATES.PENDING_OPERATOR_APPROVAL,
      REQUEST_STATES.PENDING_SCHEDULER_APPROVAL, REQUEST_STATES.PENDING_SUPERVISOR_APPROVAL
    ], event.state) >= 0) {
      if (!requests[event.state]) {
        requests[event.state] = {};
      }
      requests[event.state][`event${event.id}`] = {
        ...event,
        dates,
        type: 'event'
      };
      for (let i = 0, count = moments.length; i < count; i++) {
        const field = moments[i].valueOf();
        if (isDateInSchedule(field)) {
          records[userRecordMap[event.assigneeId]][field]['request'] = requests[event.state][`event${event.id}`];
        }
      }
    }
  });

  for (let swapId in swaps) {
    const field = moment(swaps[swapId].sourceShiftDate).valueOf();
    const targetField = moment(swaps[swapId].targetShiftDate).valueOf();
    records[userRecordMap[swaps[swapId].sourceUserId]][field]['request'] = swaps[swapId];
    if (records[userRecordMap[swaps[swapId].targetUserId]] && records[userRecordMap[swaps[swapId].targetUserId]][targetField]) {
      records[userRecordMap[swaps[swapId].targetUserId]][targetField]['request'] = swaps[swapId];
    }
  }
  for (let shiftRequestId in shiftRequests) {
    const field = moment(shiftRequests[shiftRequestId].date).valueOf();
    records[userRecordMap[shiftRequests[shiftRequestId].assigneeId]][field]['request'] = shiftRequests[shiftRequestId];
  }

  return {
    grid: {
      extraRecords,
      headers,
      records,
      userRecordMap
    },
    users,
    requests
  };
}

function parseScheduleResponse (response) {
  const schedule = {
    id: null,
    comments: '',
    departmentId: null,
    startOn: '',
    endOn: '',
    state: '',
    settings: {},
    swaps: [],
    shifts: [],
    shiftRequests: []
  };
  copyProperties(schedule, response.data, {}, false);
  return schedule;
}

const storage = new PersistentStorage(true);
function initialState () {
  let dailyScheduleSort = {
    columns: [],
    order: []
  };

  try {
    const userId = AccountServices.loadUserId();
    const cachedDailyScheduleSort = storage.getItem(`daily-schedule-sort-${userId}`);
    if (cachedDailyScheduleSort) {
      dailyScheduleSort = {
        columns: [],
        order: [],
        ...JSON.parse(cachedDailyScheduleSort)
      };
    }
  } catch {
    // Ignore bad parsing
  }
  return {
    activeScheduleId: storage.getItem('schedule-id'),
    activeSnapshotId: storage.getItem('snapshot-id'),
    activeSnapshotPeriod: storage.getItem('snapshot-period'),
    activeRequest: null,
    dailySchedule: {
      date: null,
      sort: dailyScheduleSort,
      user: null
    },
    grids: {},
    panels: {
      dailySummary: {
        tab: 'summary'
      },
      errorsOverview: {
        tab: 'ImbalanceValidator'
      },
      openShiftList: {
        tab: 'open'
      }
    },
    requests: {},
    schedules: {},
    users: {},
    validations: {}
  };
}

let validators = {};

export default {
  namespaced: true,

  state: initialState,

  getters: {
    getErrorCount: state => (scheduleId, jobId, shiftId) => {
      let count = 0;
      const scheduleValidationStates = state.validations[scheduleId];
      const scheduleValidators = validators[scheduleId];
      if (scheduleValidationStates && scheduleValidators) {
        for (let validatorName in scheduleValidationStates) {
          // We let validators do their own error counting, but in order for a getter to trigger
          // state changes to listeners we must use state properties. Here we just create a variable
          // referencing the state of the validation, but we do not need use it.
          // eslint-disable-next-line no-unused-vars
          const intentionallyNotUsed = scheduleValidationStates[validatorName];
          count += scheduleValidators[validatorName].errorCount(jobId, shiftId);
        }
      }
      return count;
    },
    getErrorCountForValidator: state => (scheduleId, validator, jobId, shiftId) => {
      let count = 0;
      const scheduleValidationStates = state.validations[scheduleId];
      const scheduleValidators = validators[scheduleId];
      if (scheduleValidationStates && scheduleValidators) {
        if (scheduleValidationStates[validator]) {
          // We let validators do their own error counting, but in order for a getter to trigger
          // state changes to listeners we must use state properties. Here we just create a variable
          // referencing the state of the validation, but we do not need use it.
          // eslint-disable-next-line no-unused-vars
          const intentionallyNotUsed = scheduleValidationStates[validator];
          count = scheduleValidators[validator].errorCount(jobId, shiftId);
        }
      }
      return count;
    },
    getRequests: (state, getters, modules) => (scheduleId, jobType, shiftType) => {
      const allRequests = _.merge(
        {},
        _.get(state.requests, [scheduleId, REQUEST_STATES.PENDING_DIRECTOR_APPROVAL], {}),
        _.get(state.requests, [scheduleId, REQUEST_STATES.PENDING_OPERATOR_APPROVAL], {}),
        _.get(state.requests, [scheduleId, REQUEST_STATES.PENDING_SCHEDULER_APPROVAL], {}),
        _.get(state.requests, [scheduleId, REQUEST_STATES.PENDING_SUPERVISOR_APPROVAL], {})
      );

      if (!jobType && !shiftType) {
        return allRequests;
      }
      const filteredRequests = {};
      const employees = _.get(modules.org, ['employees'], {});
      for (let id in allRequests) {
        let user = null;
        switch (allRequests[id].type) {
          case 'event':
            user = employees[allRequests[id].assigneeId];
            break;
          case 'swap':
            user = employees[allRequests[id].sourceUserId];
            break;
        }
        if (user) {
          if (jobType && shiftType) {
            if (user.jobTypeId === jobType && user.shiftTypeId === shiftType) {
              filteredRequests[id] = allRequests[id];
            }
          } else if (jobType) {
            if (user.jobTypeId === jobType) {
              filteredRequests[id] = allRequests[id];
            }
          } else {
            if (user.shiftTypeId === shiftType) {
              filteredRequests[id] = allRequests[id];
            }
          }
        }
      }

      return filteredRequests;
    },
    getJobTypes: (state, getters, modules) => (scheduleId) => {
      const staffNeeded = _.cloneDeep(_.get(state.schedules, [scheduleId, 'settings', 'staffNeeded'], []));

      const jobTypesMap = _.get(modules.org, ['jobTypes'], []).reduce(function (accumulator, currentValue) {
        accumulator[currentValue.id] = currentValue;
        return accumulator;
      }, {});

      const jobTypes = [];
      for (let i = 0, count = staffNeeded.length; i < count; i++) {
        const associatedJobTypes = [];
        const descriptions = [];
        const names = [];
        for (let j = 0, typeCount = staffNeeded[i].jobTypes.length; j < typeCount; j++) {
          const jobTypeInfo = jobTypesMap[staffNeeded[i].jobTypes[j]];
          if (jobTypeInfo) {
            associatedJobTypes.push(jobTypeInfo.id);
            descriptions.push(jobTypeInfo.description);
            names.push(jobTypeInfo.name);
          }
        }

        jobTypes.push({
          description: descriptions.join(' / '),
          groupRight: _.get(staffNeeded[i], 'display.dailySchedule.groupRight', false),
          id: staffNeeded[i].jobTypes.join('|'),
          name: names.join(' / '),
          associatedJobTypes,
          associatedShiftTypes: _.keys(staffNeeded[i].shiftTypes).map(id => parseInt(id)),
          staffNeeded: staffNeeded[i].shiftTypes
        });
      }

      return jobTypes;
    },
    getJobTypesById: (state, getters) => (scheduleId) => {
      const jobTypesList = getters['getJobTypes'](scheduleId);
      const jobTypes = {};
      for (let i = 0, count = jobTypesList.length; i < count; i++) {
        jobTypes[jobTypesList[i].id] = jobTypesList[i];
      }
      return jobTypes;
    },
    getUser: state => (scheduleId, userId) => {
      return _.get(state.users, [scheduleId, userId], {});
    },
    getValidator: state => (scheduleId, name) => {
      if (name) {
        return validators[scheduleId] ? validators[scheduleId][name] : null;
      } else {
        const allValidators = validators[scheduleId] ? validators[scheduleId] : {};
        const filteredValidators = {};
        for (let name in allValidators) {
          if (allValidators[name].enabled) {
            filteredValidators[name] = allValidators[name];
          }
        }
        return filteredValidators;
      }
    }
  },

  mutations: {
    set_active_schedule_id (state, scheduleId) {
      state.activeScheduleId = scheduleId;
      storage.setItem('schedule-id', scheduleId);
    },
    set_active_snapshot (state, { snapshotId, snapshotPeriod }) {
      state.activeSnapshotId = snapshotId;
      storage.setItem('snapshot-id', snapshotId);
      storage.setItem('snapshot-period', snapshotPeriod);
    },
    set_active_request (state, request) {
      state.activeRequest = request;
    },
    set_daily_schedule_filters (state, fields) {
      const fieldNames = _.keys(fields);
      for (let i = 0, count = fieldNames.length; i < count; i++) {
        state.dailySchedule[fieldNames[i]] = fields[fieldNames[i]];
      }
    },
    set_daily_schedule_sort (state, data) {
      state.dailySchedule.sort[data.name] = data.value;
      storage.setItem(`daily-schedule-sort-${data.userId}`, JSON.stringify(state.dailySchedule.sort));
    },
    set_schedule (state, schedule) {
      Vue.set(state.schedules, schedule.id, schedule);
      this.commit('org/update_schedule_period', {
        departmentId: schedule.departmentId,
        scheduleId: schedule.id,
        scheduleState: schedule.state
      });
    },
    set_schedule_state (state, schedule) {
      Vue.set(state.schedules[schedule.id], 'state', schedule.state);
    },
    set_schedule_grid (state, data) {
      Vue.set(state.grids, data.scheduleId, data.grid);
      const scheduleValidators = validators[data.scheduleId];
      if (scheduleValidators) {
        for (let validator in scheduleValidators) {
          scheduleValidators[validator].validate();
        }
      }
    },
    set_schedule_requests (state, data) {
      Vue.set(state.requests, data.scheduleId, data.requests);
    },
    prepare_schedule_listeners (state, data) {
      const { scheduleId, includeValidators } = data;
      const validations = {};
      if (!validators[scheduleId]) {
        validators[scheduleId] = {};
        for (let validator in validatorClasses) {
          if (_.isArray(includeValidators)) {
            if (includeValidators.includes(validator)) {
              validators[scheduleId][validator] = new validatorClasses[validator](this, scheduleId);
              validations[validator] = _.cloneDeep(validators[scheduleId][validator].state);
            }
          } else {
            validators[scheduleId][validator] = new validatorClasses[validator](this, scheduleId);
            validations[validator] = _.cloneDeep(validators[scheduleId][validator].state);
          }
        }
        Vue.set(state.validations, scheduleId, validations);
      }
    },
    set_schedule_users (state, data) {
      Vue.set(state.users, data.scheduleId, data.users);
    },
    approve_event_request (state, data) {
      const scheduleId = state.activeScheduleId;
      if (scheduleId && state.grids[scheduleId]) {
        const { request, updatedShifts } = data;
        const dates = request.dates;
        const userRecordMap = getUserRecordMap(this, scheduleId);
        const records = getRecords(this, scheduleId);
        const row = userRecordMap[request.assigneeId];
        const value = { ...request };
        value.state = REQUEST_STATES.APPROVED;
        for (let i = 0, count = dates.length; i < count; i++) {
          const field = moment(dates[i]).valueOf();
          if (_.has(records[row], field)) {
            const activities = _.cloneDeep(_.get(records[row][field], 'activities', []));
            activities.push(_.cloneDeep(value));
            Vue.set(state.grids[scheduleId].records[row][field], 'activities', activities);
            Vue.set(state.grids[scheduleId].records[row][field], 'request', null);
          }
        }

        Vue.delete(state.requests[scheduleId][request.state], `${request.type}${request.id}`);

        for (let i = 0, count = updatedShifts.length; i < count; i++) {
          const field = moment(updatedShifts[i].date).valueOf();
          if (_.has(records[row], field)) {
            const activities = _.cloneDeep(_.get(records[row][field], 'activities', []));
            const activityIndex = _.findIndex(activities, (a) => a.type === 'shift' && a.id === updatedShifts[i].id);
            if (activityIndex >= 0) {
              activities[activityIndex].canceled = updatedShifts[i].canceled;
              activities[activityIndex].flags = updatedShifts[i].flags;
              Vue.set(state.grids[scheduleId].records[row][field], 'activities', activities);
            }
          }
        }
      }
    },
    approve_shift_request (state, request) {
      if (state.requests[request.scheduleId]) {
        const userRecordMap = getUserRecordMap(this, request.scheduleId);
        const field = moment(request.date).valueOf();
        const row = userRecordMap[request.assigneeId];
        Vue.delete(state.requests[request.scheduleId][request.state], `shift${request.id}`);
        Vue.set(state.grids[request.scheduleId].records[row][field], 'request', null);
      }
    },
    approve_swap_request (state, request) {
      if (state.requests[request.sourceScheduleId]) {
        const userRecordMap = getUserRecordMap(this, request.sourceScheduleId);
        const sourceField = moment(request.sourceShiftDate).valueOf();
        const sourceRow = userRecordMap[request.sourceUserId];
        Vue.delete(state.requests[request.sourceScheduleId][request.state], `${request.type}${request.id}`);
        Vue.set(state.grids[request.sourceScheduleId].records[sourceRow][sourceField], 'request', null);
        if (request.sourceScheduleId === request.targetScheduleId) {
          const targetField = moment(request.targetShiftDate).valueOf();
          const targetRow = userRecordMap[request.targetUserId];
          if (_.has(state.grids[request.targetScheduleId].records, [targetRow, targetField])) {
            Vue.set(state.grids[request.targetScheduleId].records[targetRow][targetField], 'request', null);
          }
        }
      }
    },
    reject_event_request (state, request) {
      const scheduleId = state.activeScheduleId;
      if (scheduleId && state.grids[scheduleId]) {
        const dates = request.dates;
        const userRecordMap = getUserRecordMap(this, scheduleId);
        const records = getRecords(this, scheduleId);
        const row = userRecordMap[request.assigneeId];
        for (let i = 0, count = dates.length; i < count; i++) {
          const field = moment(dates[i]).valueOf();
          if (_.has(records[row], field)) {
            Vue.set(state.grids[scheduleId].records[row][field], 'request', null);
          }
        }
        Vue.delete(state.requests[scheduleId][request.state], `${request.type}${request.id}`);
      }
    },
    reject_shift_request (state, request) {
      if (state.requests[request.scheduleId]) {
        const userRecordMap = getUserRecordMap(this, request.scheduleId);
        const field = moment(request.date).valueOf();
        const row = userRecordMap[request.assigneeId];
        Vue.delete(state.requests[request.scheduleId][request.state], `shift${request.id}`);
        Vue.set(state.grids[request.scheduleId].records[row][field], 'request', null);
      }
    },
    reject_swap_request (state, request) {
      if (state.requests[request.sourceScheduleId]) {
        const userRecordMap = getUserRecordMap(this, request.sourceScheduleId);
        const field = moment(request.sourceShiftDate).valueOf();
        const row = userRecordMap[request.sourceUserId];
        Vue.delete(state.requests[request.sourceScheduleId][request.state], `${request.type}${request.id}`);
        Vue.set(state.grids[request.sourceScheduleId].records[row][field], 'request', null);
        if (request.sourceScheduleId === request.targetScheduleId) {
          const targetField = moment(request.targetShiftDate).valueOf();
          const targetRow = userRecordMap[request.targetUserId];
          if (_.has(state.grids[request.targetScheduleId].records, [targetRow, targetField])) {
            Vue.set(state.grids[request.targetScheduleId].records[targetRow][targetField], 'request', null);
          }
        }
      }
    },
    update_event_request_state (state, data) {
      const scheduleId = state.activeScheduleId;
      if (scheduleId && state.grids[scheduleId]) {
        const { request, state: newRequestState } = data;
        const dates = request.dates;
        const userRecordMap = getUserRecordMap(this, scheduleId);
        const records = getRecords(this, scheduleId);
        const row = userRecordMap[request.assigneeId];
        for (let i = 0, count = dates.length; i < count; i++) {
          const field = moment(dates[i]).valueOf();
          if (_.has(records[row], field)) {
            Vue.set(state.grids[scheduleId].records[row][field].request, 'state', newRequestState);
          }
        }
        Vue.delete(state.requests[scheduleId][request.state], `${request.type}${request.id}`);
        Vue.set(state.requests[scheduleId][newRequestState], `${request.type}${request.id}`, { ...request, state: newRequestState });
      }
    },
    update_swap_request_state (state, data) {
      const { request, state: newRequestState } = data;
      if (state.requests[request.sourceScheduleId]) {
        const userRecordMap = getUserRecordMap(this, request.sourceScheduleId);
        const records = getRecords(this, request.sourceScheduleId);
        const field = moment(request.sourceShiftDate).valueOf();
        const row = userRecordMap[request.sourceUserId];
        const previousState = _.get(records, [row, field, 'request', 'state'], null);
        Vue.delete(state.requests[request.sourceScheduleId][request.state], `${request.type}${request.id}`);
        Vue.set(state.requests[request.sourceScheduleId][newRequestState], `${request.type}${request.id}`, { ...request, state: newRequestState });
        if (previousState && previousState !== newRequestState) {
          Vue.set(state.grids[request.sourceScheduleId].records[row][field].request, 'state', newRequestState);
        }
      }
    },
    add_shift (state, shift) {
      const scheduleId = getScheduleId(state, shift);
      if (state.grids[scheduleId]) {
        const field = moment(shift.payrollDate || shift.date).valueOf();
        const userRecordMap = state.grids[scheduleId].userRecordMap;
        let userIdx = userRecordMap[shift.assigneeId];
        let row = state.grids[scheduleId].records[userIdx];
        if (!row) {
          // A shift is floating in, need to add the user to the grid.
          const schedule = state.schedules[scheduleId];
          const user = this.state.org.employees[shift.assigneeId];
          row = {
            user: {
              ...user,
              avatar: {
                bgColor: user.avatarBgColor,
                symbol: getAvatar(user)
              }
            }
          };
          state.users[scheduleId][shift.assigneeId] = row.user;

          let date = moment(schedule.startOn);
          let scheduleEndDate = moment(schedule.endOn);
          // eslint-disable-next-line no-unmodified-loop-condition
          while (date <= scheduleEndDate) {
            const dateValue = date.valueOf();
            row[dateValue] = {
              activities: [],
              exclude: true,
              request: null
            };
            date.add(1, 'd');
          }

          state.grids[scheduleId].records.push(row);
          const jobTypeOrder = {};
          const staffNeeded = schedule.settings.staffNeeded;
          let count = 0;
          for (let i = 0, staffCount = staffNeeded.length; i < staffCount; i++) {
            for (let j = 0, jobCount = staffNeeded[i].jobTypes.length; j < jobCount; j++) {
              count++;
              jobTypeOrder[staffNeeded[i].jobTypes[j]] = count;
            }
          }
          state.grids[scheduleId].records = _.sortBy(state.grids[scheduleId].records, [
            function (r) {
              return _.has(jobTypeOrder, r.user.jobTypeId) ? jobTypeOrder[r.user.jobTypeId] : 1000;
            },
            function (r) {
              return r.user.fullName;
            }
          ]);

          for (let i = 0, len = state.grids[scheduleId].records.length; i < len; i++) {
            const tmpRow = state.grids[scheduleId].records[i];
            if (tmpRow.user.userId === shift.assigneeId) {
              userIdx = i;
            }
            state.grids[scheduleId].userRecordMap[tmpRow.user.userId] = i;
          }
        }
        if (_.has(row, [field])) {
          const activity = _.find(state.grids[scheduleId].records[userIdx][field].activities, (a) => a.type === 'shift' && a.id === shift.id);
          if (!activity) {
            state.grids[scheduleId].records[userIdx][field].exclude = false;
            state.grids[scheduleId].records[userIdx][field].activities.splice(0, 0, { ...shift, type: 'shift' });
            state.grids[scheduleId].records[userIdx][field].activities = _.sortBy(state.grids[scheduleId].records[userIdx][field].activities, [(a) => {
              return `${moment(a.date).format(DATE_FORMAT)} ${a.startTime}`;
            }]);
          }
        }
      }
    },
    add_shift_for_user_schedule (state, shift) {
      const scheduleId = 'user';
      if (state.grids[scheduleId]) {
        const field = moment(shift.payrollDate || shift.date).valueOf();
        const userRecordMap = state.grids[scheduleId].userRecordMap;
        let userIdx = userRecordMap[shift.assigneeId];
        let row = state.grids[scheduleId].records[userIdx];
        if (row && _.has(row, [field])) {
          const activity = _.find(state.grids[scheduleId].records[userIdx][field].activities, (a) => a.type === 'shift' && a.id === shift.id);
          if (!activity) {
            state.grids[scheduleId].records[userIdx][field].exclude = false;
            state.grids[scheduleId].records[userIdx][field].activities.splice(0, 0, { ...shift, type: 'shift' });
            state.grids[scheduleId].records[userIdx][field].activities = _.sortBy(state.grids[scheduleId].records[userIdx][field].activities, [(a) => {
              return `${moment(a.date).format(DATE_FORMAT)} ${a.startTime}`;
            }]);
          }
        }
      }
    },
    remove_shift (state, shift) {
      const scheduleId = getScheduleId(state, shift);
      if (state.grids[scheduleId]) {
        const field = moment(shift.payrollDate || shift.date).valueOf();
        const userRecordMap = state.grids[scheduleId].userRecordMap;
        const userIdx = userRecordMap[shift.assigneeId];
        const row = state.grids[scheduleId].records[userIdx];
        if (_.has(row, [field])) {
          const idx = _.findIndex(row[field].activities, (a) => {
            return a.id === shift.id && a.type === 'shift';
          });
          if (idx >= 0) {
            state.grids[scheduleId].records[userIdx][field].activities.splice(idx, 1);
          }
        }
      }
    },
    remove_shift_for_user_schedule (state, shift) {
      const scheduleId = 'user';
      if (state.grids[scheduleId]) {
        const field = moment(shift.payrollDate || shift.date).valueOf();
        const userRecordMap = state.grids[scheduleId].userRecordMap;
        const userIdx = userRecordMap[shift.assigneeId];
        const row = state.grids[scheduleId].records[userIdx];
        if (row && _.has(row, [field])) {
          const idx = _.findIndex(row[field].activities, (a) => {
            return a.id === shift.id && a.type === 'shift';
          });
          if (idx >= 0) {
            state.grids[scheduleId].records[userIdx][field].activities.splice(idx, 1);
          }
        }
      }
    },
    update_shift (state, data) {
      const { originalShift, shift } = data;
      const originalDate = moment(originalShift.payrollDate || originalShift.date);
      const newDate = moment(shift.payrollDate || shift.date);
      const field = originalDate.valueOf();
      const newField = newDate.valueOf();

      let scheduleId;
      if (originalDate.isSame(newDate)) {
        scheduleId = getScheduleId(state, shift);
        if (state.grids[scheduleId]) {
          const userRecordMap = state.grids[scheduleId].userRecordMap;
          let userIdx = userRecordMap[shift.assigneeId];
          let row = state.grids[scheduleId].records[userIdx];
          if (!row) {
            // A shift is floating in, need to add the user to the grid.
            const schedule = state.schedules[scheduleId];
            const user = this.state.org.employees[shift.assigneeId];
            row = {
              user: {
                ...user,
                avatar: {
                  bgColor: user.avatarBgColor,
                  symbol: getAvatar(user)
                }
              }
            };
            state.users[scheduleId][shift.assigneeId] = row.user;

            let date = moment(schedule.startOn);
            let scheduleEndDate = moment(schedule.endOn);
            // eslint-disable-next-line no-unmodified-loop-condition
            while (date <= scheduleEndDate) {
              const dateValue = date.valueOf();
              row[dateValue] = {
                activities: [],
                exclude: true,
                request: null
              };
              date.add(1, 'd');
            }

            state.grids[scheduleId].records.push(row);
            const jobTypeOrder = {};
            const staffNeeded = schedule.settings.staffNeeded;
            let count = 0;
            for (let i = 0, staffCount = staffNeeded.length; i < staffCount; i++) {
              for (let j = 0, jobCount = staffNeeded[i].jobTypes.length; j < jobCount; j++) {
                count++;
                jobTypeOrder[staffNeeded[i].jobTypes[j]] = count;
              }
            }
            state.grids[scheduleId].records = _.sortBy(state.grids[scheduleId].records, [
              function (r) {
                return _.has(jobTypeOrder, r.user.jobTypeId) ? jobTypeOrder[r.user.jobTypeId] : 1000;
              },
              function (r) {
                return r.user.fullName;
              }
            ]);

            for (let i = 0, len = state.grids[scheduleId].records.length; i < len; i++) {
              const tmpRow = state.grids[scheduleId].records[i];
              if (tmpRow.user.userId === shift.assigneeId) {
                userIdx = i;
              }
              state.grids[scheduleId].userRecordMap[tmpRow.user.userId] = i;
            }
          }
          if (_.has(row, [field])) {
            const idx = _.findIndex(row[field].activities, (a) => {
              return a.id === shift.id && a.type === 'shift';
            });
            if (idx >= 0) {
              state.grids[scheduleId].records[userIdx][field].activities.splice(idx, 1, { ...shift, type: 'shift' });
              state.grids[scheduleId].records[userIdx][field].activities = _.sortBy(state.grids[scheduleId].records[userIdx][field].activities, [(a) => {
                return `${moment(a.date).format(DATE_FORMAT)} ${a.startTime}`;
              }]);
            } else {
              state.grids[scheduleId].records[userIdx][field].exclude = false;
              state.grids[scheduleId].records[userIdx][field].activities.splice(0, 0, { ...shift, type: 'shift' });
              state.grids[scheduleId].records[userIdx][field].activities = _.sortBy(state.grids[scheduleId].records[userIdx][field].activities, [(a) => {
                return `${moment(a.date).format(DATE_FORMAT)} ${a.startTime}`;
              }]);
            }
          }
        }
      } else {
        scheduleId = getScheduleId(state, originalShift);
        if (state.grids[scheduleId]) {
          const userRecordMap = state.grids[scheduleId].userRecordMap;
          const userIdx = userRecordMap[originalShift.assigneeId];
          const row = state.grids[scheduleId].records[userIdx];
          if (_.has(row, [field])) {
            const idx = _.findIndex(row[field].activities, (a) => {
              return a.id === originalShift.id && a.type === 'shift';
            });
            if (idx >= 0) {
              state.grids[scheduleId].records[userIdx][field].activities.splice(idx, 1);
            }
          }
        }
        scheduleId = getScheduleId(state, shift);
        if (state.grids[scheduleId]) {
          const userRecordMap = state.grids[scheduleId].userRecordMap;
          const userIdx = userRecordMap[shift.assigneeId];
          const row = state.grids[scheduleId].records[userIdx];
          if (_.has(row, [newField])) {
            state.grids[scheduleId].records[userIdx][newField].activities.splice(0, 0, { ...shift, type: 'shift' });
            state.grids[scheduleId].records[userIdx][newField].activities = _.sortBy(state.grids[scheduleId].records[userIdx][newField].activities, [(a) => {
              return `${moment(a.date).format(DATE_FORMAT)} ${a.startTime}`;
            }]);
          }
        }
      }
    },
    update_shift_for_user_schedule (state, data) {
      const { originalShift, shift } = data;
      const originalDate = moment(originalShift.payrollDate || originalShift.date);
      const newDate = moment(shift.payrollDate || shift.date);
      const field = originalDate.valueOf();
      const newField = newDate.valueOf();

      const scheduleId = 'user';
      if (originalDate.isSame(newDate)) {
        if (state.grids[scheduleId]) {
          const userRecordMap = state.grids[scheduleId].userRecordMap;
          let userIdx = userRecordMap[shift.assigneeId];
          let row = state.grids[scheduleId].records[userIdx];
          if (row && _.has(row, [field])) {
            const idx = _.findIndex(row[field].activities, (a) => {
              return a.id === shift.id && a.type === 'shift';
            });
            if (idx >= 0) {
              state.grids[scheduleId].records[userIdx][field].activities.splice(idx, 1, { ...shift, type: 'shift' });
              state.grids[scheduleId].records[userIdx][field].activities = _.sortBy(state.grids[scheduleId].records[userIdx][field].activities, [(a) => {
                return `${moment(a.date).format(DATE_FORMAT)} ${a.startTime}`;
              }]);
            } else {
              state.grids[scheduleId].records[userIdx][field].exclude = false;
              state.grids[scheduleId].records[userIdx][field].activities.splice(0, 0, { ...shift, type: 'shift' });
              state.grids[scheduleId].records[userIdx][field].activities = _.sortBy(state.grids[scheduleId].records[userIdx][field].activities, [(a) => {
                return `${moment(a.date).format(DATE_FORMAT)} ${a.startTime}`;
              }]);
            }
          }
        }
      } else {
        if (state.grids[scheduleId]) {
          let userRecordMap = state.grids[scheduleId].userRecordMap;
          let userIdx = userRecordMap[originalShift.assigneeId];
          let row = state.grids[scheduleId].records[userIdx];
          if (row && _.has(row, [field])) {
            const idx = _.findIndex(row[field].activities, (a) => {
              return a.id === originalShift.id && a.type === 'shift';
            });
            if (idx >= 0) {
              state.grids[scheduleId].records[userIdx][field].activities.splice(idx, 1);
            }
          }

          userRecordMap = state.grids[scheduleId].userRecordMap;
          userIdx = userRecordMap[shift.assigneeId];
          row = state.grids[scheduleId].records[userIdx];
          if (row && _.has(row, [newField])) {
            state.grids[scheduleId].records[userIdx][newField].activities.splice(0, 0, { ...shift, type: 'shift' });
            state.grids[scheduleId].records[userIdx][newField].activities = _.sortBy(state.grids[scheduleId].records[userIdx][newField].activities, [(a) => {
              return `${moment(a.date).format(DATE_FORMAT)} ${a.startTime}`;
            }]);
          }
        }
      }
    },
    add_event (state, data) {
      const add = (scheduleId) => {
        if (state.grids[scheduleId]) {
          const moments = event.dates;
          const userRecordMap = state.grids[scheduleId].userRecordMap;
          const userIdx = userRecordMap[event.assigneeId];
          const row = state.grids[scheduleId].records[userIdx];
          const dates = moments.map(date => date.toDate());
          for (let i = 0, count = moments.length; i < count; i++) {
            const field = moments[i].valueOf();
            if (_.has(row, [field])) {
              state.grids[scheduleId].records[userIdx][field].activities.splice(0, 0, { ...event, dates, type: 'event' });
            }
          }
        }
      };
      const { event } = data;
      const eventScheduleIds = getEventScheduleIds(state, event);
      for (let id of eventScheduleIds) {
        add(id);
      }
    },
    remove_event (state, data) {
      const remove = (scheduleId) => {
        const userRecordMap = state.grids[scheduleId].userRecordMap;
        const userIdx = userRecordMap[event.assigneeId];
        const row = state.grids[scheduleId].records[userIdx];
        for (let i = 0, count = event.dates.length; i < count; i++) {
          const date = moment(event.dates[i]);
          const field = date.valueOf();
          if (_.has(row, [field])) {
            const idx = _.findIndex(row[field].activities, (a) => {
              return a.id === event.id && a.type === 'event';
            });
            if (idx >= 0) {
              state.grids[scheduleId].records[userIdx][field].activities.splice(idx, 1);
            }
          }
        }
      };
      const { event } = data;
      const eventScheduleIds = getEventScheduleIds(state, event);
      for (let id of eventScheduleIds) {
        remove(id);
      }
    },
    reset (state) {
      // Resets the store state for this module
      const s = initialState();
      for (let scheduleId in validators) {
        for (let name in validators[scheduleId]) {
          validators[scheduleId][name].dispose();
        }
      }
      validators = {};
      Object.keys(s).forEach(key => {
        state[key] = s[key];
      });
    },
    update_event (state, data) {
      const update = (scheduleId) => {
        if (state.grids[scheduleId]) {
          const userRecordMap = state.grids[scheduleId].userRecordMap;
          const userIdx = userRecordMap[event.assigneeId];
          const row = state.grids[scheduleId].records[userIdx];
          const originalDateStrings = originalEvent.dates.map(date => moment(date).format('YYYY-MM-DD'));
          const newDateStrings = event.dates.map(date => moment(date).format('YYYY-MM-DD'));

          const added = _.difference(newDateStrings, originalDateStrings);
          const removed = _.difference(originalDateStrings, newDateStrings);
          const updated = _.intersection(originalDateStrings, newDateStrings);

          const dates = newDateStrings.map(date => moment(date).toDate());
          for (let i = 0, count = added.length; i < count; i++) {
            const date = moment(added[i]);
            const field = date.valueOf();
            if (_.has(row, [field])) {
              state.grids[scheduleId].records[userIdx][field].activities.splice(0, 0, { ...event, dates, type: 'event' });
            }
          }
          for (let i = 0, count = updated.length; i < count; i++) {
            const date = moment(updated[i]);
            const field = date.valueOf();
            if (_.has(row, [field])) {
              const idx = _.findIndex(row[field].activities, (a) => {
                return a.id === event.id && a.type === 'event';
              });

              if (idx >= 0) {
                state.grids[scheduleId].records[userIdx][date.valueOf()].activities.splice(idx, 1, { ...event, dates, type: 'event' });
              }
            }
          }
          for (let i = 0, count = removed.length; i < count; i++) {
            const date = moment(removed[i]);
            const field = date.valueOf();
            if (_.has(row, [field])) {
              const idx = _.findIndex(row[field].activities, (a) => {
                return a.id === event.id && a.type === 'event';
              });
              if (idx >= 0) {
                state.grids[scheduleId].records[userIdx][field].activities.splice(idx, 1);
              }
            }
          }
        }
      };
      const { event, originalEvent } = data;
      const eventScheduleIds = _.uniq([...getEventScheduleIds(state, event), ...getEventScheduleIds(state, originalEvent)]);
      for (let id of eventScheduleIds) {
        update(id);
      }
    },
    update_panel (state, data) {
      state.panels[data.panel][data.prop] = data.value;
    },
    update_user_activities (state, data) {
      const { activities, scheduleId, userId } = data;
      if (state.grids[scheduleId]) {
        const changes = [];
        const userRecordMap = getUserRecordMap(this, scheduleId);
        for (let date in activities) {
          changes.push({
            col: moment(date).valueOf(),
            row: userRecordMap[userId],
            value: activities[date]
          });
        }

        for (let i = 0, len = changes.length; i < len; i++) {
          const { col, row, value } = changes[i];
          Vue.set(state.grids[scheduleId].records[row][col], 'activities', value);
        }
      }
    },
    update_user (state, data) {
      const { userId } = data;
      for (let scheduleId in state.grids) {
        if (_.has(state.grids[scheduleId].userRecordMap, userId)) {
          const row = state.grids[scheduleId].userRecordMap[userId];
          const user = _.cloneDeep(data);
          user.avatar = {
            bgColor: user.avatarBgColor,
            symbol: getAvatar(user)
          };
          Vue.set(state.grids[scheduleId].records[row], 'user', user);
          Vue.set(state.users[scheduleId], userId, user);
        }
      }
    },
    update_user_notes (state, data) {
      const { userId, scheduleNotes } = data;
      for (let scheduleId in state.grids) {
        if (_.has(state.grids[scheduleId].userRecordMap, userId)) {
          const row = state.grids[scheduleId].userRecordMap[userId];
          Vue.set(state.grids[scheduleId].records[row].user, 'scheduleNotes', scheduleNotes);
          Vue.set(state.users[scheduleId][userId], 'scheduleNotes', scheduleNotes);
        }
      }
    },
    update_validation_data (state, data) {
      if (data.name) {
        Vue.set(state.validations[data.scheduleId], data.name, _.cloneDeep(data.state));
      }
    }
  },
  actions: {
    approveEventRequest ({ commit, state, dispatch }, data) {
      const { request, comments, flags, internalComments } = data;
      const dates = request.dates;
      const sortedDates = dates.map(date => moment(date)).sort((a, b) => a.diff(b));
      const dateStrings = {};
      for (let i = 0, count = sortedDates.length; i < count; i++) {
        const dateString = sortedDates[i].format('YYYY-MM-DD');
        dateStrings[dateString] = dateString;
      }
      const datesCount = sortedDates.length;
      const shiftParams = {
        assignee_id: request.assigneeId,
        canceled: 'false',
        dept_id: request.departmentId,
        giveaway: 'false',
        flex_on: 'false',
        non_duty: 'false',
        swapped: 'false'
      };
      if (datesCount === 1) {
        shiftParams['on'] = sortedDates[0].format('YYYY-MM-DD');
      } else {
        shiftParams['between'] = [sortedDates[0].format('YYYY-MM-DD'), sortedDates[datesCount - 1].format('YYYY-MM-DD')].join(',');
      }

      return new Promise((resolve, reject) => {
        dispatch('retrieveShifts', shiftParams).then(shifts => {
          const shiftsToUpdate = [];
          for (let i = 0, count = shifts.length; i < count; i++) {
            if (dateStrings[shifts[i].date]) {
              shiftsToUpdate.push({
                id: shifts[i].id,
                canceled: true,
                comments: `${shifts[i].comments}\n${comments}`,
                flags: _.uniq([...flags, ...shifts[i].flags]),
                internalComments: `${shifts[i].internalComments}\n${internalComments}`
              });
            }
          }
          const event = {
            state: REQUEST_STATES.APPROVED
          };
          schedulingServices.updateEvent(request.id, event).then(response => {
            let updatedShifts = [];
            if (shiftsToUpdate.length > 0) {
              dispatch('updateShifts', shiftsToUpdate).then(shifts => {
                updatedShifts = shifts;
              }).catch(() => {}).finally(() => {
                commit('approve_event_request', {
                  request,
                  updatedShifts
                });
                resolve(response);
              });
            } else {
              commit('approve_event_request', {
                request,
                updatedShifts: []
              });
              resolve(response);
            }
          }).catch(error => {
            reject(error);
          });
        }).catch(error => {
          reject(error);
        });
      });
    },
    approveGiveawayRequest ({ commit, state, dispatch }, data) {
      const {
        comments,
        originalShiftComments,
        originalShiftFlags,
        originalShiftInternalComments,
        newShiftComments,
        newShiftFlags,
        newShiftInternalComments,
        request
      } = data;
      return new Promise((resolve, reject) => {
        const payload = {
          comments,
          original_shift_comments: originalShiftComments,
          original_shift_flags: originalShiftFlags,
          original_shift_internal_comments: originalShiftInternalComments,
          new_shift_comments: newShiftComments,
          new_shift_flags: newShiftFlags,
          new_shift_internal_comments: newShiftInternalComments,
          id: request.id
        };
        const originalShift = {
          ...request.shift,
          date: moment(request.shift.date).toDate(),
          type: 'shift'
        };
        requestServices.approveGiveawayRequest(payload).then(response => {
          const updatedShift = _.cloneDeep(shiftResponseTemplate);
          copyProperties(updatedShift, response.original_shift, SHIFT_MAP_RAW);
          const newShift = _.cloneDeep(shiftResponseTemplate);
          copyProperties(newShift, response.new_shift, SHIFT_MAP_RAW);
          commit('approve_shift_request', request);
          commit('update_shift', {
            originalShift,
            shift: {
              ...updatedShift,
              date: moment(updatedShift.date).toDate(),
              type: 'shift'
            }
          });
          commit('add_shift', {
            ...newShift,
            date: moment(newShift.date).toDate(),
            type: 'shift'
          });
          resolve(response);
        }).catch(error => {
          reject(error);
        });
      });
    },
    approveSplitRequest ({ commit, state, dispatch }, data) {
      return new Promise((resolve, reject) => {
        const {
          comments,
          splits,
          request
        } = data;
        const flags = {};
        const splitComments = {};
        const splitInternalComments = {};
        for (let i = 0, count = splits.length; i < count; i++) {
          flags[splits[i].id] = splits[i].flags;
          splitComments[splits[i].id] = splits[i].comments;
          splitInternalComments[splits[i].id] = splits[i].internalComments;
        }
        const payload = {
          id: request.id,
          comments,
          flags,
          split_comments: splitComments,
          split_internal_comments: splitInternalComments
        };
        resolve();

        const originalShift = {
          ...request.shift,
          date: moment(request.shift.date).toDate(),
          type: 'shift'
        };
        requestServices.approveSplitRequest(payload).then(response => {
          commit('approve_shift_request', request);

          const updatedShift = _.cloneDeep(shiftResponseTemplate);
          copyProperties(updatedShift, response.original_shift, SHIFT_MAP_RAW);
          commit('update_shift', {
            originalShift,
            shift: {
              ...updatedShift,
              date: moment(updatedShift.date).toDate(),
              type: 'shift'
            }
          });

          for (let userId in response.new_shifts) {
            for (let i = 0, len = response.new_shifts[userId].length; i < len; i++) {
              const shift = _.cloneDeep(shiftResponseTemplate);
              copyProperties(shift, response.new_shifts[userId][i], SHIFT_MAP_RAW);
              commit('add_shift', {
                ...shift,
                date: moment(shift.date).toDate(),
                type: 'shift'
              });
            }
          }

          resolve();
        }).catch(error => {
          reject(error);
        });
      });
    },
    approveSwapRequest ({ commit, state }, data) {
      const originalShifts = {
        [data.request.sourceShiftId]: {
          assigneeId: data.request.sourceUserId,
          date: moment(data.request.sourceShiftDate).toDate(),
          departmentId: data.request.sourceDepartmentId,
          id: data.request.sourceShiftId,
          type: 'shift'
        },
        [data.request.targetShiftId]: {
          assigneeId: data.request.targetUserId,
          date: moment(data.request.targetShiftDate).toDate(),
          departmentId: data.request.targetDepartmentId,
          id: data.request.targetShiftId,
          type: 'shift'
        }
      };
      return new Promise((resolve, reject) => {
        const payload = {
          id: data.id,
          comments: data.comments,
          new_comments: data.newComments,
          new_flags: data.newFlags,
          new_internal_comments: data.newInternalComments,
          original_comments: data.originalComments,
          original_flags: data.originalFlags,
          original_internal_comments: data.originalInternalComments
        };
        requestServices.approveSwapRequest(payload).then(response => {
          for (let s in response.original_shifts) {
            const shift = _.cloneDeep(shiftResponseTemplate);
            copyProperties(shift, response.original_shifts[s], SHIFT_MAP_RAW);
            commit('update_shift', {
              originalShift: originalShifts[shift.id],
              shift: {
                ...shift,
                date: moment(shift.date).toDate(),
                type: 'shift'
              }
            });
          }
          for (let s in response.new_shifts) {
            const shift = _.cloneDeep(shiftResponseTemplate);
            copyProperties(shift, response.new_shifts[s], SHIFT_MAP_RAW);
            commit('add_shift', {
              ...shift,
              date: moment(shift.date).toDate(),
              type: 'shift'
            });
          }
          commit('approve_swap_request', data.request);
          resolve();
        }).catch(error => {
          reject(error);
        });
      });
    },
    approveSchedule ({ commit, state }, data) {
      return new Promise((resolve, reject) => {
        schedulingServices.approveSchedule(data.id, data.state).then(response => {
          const schedule = parseScheduleResponse(response);
          commit('set_schedule', schedule);
          resolve(response);
        }).catch(error => {
          reject(error);
        });
      });
    },
    assignOpenShift (store, data) {
      return new Promise((resolve, reject) => {
        schedulingServices.assignOpenShift(data.id, data.selectedBidders, data.assignees).then((response) => {
          const openShift = _.cloneDeep(openShiftResponseTemplate);
          copyProperties(openShift, response.data);
          resolve(openShift);
        }).catch(error => {
          reject(error);
        });
      });
    },
    bidForOpenShift (store, { id, fcfs }) {
      return new Promise((resolve, reject) => {
        schedulingServices.bidForOpenShift(id).then(response => {
          let data = null;
          if (fcfs) {
            data = _.cloneDeep(shiftResponseTemplate);
          } else {
            data = _.cloneDeep(openShiftResponseTemplate);
          }
          copyProperties(data, response.data);
          resolve(data);
        }).catch(error => {
          reject(error);
        });
      });
    },
    cancelBidForOpenShift (store, id) {
      return new Promise((resolve, reject) => {
        schedulingServices.cancelBidForOpenShift(id).then(response => {
          const data = _.cloneDeep(openShiftResponseTemplate);
          copyProperties(data, response.data);
          resolve(data);
        }).catch(error => {
          reject(error);
        });
      });
    },
    closeOpenShift (store, id) {
      return new Promise((resolve, reject) => {
        schedulingServices.closeOpenShift(id).then((response) => {
          const openShift = _.cloneDeep(openShiftResponseTemplate);
          copyProperties(openShift, response.data);
          resolve(openShift);
        }).catch(error => {
          reject(error);
        });
      });
    },
    createEvents (store, events) {
      return new Promise((resolve, reject) => {
        const payload = _.map(events, (event) => {
          return preparePayload(newEventPayloadTemplate, event);
        });
        schedulingServices.createEvents(payload).then((response) => {
          const newEvents = [];
          for (let i = 0, count = response.data.length; i < count; i++) {
            const event = _.cloneDeep(eventResponseTemplate);
            copyProperties(event, response.data[i]);
            newEvents.push(event);
          }
          resolve(newEvents);
        }).catch(error => {
          reject(error);
        });
      });
    },
    createOpenShift (store, data) {
      return new Promise((resolve, reject) => {
        const payload = _.cloneDeep(data);
        snakeCasePropertyNames(payload);
        schedulingServices.createOpenShift(payload).then(response => {
          const openShift = _.cloneDeep(openShiftResponseTemplate);
          copyProperties(openShift, response.data);
          resolve(openShift);
        }).catch(error => {
          reject(error);
        });
      });
    },
    deleteEvents (store, events) {
      return new Promise((resolve, reject) => {
        schedulingServices.deleteEvents(events.map((eventId) => parseInt(eventId))).then(() => {
          resolve();
        }).catch(error => {
          reject(error);
        });
      });
    },
    deleteOpenShift (store, id) {
      return new Promise((resolve, reject) => {
        schedulingServices.deleteOpenShift(id).then(() => {
          resolve();
        }).catch(error => {
          reject(error);
        });
      });
    },
    nudgeOpenShift ({ commit }, id) {
      return new Promise((resolve, reject) => {
        schedulingServices.nudgeOpenShift(id).then(response => {
          const openShift = _.cloneDeep(openShiftResponseTemplate);
          copyProperties(openShift, response.data);
          resolve(openShift);
        }).catch(error => {
          reject(error);
        });
      });
    },
    rejectSchedule ({ commit, state }, data) {
      return new Promise((resolve, reject) => {
        schedulingServices.rejectSchedule(data.id, data.state, data.comments).then(response => {
          const schedule = parseScheduleResponse(response);
          commit('set_schedule', schedule);
          resolve(response);
        }).catch(error => {
          reject(error);
        });
      });
    },
    postSchedule ({ commit, state }, data) {
      const { scheduleId, needsApproval } = data;
      return new Promise((resolve, reject) => {
        schedulingServices.postSchedule(scheduleId, needsApproval).then(response => {
          const schedule = parseScheduleResponse(response);
          commit('set_schedule', schedule);
          resolve(response);
        }).catch(error => {
          reject(error);
        });
      });
    },
    postScheduleForSelfSchedule ({ commit, state }, scheduleId) {
      return new Promise((resolve, reject) => {
        schedulingServices.postScheduleForSelfSchedule(scheduleId).then(response => {
          const schedule = parseScheduleResponse(response);
          commit('set_schedule', schedule);
          resolve(response);
        }).catch(error => {
          reject(error);
        });
      });
    },
    publishSchedule ({ commit, state }, data) {
      const { scheduleId, needsApproval } = data;
      return new Promise((resolve, reject) => {
        schedulingServices.publishSchedule(scheduleId, needsApproval).then(response => {
          const schedule = parseScheduleResponse(response);
          commit('set_schedule', schedule);
          resolve(response);
        }).catch(error => {
          reject(error);
        });
      });
    },
    rejectEventRequest ({ commit }, data) {
      return new Promise((resolve, reject) => {
        const event = {
          state: REQUEST_STATES.REJECTED,
          approvals: [
            {
              comments: data.comment
            }
          ]
        };
        schedulingServices.updateEvent(data.request.id, event).then(response => {
          commit('reject_event_request', data.request);
          resolve(response);
        }).catch(error => {
          reject(error);
        });
      });
    },
    rejectShiftRequest ({ commit }, data) {
      return new Promise((resolve, reject) => {
        const request = {
          state: REQUEST_STATES.REJECTED,
          approvals: [
            {
              comments: data.comment
            }
          ]
        };
        schedulingServices.updateShiftRequest(data.request.id, request).then(response => {
          commit('reject_shift_request', data.request);
          resolve(response);
        }).catch(error => {
          reject(error);
        });
      });
    },
    rejectSwapRequest ({ commit }, data) {
      return new Promise((resolve, reject) => {
        const swap = {
          state: REQUEST_STATES.REJECTED,
          approvals: [
            {
              comments: data.comment
            }
          ]
        };
        schedulingServices.updateSwapRequest(data.request.id, swap).then(response => {
          commit('reject_swap_request', data.request);
          resolve(response);
        }).catch(error => {
          reject(error);
        });
      });
    },
    requestDirectorApprovalForEventRequest ({ commit }, request) {
      return new Promise((resolve, reject) => {
        const approvals = request.approvals;
        const approvalIndex = approvals.length - 1;
        const event = {
          state: REQUEST_STATES.PENDING_DIRECTOR_APPROVAL,
          approvals: [
            {
              id: approvals[approvalIndex].id,
              reviewer_id: this.getters['org/getActiveDepartment']().directorId
            }
          ]
        };
        schedulingServices.updateEvent(request.id, event).then(response => {
          commit('update_event_request_state', {
            request,
            state: REQUEST_STATES.PENDING_DIRECTOR_APPROVAL
          });
          resolve(response);
        }).catch(error => {
          reject(error);
        });
      });
    },
    requestDirectorApprovalForSwapRequest ({ commit }, request) {
      return new Promise((resolve, reject) => {
        const approvals = request.approvals;
        const approvalIndex = approvals.length - 1;
        const swap = {
          state: REQUEST_STATES.PENDING_DIRECTOR_APPROVAL,
          approvals: [
            {
              id: approvals[approvalIndex].id,
              reviewer_id: this.getters['org/getActiveDepartment']().directorId
            }
          ]
        };
        schedulingServices.updateSwapRequest(request.id, swap).then(response => {
          commit('update_swap_request_state', {
            request,
            state: REQUEST_STATES.PENDING_DIRECTOR_APPROVAL
          });
          resolve(response);
        }).catch(error => {
          reject(error);
        });
      });
    },
    retrieveAvailableFloatUsers ({ commit, dispatch }, criteria) {
      return new Promise((resolve, reject) => {
        schedulingServices.retrieveAvailableFloatUsers(criteria).then(response => {
          const users = response.data.results;
          for (let i = 0, len = users.length; i < len; i++) {
            const user = {
              id: null,
              shift: null
            };
            copyProperties(user, users[i]);
            if (user.shift) {
              user.shift.available = user.shift.flexOn;
              delete user.shift.flexOn;
            }
            users[i] = user;
          }
          if (response.data.next) {
            dispatch('retrieveAvailableFloatUsers', { ...criteria, page: response.data.next }).then(response => {
              resolve(users.concat(response));
            }).catch(error => {
              reject(error);
            });
          } else {
            resolve(users);
          }
        }).catch(error => {
          reject(error);
        });
      });
    },
    retrieveDailySchedule ({ commit, dispatch, rootGetters }, criteria) {
      return new Promise((resolve, reject) => {
        schedulingServices.retrieveDailySchedule(criteria.deptId, criteria.typeId, criteria.date).then((dailyScheduleResponse) => {
          let dailySchedule = null;
          if (dailyScheduleResponse.data.count > 0) {
            dailySchedule = _.cloneDeep(dailyScheduleResponseTemplate);
            copyProperties(dailySchedule, dailyScheduleResponse.data.results[0]);
          }
          resolve(dailySchedule);
        }).catch(error => {
          reject(error);
        });
      });
    },
    retrieveDailyScheduleData ({ commit, dispatch, rootGetters }, criteria) {
      return new Promise((resolve, reject) => {
        const startOn = moment(criteria.date).startOf('week').subtract(1, 'w').format(DATE_FORMAT);
        const endOn = moment(criteria.date).endOf('week').add(1, 'w').format(DATE_FORMAT);
        const shiftsCriteria = {
          dept_id: criteria.deptId,
          between: [startOn, endOn].join(',')
        };
        const floatOutShiftsCriteria = {
          float_out_dept_id: criteria.deptId,
          float_with_flag: 'true',
          between: [startOn, endOn].join(',')
        };
        const eventParams = {
          on: criteria.date
        };

        Promise.all([
          dispatch('retrieveScheduleByDate', { departmentId: criteria.deptId, date: criteria.date }),
          dispatch('retrieveEvents', { deptId: criteria.deptId, params: eventParams }),
          dispatch('retrieveShifts', shiftsCriteria),
          dispatch('retrieveShifts', floatOutShiftsCriteria),
          dispatch('retrievePendingRequests', {
            dept_id: criteria.deptId,
            intersect: [criteria.date, criteria.date].join(',')
          })
        ]).then(response => {
          const scheduleResponse = response[0];
          const events = response[1];
          const shiftsResponse = [...response[2], ...response[3]];
          const pendingRequestsResponse = response[4];

          let schedule;
          if (scheduleResponse) {
            schedule = parseScheduleResponse({ data: scheduleResponse });
            schedule.startOn = startOn;
            schedule.endOn = endOn;
          } else {
            const dept = rootGetters['org/getDepartmentById'](criteria.deptId);
            schedule = {
              id: null,
              comments: '',
              departmentId: criteria.deptId,
              startOn,
              endOn,
              state: '',
              settings: {
                staffNeeded: _.get(dept, 'settings.staffNeeded', [])
              },
              swaps: [],
              shifts: [],
              shiftRequests: []
            };
          }

          schedule.shifts = shiftsResponse;
          for (let i = 0, len = pendingRequestsResponse.length; i < len; i++) {
            const request = pendingRequestsResponse[i].assocContent;
            switch (request.contentType) {
              case 'event':
                break;
              case 'swap':
                schedule.swaps.push(request);
                break;
              default:
                schedule.shiftRequests.push(request);
            }
          }

          const employees = this.getters['org/getEmployeesByDepartment'](criteria.deptId, [ACCOUNT_STATE.active, ACCOUNT_STATE.inactive]);
          for (let i = 0, count = schedule.shifts.length; i < count; i++) {
            const assigneeId = schedule.shifts[i].assigneeId;
            const assignee = _.get(this.state.org.employees, [assigneeId], null);
            if (assignee && !(assigneeId in employees)) {
              employees[assigneeId] = assignee;
            }
          }

          for (let i = 0, count = events.length; i < count; i++) {
            const assigneeId = events[i].assigneeId;
            const assignee = _.get(this.state.org.employees, [assigneeId], null);
            if (assignee && !(assigneeId in employees)) {
              employees[assigneeId] = assignee;
            }
          }

          const scheduleData = prepScheduleData(this, schedule, _.values(employees), events, {
            previousScheduleShifts: [],
            nextScheduleShifts: []
          });

          commit('set_schedule', schedule);
          commit('set_schedule_grid', {
            scheduleId: schedule.id,
            grid: scheduleData.grid
          });
          commit('set_schedule_requests', {
            scheduleId: schedule.id,
            requests: scheduleData.requests
          });
          commit('set_schedule_users', {
            scheduleId: schedule.id,
            users: scheduleData.users
          });
          commit('prepare_schedule_listeners', { scheduleId: schedule.id });

          resolve({ schedule: { id: schedule.id, state: schedule.state } });
        }).catch(error => {
          Sentry.captureException(error);
          reject(error);
        });
      });
    },
    retrieveDailySchedules ({ commit, dispatch }, criteria) {
      return new Promise((resolve, reject) => {
        schedulingServices.retrieveDailySchedules(criteria).then(response => {
          const schedules = response.data.results;
          for (let i = 0, len = schedules.length; i < len; i++) {
            const props = _.cloneDeep(dailyScheduleResponseTemplate);
            copyProperties(props, schedules[i]);
            schedules[i] = props;
          }
          if (response.data.next) {
            dispatch('retrieveDailySchedules', { ...criteria, page: response.data.next }).then(response => {
              resolve(schedules.concat(response));
            }).catch(error => {
              reject(error);
            });
          } else {
            resolve(schedules);
          }
        }).catch(error => {
          reject(error);
        });
      });
    },
    retrieveEvent ({ commit, dispatch }, eventId) {
      return new Promise((resolve, reject) => {
        Promise.all([
          schedulingServices.retrieveEvent(eventId),
          dispatch('retrieveEventErrors', eventId)
        ]).then(responses => {
          const event = _.cloneDeep(eventResponseTemplate);
          copyProperties(event, responses[0].data);
          event.errors = responses[1];
          resolve(event);
        }).catch(error => {
          reject(error);
        });
      });
    },
    retrieveEventErrors ({ commit, dispatch }, eventId) {
      return new Promise((resolve, reject) => {
        schedulingServices.retrieveEventErrors(eventId).then(response => {
          resolve(response.data.errors);
        }).catch(error => {
          reject(error);
        });
      });
    },
    retrieveEvents ({ commit, dispatch }, criteria) {
      return new Promise((resolve, reject) => {
        schedulingServices.retrieveEvents(criteria.deptId, { ...criteria.params, page_size: 500 }).then(response => {
          const events = response.data.results;
          for (let i = 0, len = events.length; i < len; i++) {
            const props = _.cloneDeep(eventResponseTemplate);
            copyProperties(props, events[i]);
            events[i] = props;
          }
          if (response.data.next) {
            dispatch('retrieveEvents', { ...criteria, params: { page: response.data.next } }).then(response => {
              resolve(events.concat(response));
            }).catch(error => {
              reject(error);
            });
          } else {
            resolve(events);
          }
        }).catch(error => {
          reject(error);
        });
      });
    },
    retrieveOpenShift (store, data) {
      return new Promise((resolve, reject) => {
        schedulingServices.retrieveOpenShift(data.id, data.source).then((response) => {
          const openShift = _.cloneDeep(openShiftResponseTemplate);
          copyProperties(openShift, response.data);
          resolve(openShift);
        }).catch(error => {
          reject(error);
        });
      });
    },
    retrieveOpenShifts ({ commit, dispatch }, queryParams) {
      return new Promise((resolve, reject) => {
        schedulingServices.retrieveOpenShifts(queryParams).then(response => {
          const openShifts = response.data.results;
          for (let i = 0, len = openShifts.length; i < len; i++) {
            const props = _.cloneDeep(openShiftResponseTemplate);
            copyProperties(props, openShifts[i]);
            openShifts[i] = props;
          }
          if (response.data.next) {
            dispatch('retrieveOpenShifts', { ...queryParams, page: response.data.next }).then(response => {
              resolve(openShifts.concat(response));
            }).catch(error => {
              reject(error);
            });
          } else {
            resolve(openShifts);
          }
        }).catch(error => {
          reject(error);
        });
      });
    },
    retrieveOpenShiftsCount (store, queryParams) {
      return new Promise((resolve, reject) => {
        schedulingServices.retrieveOpenShifts(queryParams, true).then(response => {
          resolve(response.data.count);
        }).catch(error => {
          reject(error);
        });
      });
    },
    retrieveSchedule ({ commit, dispatch, state }, criteria) {
      return new Promise((resolve, reject) => {
        const eventParams = {
          'intersect': [criteria.scheduleStartDate, criteria.scheduleEndDate].join(',')
        };
        const previousScheduleShifts = {
          between: [
            moment(criteria.scheduleStartDate).subtract(7, 'd').format(DATE_FORMAT),
            moment(criteria.scheduleStartDate).subtract(1, 'd').format(DATE_FORMAT)
          ].join(','),
          dept_id: criteria.deptId,
          omit: 'approvals,split'
        };
        const nextScheduleShifts = {
          between: [
            moment(criteria.scheduleEndDate).add(1, 'd').format(DATE_FORMAT),
            moment(criteria.scheduleEndDate).add(7, 'd').format(DATE_FORMAT)
          ].join(','),
          dept_id: criteria.deptId,
          omit: 'approvals,split'
        };
        const floatOutShifts = {
          between: [criteria.scheduleStartDate, criteria.scheduleEndDate].join(','),
          float_out_dept_id: criteria.deptId,
          omit: 'approvals,split'
        };
        const scheduleShifts = {
          schedule_id: criteria.scheduleId
        };
        const omit = ['shifts'];
        Promise.all([
          schedulingServices.retrieveScheduleById(criteria.scheduleId, omit),
          dispatch('retrieveEvents', { deptId: criteria.deptId, params: eventParams }),
          dispatch('retrieveShifts', previousScheduleShifts),
          dispatch('retrieveShifts', nextScheduleShifts),
          dispatch('retrieveShifts', floatOutShifts),
          dispatch('retrieveShifts', scheduleShifts)
        ]).then(responses => {
          const schedule = parseScheduleResponse(responses[0]);
          const events = responses[1];
          const jobTypeOrder = {};
          const staffNeeded = schedule.settings.staffNeeded;
          let count = 0;
          for (let i = 0, staffCount = staffNeeded.length; i < staffCount; i++) {
            for (let j = 0, jobCount = staffNeeded[i].jobTypes.length; j < jobCount; j++) {
              count++;
              jobTypeOrder[staffNeeded[i].jobTypes[j]] = count;
            }
          }
          schedule.shifts = responses[5];
          schedule.shifts.push(...responses[4]);
          const employees = this.getters['org/getEmployeesByDepartment'](criteria.deptId, [ACCOUNT_STATE.active, ACCOUNT_STATE.inactive]);
          for (let i = 0, count = schedule.shifts.length; i < count; i++) {
            const assigneeId = schedule.shifts[i].assigneeId;
            const assignee = _.get(this.state.org.employees, [assigneeId], null);
            if (assignee && !(assigneeId in employees)) {
              employees[assigneeId] = assignee;
            }
          }

          for (let i = 0, count = events.length; i < count; i++) {
            const assigneeId = events[i].assigneeId;
            const assignee = _.get(this.state.org.employees, [assigneeId], null);
            if (assignee && !(assigneeId in employees)) {
              employees[assigneeId] = assignee;
            }
          }

          const users = _.sortBy(_.values(employees), [
            function (u) {
              return _.has(jobTypeOrder, u.jobTypeId) ? jobTypeOrder[u.jobTypeId] : 1000;
            },
            function (u) {
              return u.fullName;
            }
          ]);
          const scheduleData = prepScheduleData(this, schedule, users, events, {
            previousScheduleShifts: responses[2],
            nextScheduleShifts: responses[3]
          });
          commit('set_schedule', schedule);
          commit('set_schedule_grid', {
            scheduleId: schedule.id,
            grid: scheduleData.grid
          });
          commit('set_schedule_requests', {
            scheduleId: schedule.id,
            requests: scheduleData.requests
          });
          commit('set_schedule_users', {
            scheduleId: schedule.id,
            users: scheduleData.users
          });
          commit('prepare_schedule_listeners', criteria);
          resolve(schedule.id);
        }).catch(error => {
          Sentry.captureException(error);
          reject(error);
        });
      });
    },
    retrieveUserSchedule ({ commit, dispatch, state }, criteria) {
      return new Promise((resolve, reject) => {
        const eventParams = {
          assignee_id: criteria.assigneeId,
          intersect: [criteria.scheduleStartDate, criteria.scheduleEndDate].join(',')
        };
        const previousScheduleShiftsFilter = {
          between: [
            moment(criteria.scheduleStartDate).subtract(7, 'd').format(DATE_FORMAT),
            moment(criteria.scheduleStartDate).subtract(1, 'd').format(DATE_FORMAT)
          ].join(','),
          include_assignee_ids: criteria.assigneeId,
          omit: 'approvals,split'
        };
        const nextScheduleShiftsFilter = {
          between: [
            moment(criteria.scheduleEndDate).add(1, 'd').format(DATE_FORMAT),
            moment(criteria.scheduleEndDate).add(7, 'd').format(DATE_FORMAT)
          ].join(','),
          include_assignee_ids: criteria.assigneeId,
          omit: 'approvals,split'
        };
        const shiftsFilter = {
          between: [
            moment(criteria.scheduleStartDate).format(DATE_FORMAT),
            moment(criteria.scheduleEndDate).format(DATE_FORMAT)
          ].join(','),
          include_assignee_ids: criteria.assigneeId,
          omit: 'approvals,split'
        };
        Promise.all([
          dispatch('retrieveEvents', { params: eventParams }),
          dispatch('retrieveShifts', previousScheduleShiftsFilter),
          dispatch('retrieveShifts', nextScheduleShiftsFilter),
          dispatch('retrieveShifts', shiftsFilter)
        ]).then(responses => {
          const employee = this.state.org.employees[criteria.assigneeId];
          const schedule = {
            id: 'user',
            comments: '',
            departmentId: null,
            startOn: criteria.scheduleStartDate,
            endOn: criteria.scheduleEndDate,
            state: '',
            settings: {},
            swaps: [],
            shifts: responses[3],
            shiftRequests: []
          };
          const events = responses[0];
          const scheduleData = prepScheduleData(this, schedule, [employee], events, {
            previousScheduleShifts: responses[1],
            nextScheduleShifts: responses[2]
          });
          commit('set_schedule', schedule);
          commit('set_schedule_grid', {
            scheduleId: schedule.id,
            grid: scheduleData.grid
          });
          commit('set_schedule_requests', {
            scheduleId: schedule.id,
            requests: scheduleData.requests
          });
          commit('set_schedule_users', {
            scheduleId: schedule.id,
            users: scheduleData.users
          });
          commit('prepare_schedule_listeners', {
            scheduleId: schedule.id,
            includeValidators: ['TimeConflictValidator', 'OvertimeValidator', 'ConsecutiveShiftsValidator']
          });
          resolve(schedule.id);
        }).catch(error => {
          Sentry.captureException(error);
          reject(error);
        });
      });
    },
    retrievePseudoSchedule ({ commit, dispatch, rootGetters }, criteria) {
      return new Promise((resolve, reject) => {
        const startOn = moment(criteria.scheduleStartDate).format(DATE_FORMAT);
        const endOn = moment(criteria.scheduleEndDate).format(DATE_FORMAT);
        const shiftsCriteria = {
          dept_id: criteria.deptId,
          between: [startOn, endOn].join(',')
        };
        const floatOutShiftsCriteria = {
          float_out_dept_id: criteria.deptId,
          between: [startOn, endOn].join(',')
        };
        const eventParams = {
          'intersect': [criteria.scheduleStartDate, criteria.scheduleEndDate].join(',')
        };

        Promise.all([
          dispatch('retrieveScheduleByDate', { departmentId: criteria.deptId, date: criteria.scheduleStartDate }),
          dispatch('retrieveEvents', { deptId: criteria.deptId, params: eventParams }),
          dispatch('retrieveShifts', shiftsCriteria),
          dispatch('retrieveShifts', floatOutShiftsCriteria),
          dispatch('retrievePendingRequests', {
            dept_id: criteria.deptId,
            intersect: [criteria.date, criteria.date].join(',')
          })
        ]).then(response => {
          const scheduleResponse = response[0];
          const events = response[1];
          const shiftsResponse = [...response[2], ...response[3]];
          const pendingRequestsResponse = response[4];

          let schedule;
          if (scheduleResponse) {
            schedule = parseScheduleResponse({ data: scheduleResponse });
            schedule.startOn = startOn;
            schedule.endOn = endOn;
          } else {
            const dept = rootGetters['org/getDepartmentById'](criteria.deptId);
            schedule = {
              id: null,
              comments: '',
              departmentId: criteria.deptId,
              startOn,
              endOn,
              state: '',
              settings: {
                staffNeeded: _.get(dept, 'settings.staffNeeded', [])
              },
              swaps: [],
              shifts: [],
              shiftRequests: []
            };
          }

          schedule.shifts = shiftsResponse;
          for (let i = 0, len = pendingRequestsResponse.length; i < len; i++) {
            const request = pendingRequestsResponse[i].assocContent;
            switch (request.contentType) {
              case 'event':
                break;
              case 'swap':
                schedule.swaps.push(request);
                break;
              default:
                schedule.shiftRequests.push(request);
            }
          }

          const employees = this.getters['org/getEmployeesByDepartment'](criteria.deptId, [ACCOUNT_STATE.active, ACCOUNT_STATE.inactive]);
          for (let i = 0, count = schedule.shifts.length; i < count; i++) {
            const assigneeId = schedule.shifts[i].assigneeId;
            const assignee = _.get(this.state.org.employees, [assigneeId], null);
            if (assignee && !(assigneeId in employees)) {
              employees[assigneeId] = assignee;
            }
          }

          for (let i = 0, count = events.length; i < count; i++) {
            const assigneeId = events[i].assigneeId;
            const assignee = _.get(this.state.org.employees, [assigneeId], null);
            if (assignee && !(assigneeId in employees)) {
              employees[assigneeId] = assignee;
            }
          }

          const scheduleData = prepScheduleData(this, schedule, _.values(employees), events, {
            previousScheduleShifts: [],
            nextScheduleShifts: []
          });

          commit('set_schedule', schedule);
          commit('set_schedule_grid', {
            scheduleId: schedule.id,
            grid: scheduleData.grid
          });
          commit('set_schedule_requests', {
            scheduleId: schedule.id,
            requests: scheduleData.requests
          });
          commit('set_schedule_users', {
            scheduleId: schedule.id,
            users: scheduleData.users
          });
          commit('prepare_schedule_listeners', { scheduleId: schedule.id });
          resolve(schedule.id);
        }).catch(error => {
          Sentry.captureException(error);
          reject(error);
        });
      });
    },
    retrieveScheduleSnapshot ({ commit, dispatch, state }, snapshotId) {
      return new Promise((resolve, reject) => {
        const parsedId = snapshotId.split(':');
        schedulingServices.retrieveScheduleSnapshot(parsedId[1]).then(response => {
          const props = {
            'id': null,
            'endOn': '',
            'schedule': null,
            'shifts': {},
            'events': {},
            'startOn': '',
            'state': '',
            'createdBy': null,
            'createdOn': '',
            'modifiedBy': null,
            'modifiedOn': ''
          };
          copyProperties(props, response.data);
          const snapshot = {
            ...props.schedule,
            ...props,
            id: `snapshot:${props.id}`,
            swaps: [],
            shifts: _.values(props.shifts),
            snapshot: true
          };
          const jobTypeOrder = {};
          const staffNeeded = snapshot.schedule.settings.staffNeeded;
          let count = 0;
          for (let i = 0, staffCount = staffNeeded.length; i < staffCount; i++) {
            for (let j = 0, jobCount = staffNeeded[i].jobTypes.length; j < jobCount; j++) {
              count++;
              jobTypeOrder[staffNeeded[i].jobTypes[j]] = count;
            }
          }

          const employees = {};
          for (let i = 0, count = snapshot.shifts.length; i < count; i++) {
            const assigneeId = snapshot.shifts[i].assigneeId;
            const assignee = _.get(this.state.org.employees, [assigneeId], null);
            if (assignee && !(assigneeId in employees)) {
              employees[assigneeId] = assignee;
            }
          }
          const events = _.values(snapshot.events);
          for (let i = 0, count = events.length; i < count; i++) {
            const assigneeId = events[i].assigneeId;
            const assignee = _.get(this.state.org.employees, [assigneeId], null);
            if (assignee && !(assigneeId in employees)) {
              employees[assigneeId] = assignee;
            }
          }

          const users = _.sortBy(employees, [
            function (u) {
              return _.has(jobTypeOrder, u.jobTypeId) ? jobTypeOrder[u.jobTypeId] : 1000;
            },
            function (u) {
              return u.fullName;
            }
          ]);
          const scheduleData = prepScheduleData(this, snapshot, users, events, {
            previousScheduleShifts: [],
            nextScheduleShifts: []
          });

          commit('set_schedule', snapshot);
          commit('set_schedule_grid', {
            scheduleId: snapshot.id,
            grid: scheduleData.grid
          });
          commit('set_schedule_requests', {
            scheduleId: snapshot.id,
            requests: scheduleData.requests
          });
          commit('set_schedule_users', {
            scheduleId: snapshot.id,
            users: scheduleData.users
          });
          commit('prepare_schedule_listeners', { scheduleId: snapshot.id, deptId: snapshot.schedule.departmentId });
          resolve(snapshot.id);
        }).catch(error => {
          Sentry.captureException(error);
          reject(error);
        });
      });
    },
    retrieveScheduleSnapshotsByDepartment ({ commit, dispatch, state }, { deptId, date }) {
      return new Promise((resolve, reject) => {
        schedulingServices.listScheduleSnapshotsByDepartment(deptId, date).then(response => {
          const snapshots = response.data.results;
          for (let i = 0, len = snapshots.length; i < len; i++) {
            const snapshot = {
              'id': null,
              'endOn': '',
              'schedule': null,
              'startOn': '',
              'state': '',
              'createdBy': null,
              'createdOn': '',
              'modifiedBy': null,
              'modifiedOn': ''
            };
            copyProperties(snapshot, snapshots[i]);
            snapshot.id = `snapshot:${snapshot.id}`;
            snapshots[i] = snapshot;
          }
          resolve(snapshots);
        }).catch(error => {
          Sentry.captureException(error);
          reject(error);
        });
      });
    },
    retrieveScheduleByDate ({ commit, dispatch, state }, { departmentId, date }) {
      return new Promise((resolve, reject) => {
        const omit = ['shifts', 'swaps', 'shift_requests'];
        schedulingServices.retrieveScheduleByDate(departmentId, date, omit).then(response => {
          if (response.data.count > 0) {
            resolve(parseScheduleResponse({ data: response.data.results[0] }));
          } else {
            resolve(null);
          }
        }).catch(error => {
          Sentry.captureException(error);
          reject(error);
        });
      });
    },
    retrieveScheduleById ({ commit, dispatch, state }, scheduleId) {
      return new Promise((resolve, reject) => {
        const omit = ['shifts', 'swaps', 'shift_requests'];
        schedulingServices.retrieveScheduleById(scheduleId, omit).then(response => {
          resolve(parseScheduleResponse(response));
        }).catch(error => {
          Sentry.captureException(error);
          reject(error);
        });
      });
    },
    retrieveCensus ({ commit, dispatch }, criteria) {
      return new Promise((resolve, reject) => {
        schedulingServices.retrieveCensus(criteria).then(response => {
          const census = response.data.results;
          for (let i = 0, len = census.length; i < len; i++) {
            const props = _.cloneDeep(censusResponseTemplate);
            copyProperties(props, census[i]);
            census[i] = props;
          }
          if (response.data.next) {
            dispatch('retrieveCensus', { ...criteria, page: response.data.next }).then(response => {
              resolve(census.concat(response));
            }).catch(error => {
              reject(error);
            });
          } else {
            resolve(census);
          }
        }).catch(error => {
          reject(error);
        });
      });
    },
    retrieveEventHistory (store, id) {
      return new Promise((resolve, reject) => {
        schedulingServices.retrieveEventHistory(id).then(response => {
          const data = {
            history: []
          };
          copyProperties(data, response.data);
          resolve(data.history);
        }).catch(error => {
          reject(error);
        });
      });
    },
    retrieveEventScheduleId ({ commit, dispatch, state }, event) {
      return new Promise((resolve, reject) => {
        const date = moment(event.dates[0]).format(DATE_FORMAT);
        const omit = ['shifts', 'swaps', 'shift_requests'];
        schedulingServices.retrieveScheduleByDate(event.departmentId, date, omit).then(response => {
          if (response.data.count > 0) {
            resolve(response.data.results[0].id);
          } else {
            resolve(0);
          }
        }).catch(error => {
          reject(error);
        });
      });
    },
    retrieveLastShifts (store, criteria) {
      return new Promise((resolve, reject) => {
        schedulingServices.retrieveLastShifts(criteria).then(response => {
          const shifts = response.data;
          for (let userId in shifts) {
            const props = _.cloneDeep(shiftResponseTemplate);
            copyProperties(props, shifts[userId].last, SHIFT_MAP_RAW);
            shifts[userId].last = props;
          }
          resolve(shifts);
        }).catch(error => {
          reject(error);
        });
      });
    },
    retrievePendingRequests ({ state, dispatch }, queryParams) {
      return new Promise((resolve, reject) => {
        requestServices.retrievePaginatedRequests({
          ...queryParams,
          pending: true
        }).then(response => {
          const requests = convertRequests(response.results);
          if (response.next) {
            dispatch('retrievePendingRequests', { ...queryParams, page: response.next }).then(response => {
              resolve(requests.concat(response));
            }).catch(error => {
              reject(error);
            });
          } else {
            resolve(requests);
          }
        }).catch(error => { reject(error); });
      });
    },
    retrieveShiftRequest ({ commit, dispatch, state }, shiftRequestId) {
      return new Promise((resolve, reject) => {
        Promise.all([
          schedulingServices.retrieveShiftRequest(shiftRequestId),
          dispatch('retrieveShiftRequestErrors', shiftRequestId)
        ]).then(responses => {
          const shiftRequest = _.cloneDeep(shiftRequestResponseTemplate);
          copyProperties(shiftRequest, responses[0].data);
          shiftRequest.errors = responses[1];
          resolve(shiftRequest);
        }).catch(error => {
          reject(error);
        });
      });
    },
    retrieveShiftRequestErrors ({ commit, dispatch }, shiftRequestId) {
      return new Promise((resolve, reject) => {
        schedulingServices.retrieveShiftRequestErrors(shiftRequestId).then(response => {
          resolve(response.data.errors);
        }).catch(error => {
          reject(error);
        });
      });
    },
    retrieveShift ({ commit, dispatch }, id) {
      return new Promise((resolve, reject) => {
        schedulingServices.retrieveShift(id).then(response => {
          const shift = _.cloneDeep(shiftResponseTemplate);
          copyProperties(shift, response.data, SHIFT_MAP_RAW);
          resolve(shift);
        }).catch(error => {
          reject(error);
        });
      });
    },
    retrieveShiftHistory (store, id) {
      return new Promise((resolve, reject) => {
        schedulingServices.retrieveShiftHistory(id).then(response => {
          const data = {
            history: []
          };
          copyProperties(data, response.data);
          resolve(data.history);
        }).catch(error => {
          reject(error);
        });
      });
    },
    retrieveShifts ({ commit, dispatch }, criteria) {
      return new Promise((resolve, reject) => {
        schedulingServices.retrieveShifts({ ...criteria, page_size: 500 }).then(response => {
          const shifts = response.data.results;
          for (let i = 0, len = shifts.length; i < len; i++) {
            const props = _.cloneDeep(shiftResponseTemplate);
            copyProperties(props, shifts[i], SHIFT_MAP_RAW);
            shifts[i] = props;
          }
          if (response.data.next) {
            dispatch('retrieveShifts', { ...criteria, page: response.data.next }).then(response => {
              resolve(shifts.concat(response));
            }).catch(error => {
              reject(error);
            });
          } else {
            resolve(shifts);
          }
        }).catch(error => {
          reject(error);
        });
      });
    },
    retrieveSwap ({ commit, dispatch }, swapId) {
      return new Promise((resolve, reject) => {
        Promise.all([
          schedulingServices.retrieveSwap(swapId),
          dispatch('retrieveSwapErrors', swapId)
        ]).then(responses => {
          const swap = _.cloneDeep(swapResponseTemplate);
          copyProperties(swap, responses[0].data);
          swap.errors = responses[1];
          resolve(swap);
        }).catch(error => {
          reject(error);
        });
      });
    },
    retrieveSwapErrors ({ commit, dispatch }, swapId) {
      return new Promise((resolve, reject) => {
        schedulingServices.retrieveSwapErrors(swapId).then(response => {
          resolve(response.data.errors);
        }).catch(error => {
          reject(error);
        });
      });
    },
    createDailySchedule ({ commit, dispatch }, schedule) {
      return new Promise((resolve, reject) => {
        const dailySchedule = _.cloneDeep(dailyScheduleResponseTemplate);
        const payload = preparePayload(newDailySchedulePayloadTemplate, schedule);
        if (payload.memos) {
          for (let i = 0, count = payload.memos.length; i < count; i++) {
            const memo = _.cloneDeep(payload.memos[i]);
            snakeCasePropertyNames(memo);
            payload.memos[i] = memo;
          }
        }
        schedulingServices.createDailySchedule(payload).then(response => {
          copyProperties(dailySchedule, response);
          resolve(dailySchedule);
        }).catch(error => {
          reject(error);
        });
      });
    },
    updateDailySchedule ({ commit, dispatch }, schedule) {
      return new Promise((resolve, reject) => {
        const dailySchedule = _.cloneDeep(dailyScheduleResponseTemplate);
        const payload = preparePayload(existingDailySchedulePayloadTemplate, schedule.data);
        if (payload.memos) {
          for (let i = 0, count = payload.memos.length; i < count; i++) {
            const memo = _.cloneDeep(payload.memos[i]);
            snakeCasePropertyNames(memo);
            payload.memos[i] = memo;
          }
        }
        schedulingServices.updateDailySchedule(schedule.id, payload).then(response => {
          copyProperties(dailySchedule, response);
          resolve(dailySchedule);
        }).catch(error => {
          reject(error);
        });
      });
    },
    shareDailySchedule ({ commit, dispatch }, data) {
      return new Promise((resolve, reject) => {
        schedulingServices.shareDailySchedule(data).then(response => {
          const sharedData = {
            dailySchedules: []
          };
          copyProperties(sharedData, response);
          resolve(sharedData);
        }).catch(error => {
          reject(error);
        });
      });
    },
    saveCensus ({ commit, getters, dispatch }, { census, dailyScheduleId }) {
      return new Promise((resolve, reject) => {
        const parseResponse = (response) => {
          const list = [];
          for (let i = 0, count = response.data.length; i < count; i++) {
            const censusTemplate = _.cloneDeep(censusResponseTemplate);
            copyProperties(censusTemplate, response.data[i]);
            list.push(censusTemplate);
          }
          return list;
        };
        let censusList = [];
        const createPayload = [];
        const updatePayload = [];
        const promises = [];
        for (let i = 0, count = census.length; i < count; i++) {
          const censusCopy = { ...census[i], dailyScheduleId };
          snakeCasePropertyNames(censusCopy, {
            acuity1: 'acuity1',
            acuity2: 'acuity2',
            acuity3: 'acuity3',
            acuity4: 'acuity4',
            acuity5: 'acuity5'
          });
          if (census[i].id) {
            updatePayload.push(censusCopy);
          } else {
            createPayload.push(censusCopy);
          }
        }

        if (createPayload.length > 0) {
          promises.push(schedulingServices.createCensus(createPayload).then((response) => {
            censusList = censusList.concat(parseResponse(response));
          }));
        }

        if (updatePayload.length > 0) {
          promises.push(schedulingServices.updateCensus(updatePayload).then((response) => {
            censusList = censusList.concat(parseResponse(response));
          }));
        }

        Promise.all(promises).then(() => {
          resolve(censusList);
        }).catch(error => {
          reject(error);
        });
      });
    },
    createShifts (store, shifts) {
      return new Promise((resolve, reject) => {
        const payload = _.map(shifts, (shift) => {
          return preparePayload(newShiftPayloadTemplate, shift);
        });
        schedulingServices.createShifts(payload).then((response) => {
          const newShifts = [];
          for (let i = 0, count = response.data.length; i < count; i++) {
            const shift = _.cloneDeep(shiftResponseTemplate);
            copyProperties(shift, response.data[i], SHIFT_MAP_RAW);
            newShifts.push(shift);
          }
          resolve(newShifts);
        }).catch(error => {
          reject(error);
        });
      });
    },
    updateShift (store, shift) {
      return new Promise((resolve, reject) => {
        const payload = preparePayload(existingShiftPayloadTemplate, shift);
        if (_.has(shift, 'scheduleId')) {
          payload.schedule_id = shift.scheduleId;
        }
        schedulingServices.updateShift(shift.id, payload).then((response) => {
          const shift = _.cloneDeep(shiftResponseTemplate);
          copyProperties(shift, response.data, SHIFT_MAP_RAW);
          resolve(shift);
        }).catch(error => {
          reject(error);
        });
      });
    },
    updateShifts (store, shifts) {
      return new Promise((resolve, reject) => {
        const payload = _.map(shifts, (shift) => {
          return preparePayload(existingShiftPayloadTemplate, shift);
        });
        schedulingServices.updateShifts(payload).then((response) => {
          const updatedShifts = [];
          for (let i = 0, count = response.data.length; i < count; i++) {
            const shift = _.cloneDeep(shiftResponseTemplate);
            copyProperties(shift, response.data[i], SHIFT_MAP_RAW);
            updatedShifts.push(shift);
          }
          resolve(updatedShifts);
        }).catch(error => {
          reject(error);
        });
      });
    },
    deleteShifts (store, shifts) {
      return new Promise((resolve, reject) => {
        schedulingServices.deleteShifts(shifts.map((shiftId) => parseInt(shiftId))).then(() => {
          resolve();
        }).catch(error => {
          reject(error);
        });
      });
    },
    saveUserProfile ({ commit, dispatch }, data) {
      return new Promise((resolve, reject) => {
        const payloadTemplate = {
          schedule_notes: 'scheduleNotes'
        };
        const payload = preparePayload(payloadTemplate, data);
        accountServices.updateProfile(data.profileId, payload).then(response => {
          commit('update_user_notes', data);
          commit('org/update_user_notes', data, { root: true });
          resolve(response);
        }).catch(error => {
          reject(error);
        });
      });
    },
    updateEvent ({ commit }, data) {
      return new Promise((resolve, reject) => {
        schedulingServices.updateEvent(data.id, data.props).then(response => {
          resolve(response);
        }).catch(error => {
          reject(error);
        });
      });
    },
    updateEvents (store, events) {
      return new Promise((resolve, reject) => {
        const payload = _.map(events, (event) => {
          return preparePayload(existingEventPayloadTemplate, event);
        });
        schedulingServices.updateEvents(payload).then((response) => {
          const updatedEvents = [];
          for (let i = 0, count = response.data.length; i < count; i++) {
            const event = _.cloneDeep(eventResponseTemplate);
            copyProperties(event, response.data[i]);
            updatedEvents.push(event);
          }
          resolve(updatedEvents);
        }).catch(error => {
          reject(error);
        });
      });
    },
    updateOpenShift ({ commit }, data) {
      return new Promise((resolve, reject) => {
        const payload = _.cloneDeep(data.props);
        snakeCasePropertyNames(payload);
        schedulingServices.updateOpenShift(data.id, payload).then(response => {
          const openShift = _.cloneDeep(openShiftResponseTemplate);
          copyProperties(openShift, response.data);
          resolve(openShift);
        }).catch(error => {
          reject(error);
        });
      });
    },
    updateOpenShiftOpening ({ commit }, data) {
      return new Promise((resolve, reject) => {
        schedulingServices.updateOpenShiftOpening(data.id, data.opening).then(response => {
          const openShift = _.cloneDeep(openShiftResponseTemplate);
          copyProperties(openShift, response.data);
          resolve(openShift);
        }).catch(error => {
          reject(error);
        });
      });
    },
    updateShiftRequest ({ commit }, data) {
      return new Promise((resolve, reject) => {
        schedulingServices.updateShiftRequest(data.id, data.props).then(response => {
          resolve(response);
        }).catch(error => {
          reject(error);
        });
      });
    },
    updateSwap ({ commit }, data) {
      return new Promise((resolve, reject) => {
        schedulingServices.updateSwapRequest(data.id, data.props).then(response => {
          resolve(response);
        }).catch(error => {
          reject(error);
        });
      });
    },
    updateSchedule ({ commit, dispatch }, data) {
      // !This is different from saveSchedule.
      // This action does not rely on the state of this module and does not pre-process any data.
      // It simply takes the payload and sends it to the server.

      return new Promise((resolve, reject) => {
        schedulingServices.updateSchedule(data.id, data.props).then(response => {
          const schedule = parseScheduleResponse(response);
          commit('org/update_schedule_period', {
            departmentId: schedule.departmentId,
            scheduleId: schedule.id,
            scheduleState: schedule.state
          }, { root: true });
          resolve();
        }).catch(error => {
          reject(error);
        });
      });
    }
  }
};
